import axiosServerKariermu from '../configs/axiosServerKariermu'
// import firebaseConfig from './firebase.config'
import crypto from '../functions/crypto'

export default class MessagingService {
  constructor () {
    this.messaging = window.firebaseMessaging
    if (!this.messaging.vapidKey) {
      this.messaging.usePublicVapidKey(process.env.VUE_APP_FIREBASE_VAPID_KEY)
    }

    this.messaging.onTokenRefresh(function () {
      this.messaging.getToken().then(function (refreshedToken) {
        this.setTokenSentToServerFlg(false)
        this.sendTokenToServer(refreshedToken)
      }).catch(function (err) {
        console.log('Unable to retrieve refreshed token ', err)
      })
    })
  }

  async requestPermissionAsync () {
    try {
      await this.messaging.requestPermission()
    } catch (err) {
      console.log('Unable to get permission to notify.', err)
    }
  }

  async getTokenAsync () {
    try {
      let currentToken = await this.messaging.getToken()
      if (currentToken) {
        await this.sendTokenToServerAsync(currentToken)
        return currentToken
      } else {
        // Show permission request.
        console.log('No Instance ID token available. Request permission to generate one.')
        // Show permission UI.
        this.setTokenSentToServerFlg(false)
        return false
      }
    } catch (err) {
      console.log('An error occurred while retrieving token. ', err)
      this.setTokenSentToServerFlg(false)
      return false
    }
  }

  async deleteTokenAsync () {
    try {
      let currentToken = await this.messaging.getToken()
      await this.messaging.deleteToken(currentToken)
      this.setTokenSentToServerFlg(false)
    } catch (err) {
      console.log('Unable to delete token. ', err)
    }
  }

  async sendTokenToServerAsync (currentToken) {
    try {
      if (!this.isTokenSentToServer()) {
        // TODO: Send the current token to your server.
        await axiosServerKariermu({
          version: 'v2',
          url: '/auth/update-fcm-token',
          method: 'POST',
          headers: {
            Authorization: crypto.decrypt(window.$cookies.get('access-token'))
          },
          data: {
            token: currentToken
          }
        })

        this.setTokenSentToServerFlg(true)
      } else {
        console.log('Token already sent to server so won\'t send it again unless it changes')
      }
    } catch (err) {
      console.log('Unable to send token to server', err)
    }
  }

  isTokenSentToServer () {
    return window.localStorage.getItem('sentToServer') === '1'
  }

  setTokenSentToServerFlg (sent) {
    window.localStorage.setItem('sentToServer', sent ? '1' : '0')
  }
}
