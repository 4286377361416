<template>
  <div id="base-otp-user-verification" :class="modalClass.wrapper">
    <div :class="modalClass.header">
      <p :class="modalClass.headerText">Verifikasi Akun</p>
    </div>
    <div class="body-description-container">
      <p class="font-subtitle-rg-4 text-ui-primary s-mb-3">Silakan verifikasi akunmu terlebih dahulu untuk bisa mengakses fitur Sekolah.mu secara lengkap.</p>
      <p class="font-subtitle-rg-4 text-ui-primary mb-0">Kode verifikasi telah dikirimkan ke <strong class="text-break">{{ userEmail }}</strong>. Silakan gunakan kode tersebut untuk verifikasi akun.</p>
    </div>
    <div :class="modalClass.marginAfterOTP">
      <div :class="modalClass.otpHeader">
        <h5 class="font-label-1 s-mb-3">Kode Verifikasi</h5>
        <p v-if="otpSubmitErrorMessageInfo" class="font-body-4 otp-input-error">{{ otpSubmitErrorMessageInfo }}</p>
      </div>
      <div v-if="isLoadingSendOTP" class="otp-loading-state">
        <b-spinner large/>
      </div>
      <div class="otp-input-container" v-else>
        <b-input
          v-for="(_, index) in inputs"
          :key="index"
          class="font-title-display-XL otp-input"
          :id="`otp-input-${index + 1}`"
          :formatter="numberOnlyFormatter"
          maxlength="1"
          v-model="inputs[index]"
          autocomplete="off"
          type="tel"
          @paste="pasteOtp($event, index)"
          @keydown="e => otpKeyPressed(e, index + 1)"
        />
      </div>
    </div>
    <div class="send-otp-container">
      <h5 class="font-label-md-rg-lh16 send-otp">Belum menerima kode verifikasi melalui email?</h5>
      <div class="font-label-md-sb-lh16 send-otp-trigger" :class="{ 'active': canSendOTP, 'disabled': !canSendOTP }" @click="requestOTP()" id="request-otp-button">
        Kirim Ulang Kode Verifikasi
        <b-spinner small class="ml-1" v-if="loadingSendOTP"/>
        <span v-if="!canSendOTP" class="otp-countdown">({{ countdownDisplay }})</span>
      </div>
    </div>
    <h5 class="font-body-3 meta-info"><span>atau</span></h5>
    <div>
      <div class="bottom-button-container">
        <div role="button" class="font-label-md-sb-lh16 change-email-button" @click="isOpenChangeEmailModal = true; changeEmail();" id="trigger-change-email-button">Ubah Email</div>
      </div>
      <div class="bottom-button-container" v-if="!hideSkipVerification">
        <div v-if="isSkipVerification === 'true'" role="button" class="font-label-md-rg-lh16 change-email-button" @click="skipVerification()" id="skip-verification-button">Lewati Verifikasi Akun</div>
      </div>
    </div>

    <b-modal
      v-model="isOpenChangeEmailModal"
      hide-footer
      hide-header
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      centered
      size="modal-304"
      content-class="modal-content-semi_round"
    >
      <change-email
        @toggleEmailModal="toggleEmailModal"
        @afterChangeEmail="afterChangeEmail"
      />
    </b-modal>

    <error-message-modal
      :isOpenModal="isOpenModalError"
      :illustration="'https://cdn.sekolah.mu/avatar/154925/5054ab2a3a854fc19cee75fd11fb1674.png'"
      :title="otpSubmitErrorTitle"
      :description="otpSubmitErrorMessage"
      @onCloseModal="closeModalErrorMessage"
    ></error-message-modal>
  </div>
</template>
<script>
import ErrorMessageModal from '@/components/molecules/modals/ErrorMessageModal.vue'
import setFireBasePayloadMixin from '@/mixins/setFireBasePayloadMixin.js'
import AUTH_API from '@/api/auth.js'
import { OTP_REFERENCE, getOTPReferenceId } from '@/constants/otpReference.js'

export default {
  name: 'BaseOtpUserVerification',
  props: {
    hideSkipVerification: Boolean,
    isModal: {
      type: Boolean,
      default: true
    }
  },
  components: {
    ErrorMessageModal,
    ChangeEmail: () => import('@/components/otpUserVerification/ChangeEmail.vue')
  },
  data () {
    return {
      // otp input
      inputs: ['', '', '', '', '', ''],
      isLoadingSendOTP: false,
      isOpenModalError: false,
      otpSubmitErrorTitle: '',
      otpSubmitErrorMessage: '',
      otpSubmitErrorMessageInfo: '',

      // countdown
      sendOTPdelay: 90,
      countdownMinute: 0,
      countdownSecond: 0,
      setCountdownInterval: null,
      loadingSendOTP: false,

      // change email
      isOpenChangeEmailModal: false,
      newEmailText: '',
      editEmailErrorMessage: '',
      loadingSubmitEmail: false,

      // status code
      verificationFreezeStatusCode: 124,

      // firebase analytic
      timeOpenPage: '',
      timeClosePage: '',
      firebaseAnalyticsPayload: {}
    }
  },
  mixins: [setFireBasePayloadMixin],
  computed: {
    isFromBayar () {
      return this.$route.query.from === 'bayar'
    },
    isSkipVerification () {
      return process.env.VUE_APP_IS_SKIP_VERIFICATION
    },
    userId () {
      return this.$store.state.user.userInfo.id
    },
    userEmail () {
      return this.$store.state.user.userInfo.email
    },
    accessToken () {
      return this.$store.state.storage.accessToken
    },
    countdownDisplay () {
      return `${this.countdownMinute < 10 ? '0' : ''}${this.countdownMinute}:${this.countdownSecond < 10 ? '0' : ''}${this.countdownSecond}`
    },
    canSendOTP: {
      get () {
        return this.$store.state.user.canSendOTP
      },
      set (value) {
        this.$store.commit('user/CHANGE_CAN_SEND_OTP', value)
      }
    },
    isVerificationMandatory () {
      return this.$store.state.user.isVerificationMandatory
    },
    isTemporarilyFreezeOtpVerification: {
      get () {
        return this.$store.state.user.isTemporarilyFreezeOtpVerification
      },
      set (val) {
        this.$store.commit('user/CHANGE_IS_TEMPORARILY_FREEZE_OTP_VERIFICATION', val)
      }
    },
    pageSource () {
      let source
      switch (this.$route.path) {
        case '/verification':
          source = 'sekolahmu-web-register'
          break
        case '/profile':
          source = 'sekolahmu-web-profil'
          break
        case '/daftar-pilihan':
          source = 'sekolahmu-web-pembayaran'
          break
        case '/portofolio/profil':
          source = 'sekolahmu-web-portofolio'
          break
        case '/rapor-murid':
          source = 'sekolahmu-web-transkrip'
          break
        default:
          source = 'sekolahmu-web-aktivitas belajar'
          break
      }

      return source
    },
    isLogin () {
      return this.$store.state.user.isLogin
    },
    userInfo () {
      return this.$store.state.user.userInfo
    },
    modalClass () {
      const isModal = this.isModal
      return {
        header: `verification-header ${isModal ? '' : 'header-non-modal'}`,
        headerText: `text-persian-blue-900 ${isModal ? 'font-label-1' : 'font-body-1'}`,
        otpHeader: `otp-input-header ${isModal ? '' : 'otp-header-margin-bottom-non-modal'}`,
        marginAfterOTP: `verification-input-container ${isModal ? 's-mb-5' : 'verification-input-non-modal'}`,
        wrapper: isModal ? 'base-otp-modal-height' : 'base-otp-height'
      }
    },
    isAllOtpFilled () {
      return this.inputs.every(input => input)
    }
  },
  methods: {
    pasteOtp (event, startingPoint) {
      const pastedData = event.clipboardData.getData('text') || ''
      const onlyNumberPastedData = pastedData.replace(/[^0-9]/g, '')
      const otps = onlyNumberPastedData.split('')
      let latestCursorPosition = startingPoint

      for (let i = startingPoint; i < 6; i++) {
        if (otps[i - startingPoint]) {
          this.$set(this.inputs, i, otps[i - startingPoint])
          latestCursorPosition = i === 5 ? 6 : i + 2
        }
      }

      this.setOtpCursorPosition(latestCursorPosition)
      event.preventDefault()
    },
    setOtpCursorPosition (number) {
      setTimeout(() => {
        document.getElementById(`otp-input-${number}`).focus()
      }, 100)
    },
    afterChangeEmail () {
      this.setCountdownOTP()
      this.canSendOTP = false
    },
    toggleEmailModal (payload) {
      this.isOpenChangeEmailModal = payload
    },
    otpKeyPressed (value, index) {
      const numericValue = Number(value.key)
      if (value.code === 'Backspace' && index > 0) {
        this.setOtpCursorPosition(index - 1)
      } else if (index < 6 && numericValue >= 0 && numericValue <= 9) {
        this.setOtpCursorPosition(index + 1)
      }
    },
    numberOnlyFormatter (value) {
      return value.replace(/[^0-9]/g, '')
    },
    emailFormatter (value) {
      return value.toLowerCase()
    },
    async requestOTP () {
      if (this.$route.path === '/profile') {
        this.logEventAnalytics('verifikasi_voluntary_kirim_ulang_kode_click')
      } else if (this.$route.path === '/verification') {
        this.logEventAnalytics('verifikasi_kirim_ulang_kode_click')
      } else {
        this.logEventAnalytics('verifikasi_mandatory_kirim_ulang_kode_click')
      }
      if (!this.loadingSendOTP && this.canSendOTP) {
        this.loadingSendOTP = true
        try {
          const otpReference = await AUTH_API.referenceOTP({
            authorization: process.env.VUE_APP_APP_KEY
          })
          const otp_purpose_id = getOTPReferenceId(otpReference.data, OTP_REFERENCE.VERIFY_USER)
          const { data } = await AUTH_API.resendOTP({
            authorization: this.accessToken,
            payload: {
              source: this.isMobile ? window.btoa('sekolahmu-webview') : window.btoa('sekolahmu-web'),
              email: this.userEmail,
              otp_purpose_id
            }
          })
          if (data.status === 200) {
            this.canSendOTP = false
            this.setCountdownOTP()
            this.resetOTPinput()
          } else {
            throw new Error(data)
          }
          this.loadingSendOTP = false
        } catch {
          this.loadingSendOTP = false
        }
      }
    },
    setCountdownOTP () {
      let timeNow = new Date()
      let timeOtp = new Date()
      timeOtp.setTime(timeNow.getTime() + (this.sendOTPdelay * 1000))
      let otpTimestamp = Date.parse(timeOtp)

      localStorage.setItem('OTP-timestamp', otpTimestamp)
      this.countdownCount()
      this.startCountdownOTP()
    },
    startCountdownOTP () {
      this.setCountdownInterval = setInterval(() => {
        this.countdownCount()
        if (this.countdownSecond <= 0 && this.countdownMinute <= 0) {
          this.stopCountdownOTP()
          this.canSendOTP = true
        }
      }, 1000)
    },
    stopCountdownOTP () {
      if (this.setCountdownInterval) {
        clearInterval(this.setCountdownInterval)
        localStorage.removeItem('OTP-timestamp')
      }
    },
    countdownCount () {
      let countDate = new Date(Number(localStorage.getItem('OTP-timestamp'))).getTime()
      let now = new Date().getTime()
      let gap = countDate - now

      const second = 1000
      const minute = second * 60
      const hour = minute * 60

      this.countdownMinute = Math.floor((gap % hour) / minute)
      this.countdownSecond = Math.floor((gap % minute) / second)
    },
    checkResCountdown () {
      if ((localStorage.getItem('OTP-timestamp'))) {
        let countDate = new Date(Number(localStorage.getItem('OTP-timestamp'))).getTime()
        let now = new Date().getTime()
        let gap = countDate - now
        if (gap > 0) {
          this.canSendOTP = false
          this.countdownCount()
          this.startCountdownOTP()
        }
      }
    },
    async skipVerification () {
      try {
        this.logEventAnalytics('verifikasi_lewati_click')
        const { data } = await this.$axiosServerKariermu({
          url: `/user/send-skip-email-otp/?source=${window.btoa(this.pageSource)}`,
          method: 'POST',
          headers: {
            Authorization: this.accessToken
          }
        })
        if (data.status === 200) {
          this.$emit('openAlert', 'Selamat Akunmu telah terdaftar')
          this.$emit('skipVerification')
        } else {
          throw new Error(data)
        }
      } catch {}
    },
    async sendOTP () {
      this.isLoadingSendOTP = true
      try {
        const otpReference = await AUTH_API.referenceOTP({
          authorization: process.env.VUE_APP_APP_KEY
        })
        const otp_purpose_id = getOTPReferenceId(otpReference.data, OTP_REFERENCE.VERIFY_USER)

        const otp = this.inputs.join('')
        const authorization = this.accessToken
        const payload = {
          email: this.userEmail,
          otp,
          otp_purpose_id,
          source: this.isMobile ? window.btoa('sekolahmu-webview') : window.btoa('sekolahmu-web')
        }
        const data = await AUTH_API.verifyOTP({ payload, authorization })

        if (data.status === 200) {
          this.$store.commit('user/SET_USER', { ...this.$store.state.user.userInfo, is_email_verified: true })
          this.$emit('openAlert', 'Selamat! Akunmu terverifikasi')
          this.$emit('doneVerification')
        } else {
          this.resetOTPinput()
          this.otpSubmitErrorMessage = data.status === this.verificationFreezeStatusCode ? data.message : this.otpSubmitErrorMessage
          this.otpSubmitErrorMessageInfo = 'Kode verifikasi tidak sesuai'
          if (data.status === this.verificationFreezeStatusCode) {
            this.$store.commit('user/CHANGE_UNFREEZE_VERIFICATION_SNACKBAR_TEXT_TYPE', 'wasDisabled')
            this.otpSubmitErrorTitle = 'Verifikasi Akun Diberhentikan Sementara'
            this.isOpenModalError = true

            this.setupTimerFreezeOtpVerification()
          } else {
            throw new Error(data)
          }
        }
        this.isLoadingSendOTP = false
      } catch {
        this.isLoadingSendOTP = false
      }
    },
    setupTimerFreezeOtpVerification () {
      this.isTemporarilyFreezeOtpVerification = true

      const freezeDurationInMinutes = 10
      const freezingTime = 1000 * 60 * freezeDurationInMinutes
      let timeNow = new Date()
      let timeUnfreeze = new Date()
      timeUnfreeze.setTime(timeNow.getTime() + freezingTime)
      let unfreezeTimestamp = Date.parse(timeUnfreeze)
      localStorage.setItem('freezing-verification-timestamp', unfreezeTimestamp)

      setTimeout(() => {
        this.isTemporarilyFreezeOtpVerification = false
        this.$store.commit('user/CHANGE_IS_SHOW_UNFREEZE_SNACKBAR', true)
      }, freezingTime)
    },
    resetOTPinput () {
      this.inputs = ['', '', '', '', '', '']
    },
    closeModalErrorMessage () {
      this.isOpenModalError = false
      if (this.isFromBayar) {
        window.close()
      }
      if (this.isVerificationMandatory) {
        this.$router.go(-1)
      } else {
        this.$store.commit('user/CHANGE_IS_VERIFICATION_MANDATORY', false)
        this.$store.commit('user/CHANGE_IS_HIDE_SKIP_VERIFICATION', false)
        this.$store.commit('user/CHANGE_IS_OPEN_VERIFICATION_INVITATION', false)
        this.$store.commit('user/CHANGE_IS_OPEN_VERIFICATION_OTP', false)
        this.$emit('openAlert', 'Selamat Akunmu telah terdaftar')
        this.$router.push('/')
      }
    },
    changeEmail () {
      if (this.$route.path === '/profile') {
        this.logEventAnalytics('verifikasi_voluntary_ubah_email_click')
      } else if (this.$route.path === '/verification') {
        this.logEventAnalytics('verifikasi_ubah_email_click')
      } else {
        this.logEventAnalytics('verifikasi_mandatory_ubah_email_click')
      }
    },
    logEventAnalytics (event) {
      window.firebaseAnalytics.logEvent(event, this.firebaseAnalyticsPayload)
    },
    logViewEvent () {
      this.setFireBasePayloadMixin()
      window.firebaseAnalytics.logEvent('verifikasi_page_view', this.firebaseAnalyticsPayload)
    }
  },
  watch: {
    canSendOTP (value) {
      if (!value) {
        this.checkResCountdown()
      }
    },
    isAllOtpFilled (val) {
      if (val) {
        this.sendOTP()
      }
    }
  },
  created () {
    this.timeOpenPage = new Date().getTime()
    window.addEventListener('beforeunload', this.logViewEvent)
  },
  mounted () {
    setTimeout(() => {
      this.checkResCountdown()
    }, 1000)
    this.firebaseAnalyticsPayload = {
      screen_url: window.location.href,
      user_profession: '',
      user_year_level: '',
      user_is_admin: '',
      user_id: '',
      source: this.isMobile ? 'web_mobile_sekolahmu' : 'web_sekolahmu'
    }
  },
  beforeDestroy () {
    window.removeEventListener('beforeunload', this.logViewEvent)
    this.logViewEvent()
  }
}
</script>
<style scoped>
:deep(.modal-body) {
  padding: 24px;
}

h5 {
  margin: 0;
}

::-webkit-scrollbar {
  display: none;
}

#base-otp-user-verification{
  width: 100%;
}

.base-otp-modal-height {
  max-height: 472px;
  overflow-y: auto;
}

.base-otp-height {
  height: auto;
}

.verification-header {
  position: relative;
  text-align: center;
  border-bottom: 1px solid var(--color-neutral-500);
}
.body-description-container{
  margin: 16px 0;
}
.send-otp-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
}
.send-otp-container .send-otp{
  color: var(--color-ui-text-primary);
  margin-bottom: 12px;
}
.send-otp-trigger{
  width: fit-content;
  margin: 8px 0;
}
.active{
  color: var(--color-persian-blue-500);
  cursor: pointer;
}
.disabled{
  color: var(--color-ui-text-placeholder);
}
.otp-countdown{
  color: var(--color-persian-blue-500);
}
.meta-info{
  color: var(--color-ui-text-placeholder);
  margin-bottom: 18px;
}
.meta-info {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid var(--color-neutral-500);
  line-height: 0.1em;
  margin: 16px 0 24px;
}
.meta-info span {
  background: var(--color-neutral-100);
  color: var(--color-text-200);
  padding: 0 10px;
}
.bottom-button-container{
  display: flex;
  justify-content: center;
  margin-bottom: 120px;
}
.bottom-button-container:last-child{
  margin-bottom: 0;
}
.change-email-button{
  color: var(--color-persian-blue-500);
  cursor: pointer;
  width: fit-content;
}

/* otp input */
.verification-input-container{
  text-align: center;
}

.verification-input-non-modal {
  margin-bottom: 50px;
}
.otp-input-header{
  margin-bottom: 20px;
}
.otp-input-error{
  color: var(--color-crimson-500);
  min-height: 20px;
}
.otp-input-container{
  display: flex;
  justify-content: center;
}
.otp-input{
  max-width: 35px;
  height: 58px;
  border: none;
  border-bottom: 4px solid var(--color-ui-text-secondary);
  margin-right: 25px;
  text-align: center;
  color: var(--color-ui-text-primary);
  padding: 0;
  border-radius: 0;
}
.otp-input:last-child{
  margin-right: 0;
}
.otp-loading-state{
  height: 58px;
}

.body-description{
  margin-bottom: 20px;
}

.header-non-modal {
  border-bottom: none;
  margin-bottom: 40px;
}

.otp-header-margin-bottom-non-modal {
  margin-top: 40px;
}

@media screen and (max-width: 525px) {
  .bottom-button-container {
    margin-bottom: 0;
  }
}
</style>
