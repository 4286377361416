import store from '@/store'
import axiosServerKariermu from '@/configs/axiosServerKariermu'
import axiosServerGolang from '@/configs/axiosServerGolang'

export default {
  async fetchInstitution ({ accessToken, search_slug, page }) {
    const isActiveInstitution = `status=1`
    const isNotLockedInstitution = `is_lock=false`
    const url = `/user/institution/${page}/20?search=${search_slug}&${isActiveInstitution}&${isNotLockedInstitution}&sort=name.keyword-asc`
    try {
      const result = await axiosServerKariermu({
        method: 'GET',
        url,
        headers: {
          Authorization: accessToken
        }
      })
      return result
    } catch (error) {
      console.error(error, 'Failed to get institution list')
    }
  },
  async fetchDistrict ({ accessToken, search_slug }) {
    const url = `/user/domicile/${search_slug}`
    try {
      const result = await axiosServerKariermu({
        method: 'GET',
        url,
        headers: {
          Authorization: accessToken
        }
      })
      return result
    } catch (error) {
      console.error(error, 'Failed to get district list')
    }
  },
  async fetchUserReferences ({ accessToken }) {
    const url = `/user/reference/`
    try {
      const result = await axiosServerKariermu({
        method: 'GET',
        url,
        headers: {
          Authorization: accessToken
        }
      })
      return result
    } catch (error) {
      console.error(error, 'Failed to get user references')
    }
  },
  async initClasses ({ accessToken }) {
    const url = `/user/year/list`
    try {
      const result = await axiosServerKariermu({
        method: 'GET',
        url,
        headers: {
          Authorization: accessToken
        }
      })
      if (store.state.institution.platinumConfig.yearLevelTrim) {
        const options = result.data.data
        result.data.data = options.filter(obj => {
          return obj.tag !== 'PAUD01' && obj.tag !== 'PAUD12' && obj.tag !== 'PAUD23'
        })
      }
      return result
    } catch (error) {
      console.error(error, 'Failed to get year/list')
    }
  },
  async fetchYearLevel ({ authorization }) {
    try {
      const result = await axiosServerKariermu({
        url: '/user/profession/',
        method: 'GET',
        headers: {
          authorization
        }
      })
      if (result.status === 200) {
        if (store.state.institution.platinumConfig.yearLevelTrim) {
          const options = result.data.data.find(obj => obj.id === 1)
          options.year_level = options.year_level.filter(obj => {
            return obj.tag !== 'PAUD01' && obj.tag !== 'PAUD12' && obj.tag !== 'PAUD23'
          })
        }
        return result
      } else {
        throw new Error(result)
      }
    } catch (error) {
      return { status: error.status, message: error.message }
    }
  },
  async bulkRegisterChild ({ Authorization, payload }) {
    try {
      const result = await axiosServerKariermu({
        version: 'v2',
        url: '/user/register-children/bulk',
        method: 'POST',
        headers: {
          Authorization
        },
        data: payload
      })
      if (result.status === 200) {
        return result
      } else {
        throw new Error(result)
      }
    } catch (error) {
      return { status: error.status, message: error.message }
    }
  },
  async deleteUserRelation ({ Authorization, target_id }) {
    try {
      const result = await axiosServerKariermu({
        version: 'v2',
        url: `/user/user-relation/delete/${target_id}`,
        method: 'POST',
        headers: {
          Authorization
        }
      })
      if (result.status === 200) {
        return result
      } else {
        throw new Error(result)
      }
    } catch (error) {
      return { status: error.status, message: error.message }
    }
  },
  async submitNewPassword ({ email, code, new_password }) {
    try {
      const result = await axiosServerKariermu({
        url: '/user/forget_password/',
        method: 'POST',
        headers: {
          Authorization: process.env.VUE_APP_APP_KEY
        },
        data: {
          email,
          code,
          new_password
        }
      })
      if (result.status === 200) {
        return result
      } else {
        throw new Error(result)
      }
    } catch (error) {
      return { status: error.status, message: error.message }
    }
  },
  async getRegistrationFormData ({ formId }) {
    try {
      const { data } = await axiosServerKariermu({
        version: 'v2',
        url: `/user/registration-form/detail/${formId}`,
        method: 'GET',
        headers: {
          Authorization: process.env.VUE_APP_APP_KEY
        }
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async getCompleteRegion ({ search, page, limit }) {
    try {
      const { data } = await axiosServerKariermu({
        version: 'v2',
        url: `/user/complete-region`,
        method: 'POST',
        headers: {
          Authorization: process.env.VUE_APP_APP_KEY
        },
        data: {
          search,
          page,
          limit
        }
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async getLearningSpaceCity ({ institutionId }) {
    try {
      const { data } = await axiosServerGolang({
        url: `/product/learning-space/city-list/by-operator/${institutionId}`,
        method: 'GET',
        headers: {
          Authorization: process.env.VUE_APP_APP_KEY
        }
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async getLearningSpace ({ institutionId, city }) {
    try {
      const { data } = await axiosServerGolang({
        url: `/product/learning-space/list/by-operator/${institutionId}`,
        method: 'GET',
        headers: {
          Authorization: process.env.VUE_APP_APP_KEY
        },
        params: {
          city
        }
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async getLivingLearningSpace (institutionId, perPage, city = '') {
    try {
      const { data } = await axiosServerGolang({
        url: `product/learning-space-by-platform/${institutionId}/1/${perPage}?city=${city}`,
        method: 'GET',
        headers: {
          Authorization: process.env.VUE_APP_APP_KEY
        }
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async loadMoreLivingLearningSpace ({ nextUrl = '' }) {
    const url = nextUrl.split('v2')[1]
    try {
      const result = await axiosServerKariermu({
        version: 'v2',
        method: 'GET',
        url,
        headers: {
          Authorization: process.env.VUE_APP_APP_KEY
        }
      })
      return result.data
    } catch (error) {
      throw error
    }
  },
  async fetchListRegion (search, page = 1, limit = 12) {
    try {
      const result = await axiosServerKariermu({
        version: 'v2',
        url: `/user/complete-region`,
        method: 'POST',
        headers: {
          Authorization: process.env.VUE_APP_APP_KEY
        },
        data: {
          search,
          page,
          limit
        }
      })
      if (result.status === 200) {
        return result.data
      } else {
        throw new Error(result)
      }
    } catch (error) {
      return { status: error.status, message: error.message }
    }
  },
  async submitAddress (authorization, payload) {
    try {
      const result = await axiosServerKariermu({
        url: '/user/manage/',
        method: 'POST',
        headers: {
          authorization
        },
        data: payload
      })
      if (result.status === 200) {
        return result
      } else {
        throw new Error(result)
      }
    } catch (error) {
      return { status: error.status, message: error.message }
    }
  },
  async getInstitutionDocuments (accessToken, institutionId) {
    try {
      const { data } = await axiosServerKariermu({
        version: 'v2',
        url: `/user/institution-document/public-list/${institutionId}`,
        method: 'GET',
        headers: {
          Authorization: accessToken
        }
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async getInstitutionDocumentsV2 (accessToken) {
    try {
      const { data } = await axiosServerKariermu({
        version: 'v2',
        url: '/user/institution-document/multiple-public-list',
        method: 'GET',
        headers: {
          Authorization: accessToken
        }
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async submitAgreementDocument (accessToken, payload) {
    try {
      const { data } = await axiosServerKariermu({
        version: 'v2',
        url: '/user/institution-document/user-agreement',
        method: 'POST',
        headers: {
          Authorization: accessToken
        },
        data: payload
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async getParentsData (accessToken, userId) {
    try {
      const { data } = await axiosServerKariermu({
        version: 'v2',
        url: `/user/parent-information/${userId}`,
        method: 'GET',
        headers: {
          Authorization: accessToken
        }
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async getChildrenData (accessToken, childId) {
    try {
      const { data } = await axiosServerKariermu({
        version: 'v2',
        url: `/user/child-information/${childId}`,
        method: 'GET',
        headers: {
          Authorization: accessToken
        }
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async submitChildrenData (accessToken, payload) {
    try {
      const { data } = await axiosServerKariermu({
        version: 'v2',
        url: `/user/child-information`,
        method: 'POST',
        headers: {
          Authorization: accessToken
        },
        data: {
          ...payload
        }
      })

      return data
    } catch (error) {
      throw error
    }
  },
  async postParentsData (accessToken, payload) {
    try {
      const result = await axiosServerKariermu({
        version: 'v2',
        url: '/user/parent-information',
        method: 'POST',
        headers: {
          Authorization: accessToken
        },
        data: {
          ...payload
        }
      })

      if (result.status === 200) {
        return result
      } else {
        throw new Error(result)
      }
    } catch (error) {
      throw error
    }
  },
  async getParentDetails (accessToken, section) {
    try {
      const url = `/user/v2/parent-information/${section}`
      const { data } = await axiosServerKariermu({
        version: 'v2',
        url: url,
        method: 'GET',
        headers: {
          Authorization: accessToken
        }
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async getParentOptions (accessToken) {
    try {
      const data = await axiosServerKariermu({
        version: 'v2',
        url: '/user/community/edit-option',
        method: 'GET',
        headers: {
          Authorization: accessToken
        }
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async submitParentData ({ accessToken, payload, section }) {
    try {
      const data = await axiosServerKariermu({
        version: 'v2',
        url: `/user/submit-parent-information/${section}`,
        method: 'POST',
        headers: {
          Authorization: accessToken
        },
        data: {
          ...payload
        }
      })
      return data
    } catch (err) {
      throw err
    }
  },
  async validateDapodikChild ({ accessToken, userId }) {
    try {
      const data = await axiosServerKariermu({
        version: 'v2',
        url: `/user/validate-dapodik/${userId}`,
        method: 'GET',
        headers: {
          Authorization: accessToken
        }
      })
      return data
    } catch (err) {
      throw err
    }
  },
  async getUserPrimaryData (accessToken, userId) {
    try {
      const url = `/user/primary-data/${userId}`
      const { data } = await axiosServerKariermu({
        version: 'v2',
        url: url,
        method: 'GET',
        headers: {
          Authorization: accessToken
        }
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async getUserOptions (accessToken) {
    try {
      const url = `/user/community/edit-option`
      const { data } = await axiosServerKariermu({
        version: 'v2',
        url: url,
        method: 'GET',
        headers: {
          Authorization: accessToken
        }
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async saveUserPrimaryData (accessToken, payload) {
    try {
      const url = `/user/primary-data/main`
      const { data } = await axiosServerKariermu({
        version: 'v2',
        url: url,
        method: 'POST',
        headers: {
          Authorization: accessToken
        },
        data: payload
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async saveUserSiblings (accessToken, payload) {
    try {
      const url = `/user/primary-data/sibling`
      const { data } = await axiosServerKariermu({
        version: 'v2',
        url: url,
        method: 'POST',
        headers: {
          Authorization: accessToken
        },
        data: payload
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async getUserAcademicYearData (accessToken, { userId, academicYear }) {
    try {
      const data = await axiosServerKariermu({
        version: 'v2',
        url: `/user/academic-year-data/${userId}?academic_year=${academicYear}`,
        method: 'GET',
        headers: {
          Authorization: accessToken
        }
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async submitAcademicYearAddress (accessToken, { userId, academicYear, academicYearData }) {
    try {
      const {
        backwoods_name,
        master_data_region_id,
        address,
        link_map_address,
        phone
      } = academicYearData

      const data = await axiosServerKariermu({
        version: 'v2',
        url: `/user/academic-year-data/address`,
        method: 'POST',
        headers: {
          Authorization: accessToken
        },
        data: {
          user_id: userId,
          academic_year: academicYear,
          backwoods_name,
          master_data_region_id,
          address,
          link_map_address,
          phone
        }
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async submitAcademicYearPeriodic (accessToken, { userId, academicYear, academicYearData }) {
    try {
      let {
        head_circle,
        height,
        weight,
        distance_from_home_choice,
        distance_from_home,
        mileage,
        transportation
      } = academicYearData

      head_circle = Number(head_circle)
      height = Number(height)
      weight = Number(weight)
      distance_from_home = Number(distance_from_home)
      mileage = Number(mileage)

      const data = await axiosServerKariermu({
        version: 'v2',
        url: `/user/academic-year-data/periodic`,
        method: 'POST',
        headers: {
          Authorization: accessToken
        },
        data: {
          user_id: userId,
          academic_year: academicYear,
          head_circle,
          height,
          weight,
          distance_from_home_choice,
          distance_from_home,
          mileage,
          transportation
        }
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async submitAcademicYearProsperity (accessToken, { userId, academicYear, academicYearData }) {
    try {
      let {
        has_prosperity_card,
        prosperity_cards
      } = academicYearData

      const data = await axiosServerKariermu({
        version: 'v2',
        url: `/user/academic-year-data/prosperity-card`,
        method: 'POST',
        headers: {
          Authorization: accessToken
        },
        data: {
          user_id: userId,
          academic_year: academicYear,
          has_prosperity_card,
          prosperity_cards
        }
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async submitAcademicYearAchievement (accessToken, { userId, academicYear, academicYearData }) {
    try {
      let {
        has_achievement,
        achievements
      } = academicYearData

      const data = await axiosServerKariermu({
        version: 'v2',
        url: `/user/academic-year-data/achievement`,
        method: 'POST',
        headers: {
          Authorization: accessToken
        },
        data: {
          user_id: userId,
          academic_year: academicYear,
          has_achievement,
          achievements
        }
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async validateDapodikParent (accessToken) {
    try {
      const data = await axiosServerKariermu({
        version: 'v2',
        url: '/user/validate-parent-dapodik',
        method: 'GET',
        headers: {
          Authorization: accessToken
        }
      })
      return data
    } catch (err) {
      throw err
    }
  },
  async validateDapodikChildV2 ({ accessToken, userId }) {
    try {
      const data = await axiosServerKariermu({
        version: 'v2',
        url: `/user/validate-child-dapodik/${userId}`,
        method: 'GET',
        headers: {
          Authorization: accessToken
        }
      })
      return data
    } catch (err) {
      throw err
    }
  },
  async getUserPrimaryDataV2 ({ accessToken, childId, section = 'all', academicYear }) {
    try {
      const url = `/user/v2/primary-data/${childId}/${section}?academic_year=${academicYear}`
      const { data } = await axiosServerKariermu({
        version: 'v2',
        url: url,
        method: 'GET',
        headers: {
          Authorization: accessToken
        }
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async saveUserPrimaryDataV2 ({ accessToken, payload }) {
    try {
      const url = `/user/v2/primary-data/main`
      const { data } = await axiosServerKariermu({
        version: 'v2',
        url: url,
        method: 'POST',
        headers: {
          Authorization: accessToken
        },
        data: payload
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async saveUserSiblingsV2 ({ accessToken, payload }) {
    try {
      const url = `/user/v2/primary-data/sibling`
      const { data } = await axiosServerKariermu({
        version: 'v2',
        url: url,
        method: 'POST',
        headers: {
          Authorization: accessToken
        },
        data: payload
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async getUserAcademicYearDataV2 ({ accessToken, childId, academicYear, section = 'all' }) {
    try {
      const { data } = await axiosServerKariermu({
        version: 'v2',
        url: `/user/v2/academic-year-data/${childId}/${section}?academic_year=${academicYear}`,
        method: 'GET',
        headers: {
          Authorization: accessToken
        }
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async submitAcademicYearAddressV2 ({ accessToken, userId, academicYear, academicYearData }) {
    try {
      const {
        backwoods_name,
        master_data_region_id,
        address,
        link_map_address,
        phone
      } = academicYearData

      const { data } = await axiosServerKariermu({
        version: 'v2',
        url: `/user/v2/academic-year-data/address`,
        method: 'POST',
        headers: {
          Authorization: accessToken
        },
        data: {
          user_id: userId,
          academic_year: academicYear,
          backwoods_name,
          master_data_region_id,
          address,
          link_map_address,
          phone
        }
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async submitAcademicYearPeriodicV2 ({ accessToken, userId, academicYear, academicYearData }) {
    try {
      let {
        head_circle,
        height,
        weight,
        distance_from_home_choice,
        distance_from_home,
        mileage,
        transportation
      } = academicYearData

      head_circle = Number(head_circle)
      height = Number(height)
      weight = Number(weight)
      distance_from_home = Number(distance_from_home)
      mileage = Number(mileage)

      const { data } = await axiosServerKariermu({
        version: 'v2',
        url: `/user/v2/academic-year-data/periodic`,
        method: 'POST',
        headers: {
          Authorization: accessToken
        },
        data: {
          user_id: userId,
          academic_year: academicYear,
          head_circle,
          height,
          weight,
          distance_from_home_choice,
          distance_from_home,
          mileage,
          transportation
        }
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async submitAcademicYearProsperityV2 ({ accessToken, userId, academicYear, academicYearData }) {
    try {
      let {
        has_prosperity_card,
        prosperity_cards
      } = academicYearData

      const { data } = await axiosServerKariermu({
        version: 'v2',
        url: `/user/v2/academic-year-data/prosperity-card`,
        method: 'POST',
        headers: {
          Authorization: accessToken
        },
        data: {
          user_id: userId,
          academic_year: academicYear,
          has_prosperity_card,
          prosperity_cards
        }
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async submitAcademicYearAchievementV2 ({ accessToken, userId, academicYear, academicYearData }) {
    try {
      let {
        has_achievement,
        achievements
      } = academicYearData

      const { data } = await axiosServerKariermu({
        version: 'v2',
        url: `/user/v2/academic-year-data/achievement`,
        method: 'POST',
        headers: {
          Authorization: accessToken
        },
        data: {
          user_id: userId,
          academic_year: academicYear,
          has_achievement,
          achievements
        }
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async getSchoolDataV2 ({ accessToken, child_id, academic_year }) {
    const url = `/user/v2/school-data/${child_id}?academic_year=${academic_year}`
    try {
      const { data } = await axiosServerKariermu({
        version: 'v2',
        method: 'GET',
        url,
        headers: {
          Authorization: accessToken
        }
      })
      return data
    } catch (error) {
      console.error(error, 'Failed to get school data')
    }
  },
  async submitPrimarySchoolDataV2 ({ accessToken, schoolData }) {
    // userId, academic_year, primary_school_type, primary_school_id, primary_school_name, primary_school_statement_letter
    try {
      const { data } = await axiosServerKariermu({
        version: 'v2',
        url: `/user/v2/primary-school`,
        method: 'POST',
        headers: {
          Authorization: accessToken
        },
        data: schoolData
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async getPrimarySchoolOptions ({ accessToken, user_id, academic_year }) {
    try {
      const { data } = await axiosServerKariermu({
        version: 'v2',
        url: `/user/primary-school-list/${user_id}?academic_year=${academic_year}`,
        method: 'GET',
        headers: {
          Authorization: accessToken
        }
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async submitInternalSchoolDataV2 ({ accessToken, schoolData }) {
    try {
      const { data } = await axiosServerKariermu({
        version: 'v2',
        url: `/user/v2/school-data`,
        method: 'POST',
        headers: {
          Authorization: accessToken
        },
        data: schoolData
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async getUserDapodikCompletenessStatus (accessToken) {
    try {
      const data = await axiosServerKariermu({
        version: 'v2',
        url: `/user/dapodik/data-completeness`,
        method: 'GET',
        headers: {
          Authorization: accessToken
        }
      })
      return data
    } catch (error) {
      throw error
    }
  },
  async submitParentDataV2 ({ accessToken, payload, section }) {
    try {
      const data = await axiosServerKariermu({
        version: 'v2',
        url: `/user/v2/submit-parent-information/${section}`,
        method: 'POST',
        headers: {
          Authorization: accessToken
        },
        data: {
          ...payload
        }
      })
      return data
    } catch (err) {
      throw err
    }
  },
  async validateDapodikChildV3 ({ accessToken, childId, academicYear }) {
    try {
      const { data } = await axiosServerKariermu({
        version: 'v2',
        url: `/user/v2/validate-child-dapodik/${childId}?academic_year=${academicYear}`,
        method: 'GET',
        headers: {
          Authorization: accessToken
        }
      })
      return data
    } catch (err) {
      throw err
    }
  },
  async getListAcademicYear (accessToken) {
    try {
      const { data } = await axiosServerKariermu({
        version: 'v2',
        url: `/user/dapodik-access-list`,
        method: 'GET',
        headers: {
          Authorization: accessToken
        }
      })
      return data
    } catch (err) {
      throw err
    }
  },
  async getParentDataCompletenessStatus ({ accessToken }) {
    try {
      const { data } = await axiosServerKariermu({
        version: 'v2',
        url: '/user/parent-data/completeness-status',
        method: 'GET',
        headers: {
          Authorization: accessToken
        }
      })
      return data
    } catch (err) {
      throw err
    }
  },
  async cloneParentData ({ accessToken, payload }) {
    try {
      const { data } = await axiosServerKariermu({
        version: 'v2',
        url: '/user/clone/parent-data',
        method: 'POST',
        headers: {
          Authorization: accessToken
        },
        data: payload
      })
      return data
    } catch (err) {
      throw err
    }
  },
  async dapodikLock ({ accessToken, payload }) {
    try {
      const { data } = await axiosServerKariermu({
        version: 'v2',
        url: '/user/dapodik/lock',
        method: 'POST',
        headers: {
          Authorization: accessToken
        },
        data: payload
      })
      return data
    } catch (err) {
      throw err
    }
  },
  async dapodikCopyAll ({ accessToken, payload }) {
    try {
      const { data } = await axiosServerKariermu({
        version: 'v2',
        url: '/user/clone/all-data',
        method: 'POST',
        headers: {
          Authorization: accessToken
        },
        data: payload
      })
      return data
    } catch (err) {
      throw err
    }
  },
  async getParentDetailsV2 ({ accessToken, childId, section, academicYear }) {
    try {
      const url = `/user/v3/parent-information/${childId}/${section}?academic_year=${academicYear}`
      const { data } = await axiosServerKariermu({
        version: 'v2',
        url: url,
        method: 'GET',
        headers: {
          Authorization: accessToken
        }
      })
      return data
    } catch (error) {
      throw error
    }
  }
}
