import axiosServerKariermu from '../../configs/axiosServerKariermu'
import axiosServerGolang from '@/configs/axiosServerGolang'
import deviceDetector from 'mobile-device-detect'

const institution = {
  namespaced: true,
  state: {
    institutions: [],
    totalPage: 0,
    nextUrl: '',
    typeRefs: [],
    typeGroups: [
      { sekolah: ['Sekolah', 'Sekolah lainnya'] },
      { 'perguruan-tinggi': ['Perguruan Tinggi'] },
      { industri: ['Lembaga Pelatihan', 'Balai Latihan Kerja', 'Lembaga Kursus Pelatihan', 'Korporasi', 'Organisasi', 'Museum', 'Tempat Wisata', 'Lembaga Lainnya'] }
    ],
    /*
      DATA REFERENSI TYPE LEMBAGA PER 18 JUNI 2020
      1 : sekolah
      2 : lembaga pelatihan
      3 : balai latihan kerja
      4 : lembaga kursus pelatihan
      5 : korporasi
      6 : perguruan tinggi
      7 : organisasi
      8 : museum
      9 : lembaga lainnya
    */
    hardcodedTypeRefs: [
      { type: 'institution_type', value: '1', label: 'Sekolah', order_id: 1, level: null },
      { type: 'institution_type', value: '2', label: 'Lembaga Pelatihan', order_id: 2, level: null },
      { type: 'institution_type', value: '3', label: 'Balai Latihan Kerja', order_id: 3, level: null },
      { type: 'institution_type', value: '4', label: 'Lembaga Kursus Pelatihan', order_id: 4, level: null },
      { type: 'institution_type', value: '5', label: 'Korporasi', order_id: 5, level: null },
      { type: 'institution_type', value: '6', label: 'Perguruan Tinggi', order_id: 6, level: null },
      { type: 'institution_type', value: '7', label: 'Origanisasi', order_id: 7, level: null },
      { type: 'institution_type', value: '8', label: 'Museum', order_id: 8, level: null },
      { type: 'institution_type', value: '9', label: 'Tempat Wisata', order_id: 9, level: null },
      { type: 'institution_type', value: '101', label: 'Lambaga lainnya', order_id: 10, level: null },
      { type: 'institution_type', value: '102', label: 'Sekolah lainnya', order_id: 11, level: null }
    ]
  },
  mutations: {
    CHANGE_LIST (state, payload) {
      state.institutions = payload
    },
    CHANGE_NEXT_URL (state, payload) {
      state.nextUrl = payload
    },
    CHANGE_TYPE_REFERENCES (state, payload) {
      state.typeRefs = payload
    },
    CHANGE_TOTAL_PAGE (state, payload) {
      state.totalPage = payload
    }
  },
  actions: {
    async fetchChildInstitutionList ({ commit, state, rootState, dispatch }, payload) {
      try {
        const { data } = await axiosServerKariermu({
          url: `/user/parent_institution/${payload.slug}/${payload.currentPage}/18`,
          method: 'GET',
          headers: {
            Authorization: process.env.VUE_APP_APP_KEY
          }
        })
        if (data.status === 200) {
          commit('CHANGE_TOTAL_PAGE', data.total_page)
          return data.data
        } else {
          console.error(data)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async fetchInstitutionList ({ commit, state, rootState, dispatch }, payload) {
      try {
        let url = `/program-core/host/all/1?url=${window.location.href}&source=${deviceDetector.isMobile ? 'web-mobile' : 'web'}`
        if (payload && (payload.type || payload.lembaga)) {
          // url = url.concat('?')
          url = payload.type ? url.concat(`&type=${payload.type}`) : url
          url = payload.lembaga ? url.concat(`&search=${payload.lembaga}`) : url
        }
        const { data } = await axiosServerGolang({
          url: payload && payload.nextUrl ? payload.nextUrl : url,
          method: 'GET',
          headers: {
            Authorization: rootState.storage.accessToken || process.env.VUE_APP_APP_KEY
          }
        })
        if (data.status === 200) {
          if (payload && payload.nextUrl) {
            commit('CHANGE_LIST', [...state.institutions, ...data.data])
          } else {
            commit('CHANGE_LIST', data.data)
          }
          commit('CHANGE_NEXT_URL', data['next_url'])
        } else {
          commit('CHANGE_NEXT_URL', '')
          dispatch('storage/checkToken', data, { root: true })
        }
      } catch (error) {
        commit('CHANGE_NEXT_URL', '')
        console.error(error)
      }
    },
    async fetchTypeReferences ({ commit, state, rootState, dispatch }, payload) {
      try {
        const { data } = await axiosServerKariermu.get('/user/reference/', {
          headers: {
            Authorization: rootState.storage.accessToken || process.env.VUE_APP_APP_KEY
          }
        })
        if (data.status === 200) {
          commit('CHANGE_TYPE_REFERENCES', data.data.filter(refs => refs.type === 'institution_type'))
        } else {
          commit('CHANGE_TYPE_REFERENCES', state.hardcodedTypeRefs)
          dispatch('storage/checkToken', data, { root: true })
        }
      } catch (error) {
        commit('CHANGE_TYPE_REFERENCES', state.hardcodedTypeRefs)
      }
    }
  }
}

export default institution
