import axiosServerKariermu from '../../configs/axiosServerKariermu'
import axiosServerGolang from '../../configs/axiosServerGolang' // golang server

const portofolioV2 = {
  namespaced: true,
  state: {
    isPublicMode: false,
    publicUserData: {
      username: ''
    },
    pagePortofolioLoading: false
  },
  mutations: {
    CHANGE_IS_PUBLIC_MODE (state, payload) {
      state.isPublicMode = payload
    },
    CHANGE_PUBLIC_USER_DATA (state, payload) {
      state.publicUserData = payload
    },
    CHANGE_PAGE_PORTOFOLIO_LOADING (state, payload) {
      state.pagePortofolioLoading = payload
    }
  },
  actions: {
    async getPublicUserProfile ({ rootState, commit, dispatch, state }, payload) {
      if (state.publicUserData.username !== payload) {
        try {
          const { data } = await axiosServerKariermu({
            url: `/user/public/get/${payload}?is_username=1`,
            method: 'GET',
            headers: {
              Authorization: rootState.storage.accessToken || process.env.VUE_APP_APP_KEY
            }
          })
          if (data.status === 200) {
            commit('CHANGE_PUBLIC_USER_DATA', data.data)
          } else {
            this.$store.dispatch('storage/checkToken', data)
            console.log(data)
          }
          commit('CHANGE_PAGE_PORTOFOLIO_LOADING', false)
        } catch (error) {
          console.log(error)
          commit('CHANGE_PAGE_PORTOFOLIO_LOADING', false)
        }
      }
    },
    async getAttainmentList ({ rootState, commit, dispatch, state }, payload) {
      let userId = payload.params.userId
      try {
        const { data } = await axiosServerGolang({
          url: `/portofolio/view/${userId}?public=0`,
          method: 'GET',
          headers: {
            Authorization: rootState.storage.accessToken || process.env.VUE_APP_APP_KEY
          }
        })
        return data
      } catch (error) {
        console.log(error)
      }
    },
    async getPortofolioList ({ rootState, commit, dispatch, state }, payload) {
      let userId = payload.params.userId
      try {
        const { data } = await axiosServerGolang({
          url: `/portofolio/view/${userId}?public=1`,
          method: 'GET',
          headers: {
            Authorization: rootState.storage.accessToken || process.env.VUE_APP_APP_KEY
          }
        })
        return data
      } catch (error) {
        console.log(error)
      }
    },
    async getDetailPortofolio ({ rootState, commit, dispatch, state }, payload) {
      let portofolioId = payload.params.id
      let isPublic = payload.query.isPublic
      try {
        const { data } = await axiosServerGolang({
          url: `/portofolio/detail/${portofolioId}?public=${isPublic}`,
          method: 'GET',
          headers: {
            Authorization: rootState.storage.accessToken || process.env.VUE_APP_APP_KEY
          }
        })
        return data
      } catch (error) {
        console.log(error)
      }
    },
    async saveEditPortofolio ({ rootState, commit, dispatch, state }, payload) {
      try {
        const { data } = await axiosServerGolang({
          url: `/portofolio/manage/header`,
          method: 'POST',
          data: {
            id: payload.id,
            description: payload.description,
            content_url: payload.avatar,
            is_publish: payload.isPublish
          },
          headers: {
            Authorization: rootState.storage.accessToken
          }
        })
        return data
      } catch (error) {
        console.log(error)
      }
    },
    async saveEditPortofolioItem ({ rootState, commit, dispatch, state }, payload) {
      try {
        const { data } = await axiosServerGolang({
          url: `/portofolio/manage/item`,
          method: 'POST',
          data: {
            id: payload.id,
            caption: payload.caption,
            description: payload.description,
            is_publish: payload.isPublish
          },
          headers: {
            Authorization: rootState.storage.accessToken
          }
        })
        return data
      } catch (error) {
        console.log(error)
      }
    }
  }
}

export default portofolioV2
