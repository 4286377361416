<template>
  <transition-group
    name="fade"
    mode="out-in"
  >
    <recommendation-downloading-apps
      :key="'apps-recommendation'"
      class="sticky-top"
      @closeRecommendation="showRecommendation = false"
      v-if="isMobile && showRecommendation"
    ></recommendation-downloading-apps>
    <fun-report-invitation-modals
      :key="'fun-report-modals'"
    />
    <nav key="navbar">
      <div
        class="navbar"
        :class="{'d-none': hideMobileSearch}"
      >
        <div class="left-nav">
          <div
            id="nav-burger-btn"
            class="nav-burger"
            @click="toggleDropdown()"
          >
            <transition
              name="fade"
              mode="out-in"
            >
              <img
                :src="imageDropdown"
                :key="imageDropdown"
                class="icon-filter-persian-blue-400"
                alt="Sekolah.mu"
              />
            </transition>
            <!-- notif here -->
            <div v-if="isShowingBurgerNotification" class="navbar-mobile-notification"></div>
          </div>

          <!-- Logo -->
          <router-link to="/" id="navbar-homepage-logo" class="d-flex align-items-center" :style="{maxWidth: envPlatinum ? '119px' : 'none'}" @click.native="closeDropdownAllMobileDropdown">
            <template v-if="envPlatinum">
              <img
                :src="platinumInstitutionInfo.logo_2 ? platinumInstitutionInfo.logo_2 : platinumInstitutionInfo.logo"
                alt="/"
                id="mitra-platinum-logo"
                v-if="platinumInstitutionInfo.logo_2 || platinumInstitutionInfo.logo"
              >
            </template>
            <img
              v-else
              alt="/"
              :class="['sekolahmu-logo', {'mobile-platinum-hide': envPlatinum}]"
            />
          </router-link>
          <div
            id="separation-prakerja"
            class="mx-2"
            v-if="!envBelajar && !isMobile"
          ></div>
          <a
            href="https://www.prakerja.go.id/"
            target="_blank"
            v-if="!envBelajar"
          >
            <img
              src="../../assets/images/kartu_prakerja.png"
              class="prakerja-logo"
            />
          </a>
          <!-- End of Logo -->

          <searchbar ref="desktop-searchbar" v-model="searchTitle" class="search-bar" @show="closeDropdownAllDropdown()"></searchbar>
        </div>
        <div class="mobile-right-nav-wrapper">
          <span
            v-if="!envPlatinum"
            id="navbar-product-dropdown-button"
            class="font-subtitle-4 product-dropdown product-dropdown-mobile"
            @click.prevent="toggleCategoryMobileDropdown()"
          >
            <span class="mr-1" v-if="!isLogin">Produk Kami</span>
            <font-awesome-icon icon="chevron-up" v-if="categoryStateMobile"/>
            <font-awesome-icon icon="chevron-down" v-else/>
          </span>
          <router-link
            v-if="isLogin"
            class="toggle-search"
            :to="validateRoute('/daftar-pilihan?utm_source=sekolahmu&utm_medium=navigation-bar', ['email'])"
            @click.native="validateData(['email']), closeDropdownAllMobileDropdown()"
            id="navbar-daftar-pilihan-route"
          >
            <icon-cart />
          </router-link>
          <router-link
            v-if="isLogin"
            class="toggle-search"
            to="/notifikasi?utm_source=sekolahmu&utm_medium=navigation-bar"
            id="navbar-notification-route"
            @click.native="closeDropdownAllMobileDropdown()"
          >
            <icon-notifikasi ref="iconNotifikasiRef" />
          </router-link>
          <img
            src="https://cdn.sekolah.mu/assets/navbar_v2/icons/blue-search-icon.svg"
            alt="Sekolah.mu"
            class="toggle-search icon-filter-persian-blue-400"
            width="20"
            height="20"
            @click="toggleSearchMobile()"
            id="navbar-toggle-search-mobile"
          />
          <div class="right-nav-wrapper">
            <template v-if="!envPlatinum">
              <button
                id="navbar-product-dropdown-button"
                :class="[ 'font-label-1', 'product-dropdown', { 'mr-4': !isLogin, 'min-width-0': !showProductLabel }]"
                @click.prevent="toggleCategoryDropdown()"
              >
                <span v-if="showProductLabel">
                  Produk Kami
                </span>
                <font-awesome-icon icon="chevron-up" v-if="categoryState"/>
                <font-awesome-icon icon="chevron-down" v-else/>
              </button>
              <b-collapse v-model="categoryState" id="nav-collapse-business-unit" :class="{'nav-collapse-business-unit-login': isLogin}">
                <navbar-product-list @toggle="toggleBUDropdown" @logevent="handleLogEvent"></navbar-product-list>
              </b-collapse>
              <div v-if="categoryState" class="product-overlay"></div>
            </template>

            <div v-if="!isLogin" class="login-register-wrapper">
              <router-link id="navbar-button-to-login" to="/login">
                <s-button
                  variant="secondary"
                  pill
                  @onClick="saveLoginRedirect('login')"
                ><span class="font-subtitle-4">Masuk</span></s-button>
              </router-link>

              <router-link id="navbar-button-to-register" to="/register">
                <s-button
                  variant="primary"
                  pill
                  @onClick="saveLoginRedirect('sign_up_navbar_click')"
                ><span class="font-subtitle-4">Daftar</span></s-button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="desktop-right-nav-wrapper" v-if="isLogin">
          <router-link
            to="/kelasku?utm_source=sekolahmu&utm_medium=navigation-bar"
            id="navbar-route-programmu-button"
            class="navigation-icon-list"
            @click.native="closeDropdownAllDropdown(); handleLogEvent('kelasku_quick_access_click')"
          >
            <icon-programmu />
          </router-link>

          <router-link
            to="/jadwal?utm_source=sekolahmu&utm_medium=navigation-bar"
            id="navbar-route-jadwal-button"
            class="navigation-icon-list"
            @click.native="handleLogEvent('jadwal_quick_access_click')"
          >
            <icon-jadwal />
          </router-link>

          <span
            @click.prevent="toggleDropdownNotification(); handleLogEvent('notifikasi_quick_access_click')"
            @mouseenter="toggleDropdownNotification(); handleLogEvent('notifikasi_quick_access_click')"
            id="navbar-dropdown-notification-button"
            class="navigation-icon-list"
          >
            <icon-notifikasi ref="iconNotifikasiRef" />
          </span>

          <router-link
            :to="validateRoute('/daftar-pilihan?utm_source=sekolahmu&utm_medium=navigation-bar',['email'])"
            @click.native="validateData(['email']); handleLogEvent('daftar_pilihan_quick_access_click')"
            id="navbar-route-cart-button"
            class="navigation-icon-list"
          >
            <icon-cart />
          </router-link>

          <b-collapse
            v-model="dropdownNotification.state"
            id="nav-collapse-notification"
          >
            <div class="notification-container" @mouseleave="toggleDropdownNotification">
              <!-- Tab Program/Info -->
              <div class="notification-tab">
                <div
                  class="notif-program-tab inter-medium_small-bold"
                  :class="{'notif-tab-child-active':dropdownNotification.activeTab=='program', 'notif-tab-child':dropdownNotification.activeTab!=='program'}"
                  @click.prevent="changeNotificationTab('program')"
                >Kelas</div>
                <div
                  class="notif-info-tab inter-medium_small-bold"
                  :class="{'notif-tab-child-active':dropdownNotification.activeTab=='info', 'notif-tab-child':dropdownNotification.activeTab!=='info'}"
                  @click.prevent="changeNotificationTab('info')"
                >Info</div>
              </div>
              <!-- Loading -->
              <div class="notification-loading" v-if="dropdownNotification.isLoading">
                <b-spinner variant="primary"></b-spinner>
              </div>
              <!-- Empty State -->
              <div class="notification-empty-state" v-if="dropdownNotification.data.length == 0 && !dropdownNotification.isLoading">
                <div class="notification-empty-state-wording">
                  <div class="wording-title inter-medium-bold">Belum Ada Notifikasi</div>
                  <div class="wording-body inter-medium_small-normal">{{`Notifikasi ${dropdownNotification.activeTab === 'program' ? 'kelas' : 'info'} akan muncul di sini.`}}</div>
                </div>
              </div>
              <!-- List -->
              <div class="notification-list-container" v-if="dropdownNotification.data.length && !dropdownNotification.isLoading">
                <div
                  v-for="(notificationData, index) in dropdownNotification.data"
                  :key="index"
                  @click.prevent="gotoNotificationDetail(notificationData.id, notificationData.object_type)"
                >
                  <notification-card-v2 :notificationData="notificationData"></notification-card-v2>
                </div>
              </div>
              <!-- Goto /notifikasi -->
              <div class="notification-list-more inter-medium_small-bold" v-if="dropdownNotification.data.length && !dropdownNotification.isLoading">
                <router-link to="/notifikasi?utm_source=sekolahmu&utm_medium=navigation-bar">
                  <div class="expand-notification-btn" @click="dropdownNotification.state = false">
                    Lihat Semua
                  </div>
                </router-link>
              </div>
            </div>
          </b-collapse>
          <div @click.stop="toggleDropdownProfile(); handleLogEvent('akun_quick_access_click')" class="navbar-avatar-wrapper" id="navbar-profile-dropdown-avatar">
            <img
              :src="`${urlServerImage}/300x300,fit/${getAvatar}`"
              class="image-user"
            />
          </div>
          <b-collapse
            v-model="dropdownProfile"
            id="nav-collapse-profile"
          >
            <div class="inside-profile">
              <profile-header ref="profileHeaderRef" @closeDropdown="dropdownProfile = false"></profile-header>
              <div class="inside-profile-nav-container">
                <div
                  v-if="accountMenuConfig"
                  id="parent-guide-setting"
                  class="inside-profile-nav-section"
                >
                  <router-link
                    :to="dataWajibMuridUrl"
                    class="profile-nav-container"
                    @click.native="clickParentPageMenu('menudatawajibmurid_click')"
                  >
                    <img src="@/assets/icons/data-pendidikan.svg" alt="Sekolah.mu" class="feature-icon">
                    <h5 class="font-body-3 inside-profile-nav">Data Wajib Murid</h5>
                    <s-tag
                      variant="solid-crimson"
                      type="category"
                      class="ml-2"
                    >
                      Baru
                    </s-tag>
                  </router-link>

                  <router-link
                    to="/dokumen-panduan?utm_source=sekolahmu&utm_medium=navigation-bar-akun"
                    class="profile-nav-container"
                    @click.native="clickParentPageMenu('halaman_orang_tua_menu_click')"
                  >
                    <img src="https://cdn.sekolah.mu/assets/icon/parent-guide/panduan-orang-tua-sidebar.svg" alt="Sekolah.mu" class="feature-icon">
                    <h5 class="font-body-3 inside-profile-nav">Dokumen Panduan</h5>
                  </router-link>
                </div>
                <div class="inside-profile-nav-section">
                  <router-link
                    to="/tanyamu?utm_source=sekolahmu&utm_medium=navigation-bar-akun"
                    class="profile-nav-container"
                  >
                    <img src="https://cdn.sekolah.mu/assets/v2/menu-icon/tanyamu.svg" alt="Sekolah.mu" class="feature-icon">
                    <h5 class="font-body-3 inside-profile-nav">Tanya.mu</h5>
                  </router-link>
                </div>
                <div class="inside-profile-nav-section" v-if="!isSMM">
                  <a
                    :href="`${this.paymentUrl}`"
                    class="profile-nav-container"
                  >
                    <img src="https://cdn.sekolah.mu/assets/v2/menu-icon/paket-kelas.svg" alt="Sekolah.mu" class="feature-icon">
                    <h5 class="font-body-3 inside-profile-nav">Paket Kelas</h5>
                  </a>
                </div>
                <div class="inside-profile-nav-section">
                  <div class="profile-nav-container clickable" @click="openFunReportApps" v-if="isShowFunReport">
                    <img src="https://cdn.sekolah.mu/avatar/112189/a1116d16902a45e0b229385ecaba95d3.png" alt="Sekolah.mu" class="feature-icon">
                    <h5 class="font-body-3 inside-profile-nav">Jurnal <br> Refleksimu 2021</h5>
                    <span class="new-inside-profile-nav">Baru</span>
                  </div>
                  <router-link
                    :to="validateRoute('/portofolio/profil?utm_source=sekolahmu&utm_medium=navigation-bar-akun', ['email','profile'])"
                    @click.native="validateData(['email','profile'])"
                    class="profile-nav-container"
                  >
                    <img src="https://cdn.sekolah.mu/assets/v2/menu-icon/portfolio.svg" alt="Sekolah.mu" class="feature-icon">
                    <h5 class="font-body-3 inside-profile-nav">Portofolio</h5>
                  </router-link>
                  <router-link
                    to="/rapor-murid?utm_source=sekolahmu&utm_medium=navigation-bar-akun"
                    class="profile-nav-container"
                  >
                    <img src="https://cdn.sekolah.mu/assets/v2/menu-icon/transkrip.svg" alt="Sekolah.mu" class="feature-icon">
                    <h5 class="font-body-3 inside-profile-nav">Rapor</h5>
                  </router-link>
                </div>
                <div id="navbar-click-to-close" @click.prevent="toggleDropdownProfile" class="inside-profile-nav-section">
                  <router-link
                    v-if="!isSMM"
                    :to="validateRoute('/tukar-voucher?utm_source=sekolahmu&utm_medium=navigation-bar-akun', ['email','profile'])"
                    @click.native="validateData(['email','profile'])"
                    class="profile-nav-container"
                  >
                    <img src="https://cdn.sekolah.mu/assets/v2/menu-icon/tukar-voucher.svg" alt="Sekolah.mu" class="feature-icon">
                    <h5 class="font-body-3 inside-profile-nav">Tukar Voucher</h5>
                  </router-link>
                  <router-link
                    to="/daftar-transaksi?utm_source=sekolahmu&utm_medium=navigation-bar-akun"
                    class="profile-nav-container"
                  >
                    <img src="https://cdn.sekolah.mu/assets/v2/menu-icon/daftar-transaksi.svg" alt="Sekolah.mu" class="feature-icon">
                    <h5 class="font-body-3 inside-profile-nav">Daftar Transaksi</h5>
                  </router-link>
                  <router-link
                    to="/daftar-tagihan?utm_source=sekolahmu&utm_medium=navigation-bar-akun"
                    class="profile-nav-container">
                    <img src="https://cdn.sekolah.mu/assets/v2/menu-icon/daftar-tagihan.svg" alt="Sekolah.mu" class="feature-icon">
                    <h5 class="font-body-3 inside-profile-nav">Daftar Tagihan</h5>
                  </router-link>
                </div>
                <div id="inside-profile-account-setting" @click.prevent="toggleDropdownProfile" class="inside-profile-nav-section">
                  <router-link
                    to="/profile?utm_source=sekolahmu&utm_medium=navigation-bar-akun"
                    class="profile-nav-container">
                    <img src="https://cdn.sekolah.mu/assets/v2/menu-icon/lihat-profil.svg" alt="Sekolah.mu" class="feature-icon">
                    <h5 class="font-body-3 inside-profile-nav">Pengaturan Akun</h5>
                  </router-link>
                </div>
                <div>
                  <div class="profile-nav-container pb-3">
                    <img src="https://cdn.sekolah.mu/assets/v2/menu-icon/exit.svg" alt="Sekolah.mu" class="feature-icon">
                    <h5 class="font-body-3 user-logout-button" @click="logout()">Keluar</h5>
                  </div>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>

      <div
        class="search-mobile"
        :class="{'d-none': !hideMobileSearch}"
      >
        <s-button
          id="close-searchbar-btn"
          variant="link"
          class="close-searchbar"
          @onClick="toggleSearchMobile()"
        >
          <font-awesome-icon icon="arrow-left"/>
        </s-button>

        <searchbar ref="mobile-searchbar" v-model="searchTitle" class="search-bar2" @open="closeDropdownAllMobileDropdown()"></searchbar>
      </div>

      <!-- <sub-navbar :menus="menuList" v-if="!envBelajar"></sub-navbar> -->
      <b-collapse
        v-model="dropdown"
        id="nav-collapse"
      >
        <template v-if="!isLogin">
          <login-collapse-mobile
            @closeDropdown="closeDropdownAllMobileDropdown"
            @toggleDaftar="toggleDaftar"
            @toggleLogin="toggleLogin"
          />
        </template>
        <template v-else>
          <profile-collapse-mobile
            ref="profileHeaderMobileRef"
            @closeDropdown="closeDropdownAllMobileDropdown"
            @logout="logout"
          />
        </template>
      </b-collapse>

      <b-collapse
        v-model="categoryStateMobile"
        id="nav-collapse-year-level-mobile"
      >
        <navbar-product-list @toggle="closeDropdownAllMobileDropdown" @logevent="handleLogEvent"></navbar-product-list>
      </b-collapse>

      <b-modal
        v-model="modalNewPassword"
        hide-footer
        hide-header
        centered
        size="new-password"
      >
        <modal-new-password
          @toggleNewPassword="toggleNewPassword"
          @toggleDaftar="toggleDaftar"
          @toggleMasuk="toggleMasuk"
          @toggleConfirmationPassword="toggleConfirmationPassword"
          class="open-sans"
        />
      </b-modal>

      <b-modal
        v-model="modalConfirmationPassword"
        hide-footer
        hide-header
        centered
        size="new-password"
      >
        <modal-confirmation-password
          @toggleNewPassword="toggleNewPassword"
          @toggleDaftar="toggleDaftar"
          @toggleMasuk="toggleMasuk"
          @toggleConfirmationPassword="toggleConfirmationPassword"
          class="open-sans"
        />
      </b-modal>

      <b-modal
        v-model="modalConfirmationRegister"
        hide-footer
        hide-header
        centered
        size="new-password"
      >
        <modal-confirmation-register
          @toggleNewPassword="toggleNewPassword"
          @toggleDaftar="toggleDaftar"
          @toggleMasuk="toggleMasuk"
          @toggleConfirmationPassword="toggleConfirmationPassword"
          @toggleConfirmationRegister="toggleConfirmationRegister"
          class="open-sans"
        />
      </b-modal>

      <b-modal
        v-model="isShowEmailVerificationModal"
        hide-footer
        hide-header
        centered
        size="login"
      >
        <modal-email-verification
          @toggleEmailVerification="toggleEmailVerification"
          @notYetVerified="() => {
            isShowEmailNotYetVerified = !isShowEmailNotYetVerified
            isShowEmailVerificationModal = !isShowEmailVerificationModal
          }"
          class="open-sans"
        ></modal-email-verification>
      </b-modal>

      <b-modal
        v-model="isShowEmailNotYetVerified"
        hide-footer
        hide-header
        centered
        size="login"
      >
        <modal-not-yet-verified
          @toggleEmailNotYetVerified="() => {
            isShowEmailNotYetVerified = !isShowEmailNotYetVerified
            isShowEmailVerificationModal = !isShowEmailVerificationModal
          }"
          @closeEmailNotYetVerified="() => { isShowEmailNotYetVerified = !isShowEmailNotYetVerified }"
        ></modal-not-yet-verified>
      </b-modal>

      <b-modal
        v-model="isShowProfileUncompleted"
        :no-close-on-esc="true"
        :no-close-on-backdrop="true"
        centered
        size="sm"
        :hide-footer="true"
        :hide-header="true"
      >
        <modal-profile-uncompleted @toggleProfileUncompleted="() => isShowProfileUncompleted = !isShowProfileUncompleted"></modal-profile-uncompleted>
      </b-modal>

      <b-modal
        v-model="showBlockerProgramTerbatas"
        no-close-on-backdrop
        no-close-on-esc
        centered
        hide-footer
        hide-header
        size="sm"
      >
        <modal-blocker-program-terbatas @toggleModalMasuk="toggleMasuk(false)"></modal-blocker-program-terbatas>
      </b-modal>

      <template v-if="/* isDomainPrakerja */ false">
        <b-modal
          v-model="activePrakerja"
          hide-footer
          hide-header
          centered
          size="login"
          no-close-on-backdrop
          no-close-on-esc
          content-class="modal-content-semi_round"
        >
          <modal-closed-prakerja></modal-closed-prakerja>
        </b-modal>
      </template>
      <modal-authentication
        :is-open="isOpenModalAuth"
        :redirect-path="redirectPath"
        @toggleModalAuthentication="toggleModalAuthentication"
      ></modal-authentication>
    </nav>

    <otp-verification
      :key="'otp-verification'"
    />
  </transition-group>
</template>

<script>
import { mapState } from 'vuex'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import axiosServer from '../../configs/axiosServer.js'
import ModalNewPassword from './ModalNewPassword'
import ModalConfirmationPassword from './ModalConfirmationPassword'
import ModalConfirmationRegister from './ModalConfirmationRegister'
import ModalEmailVerification from '@/components/validator/ModalEmailVerification'
import ModalNotYetVerified from '@/components/validator/ModalNotYetVerified'
import ModalProfileUncompleted from '@/components/validator/ModalProfileUncompleted'
import ModalBlockerProgramTerbatas from '@/components/validator/ModalBlockerProgramTerbatas'
import ModalClosedPrakerja from '@/components/validator/ModalClosedPrakerja'
import ProfileHeader from '@/components/navbar/ProfileHeader'
import Swal from 'sweetalert2'
import errorHandler5xx_desktop from '@/assets/images/errorHandler/login_5xx_desktop.png'
import errorHandler5xx_mobile from '@/assets/images/errorHandler/login_5xx_mobile.png'
// improvement by Harfi
import RecommendationDownloadingApps from '@/components/navbar/RecommendationDownloadingApps'
import Searchbar from '@/components/navbar/Search/Searchbar.vue'
import deviceDetector from 'mobile-device-detect'
import moment from 'moment'
import NotificationCardV2 from '@/components/notification/NotificationCardV2'
// Navbar Icon
import IconJadwal from '@/components/navbar/iconNavbar/IconJadwal.vue'
import IconNotifikasi from '@/components/navbar/iconNavbar/IconNotifikasi.vue'
import IconProgrammu from '@/components/navbar/iconNavbar/IconProgrammu.vue'
import IconCart from '@/components/navbar/iconNavbar/IconCart.vue'
// firebase notification
import MessagingService from '../../modules/messaging-service'
import desktopNotification from '../../functions/pushNotification/desktopNotification'
// cards
// mobile dropdowns
import ProfileCollapseMobile from '@/components/navbar/dropdowns/ProfileCollapseMobile.vue'
import LoginCollapseMobile from '@/components/navbar/dropdowns/LoginCollapseMobile.vue'
import FunReportInvitationModals from '@/components/flashbackSekolahmu/FunReportInvitationModals.vue'

// otp
import OtpVerification from '@/components/otpUserVerification/OtpVerification.vue'

import SButton from '@/components/atoms/buttons/SButton.vue'
import ModalAuthentication from '@/components/navbar/ModalAuthentication.vue'

// coach mark
import tourHelper from '@/functions/tourHelper.js'

// MIXINS
import setAuthPayload from '@/mixins/setAuthPayload.js'
import tourDestroyOnBack from '@/mixins/tourDestroyOnBack.js'
import smmConfig from '@/mixins/smmConfig.js'
import parentPageEventTrackerMixin from '@/mixins/parentPageEventTrackerMixin.js'
import firebaseLogEvent from '@/mixins/firebaseLogEvent.js'

export default {
  components: {
    FontAwesomeIcon,
    ModalNewPassword,
    ModalConfirmationPassword,
    ModalConfirmationRegister,
    ModalEmailVerification,
    ModalNotYetVerified,
    ModalProfileUncompleted,
    ModalBlockerProgramTerbatas,
    RecommendationDownloadingApps,
    Searchbar,
    NavbarProductList: () => import('@/components/navbar/ProductList.vue'),
    NotificationCardV2,
    IconJadwal,
    IconNotifikasi,
    IconProgrammu,
    IconCart,
    ModalClosedPrakerja,
    ProfileCollapseMobile,
    LoginCollapseMobile,
    FunReportInvitationModals,
    OtpVerification,
    SButton,
    ModalAuthentication,
    ProfileHeader
  },
  mixins: [setAuthPayload, tourDestroyOnBack, smmConfig, parentPageEventTrackerMixin, firebaseLogEvent],
  data () {
    return {
      hasCoachmark: false,
      isMounted: false,
      showRecommendation: false, // ToDo: Temporary set to false, ref VISION-1004
      isMobile: deviceDetector.isMobile,
      isSignIn: 'test',
      modalRegister: false,
      modalRegisterForm2: false,
      modalRegisterOAuthForm2: false,
      modalNewPassword: false,
      modalConfirmationPassword: false,
      modalConfirmationRegister: false,
      isShowEmailVerificationModal: false,
      isShowEmailNotYetVerified: false,
      isShowProfileUncompleted: false,
      modalOtp: false,
      isSend: false,
      email: '',
      isAgree: false,
      modalMasuk: false,
      isKeepLogin: false,
      passwordUser: '',
      emailUser: '',
      isClickable: false,
      isLoading: false,
      dropdown: false,
      isShowPassword: false,
      errorMessage: '',
      isError: false,
      searchSelect: 'all',
      searchOptions: [
        'Semua',
        'Kelasmu',
        'Ujianmu',
        'Karirmu'
      ],
      dropdownLogin: false,
      hideMobileSearch: false,
      dropdownProfile: false,
      dropdownNotification: {
        activeTab: 'program',
        isLoading: false,
        data: [],
        state: false
      },
      searchTitle: '',
      isLoadingOauth: false,
      isClicked: false,
      // msgService: null,
      categoryState: false,
      categoryStateMobile: false,
      businessUnitState: false,
      windowWidth: window.innerWidth,
      // email autocomplete
      domains: [
        'gmail.com',
        'yahoo.com',
        'hotmail.com',
        'outlook.com'
      ],
      defaultDomains: [
        'gmail.com',
        'yahoo.com',
        'hotmail.com',
        'outlook.com'
      ],
      isFromProgramDetail: false,
      isOpenModalAuth: false,
      redirectPath: {},
      isTourRunning: false,
      tagStyle: {
        width: '32px',
        height: '16px',
        padding: '0px',
        fontSize: '10px !important',
        fontWeight: '700 !important'
      }
    }
  },
  methods: {
    formatAcademicYear (year = '2024/2025') {
      return year.replace('/', '-')
    },
    async getCoachmarkStatus () {
      await this.$store.dispatch('coachmark/getCoachmarkConfig')
      const hasCoachmark = await this.$store.dispatch('coachmark/checkCoachmark', 'relation')
      this.hasCoachmark = hasCoachmark
    },
    setRelationList (value) {
      this.$refs.profileHeaderRef.toggleRelationList(value)
    },
    setRelationListMobile (value) {
      this.$refs.profileHeaderMobileRef.toggleRelationList(value)
    },
    handleLogEvent (eventName) {
      const { id, profession_name, year_level_name, is_admin = false } = this.userInfo
      window.firebaseAnalytics.logEvent(eventName, {
        page_referrer: `${window.location.origin}${this.$route.fullPath}`,
        user_id: id || '',
        user_profession: profession_name || '',
        user_year_level: year_level_name || '',
        user_is_admin: is_admin,
        source: this.isMobile ? 'sekolahmu-webview' : 'sekolahmu-web'
      })
    },
    openFunReportApps () {
      if (this.isMobile) {
        this.$store.commit('funReport/CHANGE_IS_OPEN_MODAL_INVITATION', true)
      } else {
        this.$store.commit('funReport/CHANGE_IS_OPEN_MODAL_INVITATION_QR', true)
      }
      if (this.isLogin) {
        window.firebaseAnalytics.logEvent('jurnal_refleksimu_click', {
          user_profession: this.userInfo.profession_name,
          user_year_level: this.userInfo.year_level_name,
          user_is_parent: !!((this.userInfo.profession_name === 'Orangtua' || this.userInfo.child.length)),
          user_id: this.userInfo.id,
          source: this.isMobile ? 'mobile-web' : 'web'
        })
      }
    },
    openModalRegisterOAuthForm2 () {
      this.modalRegisterOAuthForm2 = true
    },
    openModalRegisterForm2 () {
      this.modalRegisterForm2 = true
    },
    closeModalMasuk () {
      this.modalMasuk = false
      this.$store.commit('program/CHANGE_OPEN_PROGRAM_TERBATAS', { isOpen: this.showBlockerProgramTerbatas, isLoading: false })
    },
    searchNavbar (value) {
      this.searchTitle = value
    },
    toggleDaftar (payload) {
      this.closeDropdownAllDropdown()
      this.closeDropdownAllMobileDropdown()
      this.isSend = false
      this.modalMasuk = false
      this.modalNewPassword = false
      this.modalConfirmationPassword = false
      this.modalConfirmationRegister = false
      this.modalOtp = false

      this.modalRegister = !this.modalRegister

      if (payload?.origin === 'program_detail') {
        this.isFromProgramDetail = true
      }
    },
    toggleLogin () {
      this.closeDropdownAllDropdown()
      this.closeDropdownAllMobileDropdown()
      this.isSend = false
      this.modalMasuk = false
      this.modalNewPassword = false
      this.modalConfirmationPassword = false
      this.modalConfirmationRegister = false
      this.modalOtp = false

      this.modalMasuk = !this.modalMasuk
    },
    toggleEmailVerification () {
      this.isShowEmailVerificationModal = !this.isShowEmailVerificationModal
    },
    toggleMasuk (isClicked) {
      this.modalRegister = false
      this.modalNewPassword = false
      this.modalConfirmationPassword = false
      this.modalConfirmationRegister = false
      this.modalOtp = false
      this.isClicked = !!isClicked

      this.modalMasuk = !this.modalMasuk
    },
    toggleNewPassword () {
      this.modalRegister = false
      this.modalMasuk = false
      this.modalConfirmationPassword = false
      this.modalConfirmationRegister = false
      this.modalOtp = false

      this.modalNewPassword = !this.modalNewPassword
    },
    toggleConfirmationPassword () {
      this.modalRegister = false
      this.modalNewPassword = false
      this.modalMasuk = false
      this.modalConfirmationRegister = false
      this.modalOtp = false

      this.modalConfirmationPassword = !this.modalConfirmationPassword
    },
    toggleConfirmationRegister () {
      this.modalMasuk = false
      this.modalRegister = false
      this.modalNewPassword = false
      this.modalConfirmationPassword = false
      this.modalOtp = false

      this.modalConfirmationRegister = !this.modalConfirmationRegister
    },
    openRegistrationForm2 () {
      this.modalMasuk = false
      this.modalRegister = false
      this.modalNewPassword = false
      this.modalConfirmationPassword = false
      this.modalOtp = false

      this.modalRegisterForm2 = true
    },
    openRegistrationOAuthForm2 () {
      this.modalMasuk = false
      this.modalRegister = false
      this.modalNewPassword = false
      this.modalConfirmationPassword = false
      this.modalOtp = false
      this.modalRegisterForm2 = false
      this.modalRegisterOAuthForm2 = true
    },
    closeAllModal () {
      this.modalMasuk = false
      this.modalRegister = false
      this.modalNewPassword = false
      this.modalConfirmationPassword = false
      this.modalOtp = false
      this.modalRegisterForm2 = false
      this.modalRegisterOAuthForm2 = false
      this.isFromProgramDetail = false
    },
    toggleOtp () {
      this.modalMasuk = false
      this.modalRegister = false
      this.modalNewPassword = false
      this.modalConfirmationPassword = false
      this.modalConfirmationRegister = false

      this.modalOtp = !this.modalOtp
    },
    toHome () {
      this.$router.push('/')
    },
    toRegister () {
      this.toggleDaftar()
      this.toggleMasuk()
    },
    async fetchNotification () {
      this.dropdownNotification.isLoading = true
      let payload = {
        page: 1,
        total_item: 5,
        sort: 'desc',
        types: this.dropdownNotification.activeTab === 'program' ? ['program', 'activity', 'learning_package', 'school_payment'] : ['payment', 'event', 'promo'],
        query: `?type=${this.dropdownNotification.activeTab === 'program' ? 'program,activity,learning_package,school_payment' : 'payment,event,promo'}`
      }

      const dbFirestore = await this.$store.dispatch('user/fetchNotification', payload)
      dbFirestore
        .onSnapshot(collectionSnapshot => {
          if (!collectionSnapshot.empty) {
            this.dropdownNotification.data = collectionSnapshot.docs.map(doc => doc.data())
          } else {
            this.dropdownNotification.data = []
          }
        },
        error => {
          this.dropdownNotification.data = []
          console.error(error)
        })
      this.dropdownNotification.isLoading = false
    },
    getNotifDate (date_input) {
      moment.locale('id')
      return moment(date_input).format('LL')
    },
    gotoNotificationLink (link) {
      if (link) {
        if (link.includes(location.host)) {
          let modLink = '_' + link
          this.dropdownNotification.state = false
          this.$router.push(modLink.split(location.host)[1])
        } else {
          window.open(link, '_blank')
        }
      }
    },
    gotoNotificationDetail (notif_id, notif_type) {
      if (notif_type === 'program' || notif_type === 'activity' || notif_type === 'learning_package' || notif_type === 'school_payment') {
        this.$router.push(`/notifikasi?id=${notif_id}&type=program`)
      } else {
        this.$router.push(`/notifikasi?id=${notif_id}&type=info`)
      }
      this.dropdownNotification.state = false
    },
    async toggleDropdown () { // mobile
      let state = this.dropdown
      this.closeDropdownAllMobileDropdown()
      this.dropdown = !state

      if (this.dropdown) {
        await this.getCoachmarkStatus()
        this.initTour()
        this.autoExpandRelation()
      }
    },
    toggleDropdownLogin () {
      this.dropdownLogin = !this.dropdownLogin
    },
    toggleShowPassword () {
      this.isShowPassword = !this.isShowPassword
    },
    toggleSearchMobile () {
      this.closeDropdownAllMobileDropdown()
      this.$emit('closeOverlay', false)
      this.hideMobileSearch = !this.hideMobileSearch
    },
    async clickParentPageMenu (eventName) {
      if (eventName === 'menudatawajibmurid_click') {
        const childYearLevelId = this.dapodikChildren.find(child => child.id === this.redirectData.childrenId).year_level_id
        this.logEvent('menudatawajibmurid_click', [{
          child_year_level_id: childYearLevelId,
          child_id: this.redirectData.childrenId
        }])
      }

      if (eventName === 'halaman_orang_tua_menu_click') {
        this.sendClickEventTracker('halaman_orang_tua_menu_click')
      }

      await this.toggleDropdownProfile()
    },
    async toggleDropdownProfile () { // desktop
      let state = this.dropdownProfile
      this.closeDropdownAllDropdown()
      this.dropdownProfile = !state
      if (this.dropdownProfile) {
        await this.getCoachmarkStatus()
        this.initTour()
        this.autoExpandRelation()
      }
    },
    toggleDropdownNotification () {
      if (!this.dropdownNotification.state) {
        this.$refs.iconNotifikasiRef.resetNotifCounter()
      }
      let state = this.dropdownNotification.state
      this.closeDropdownAllDropdown()
      this.dropdownNotification.state = !state
      setTimeout(() => {
        this.dropdownNotification.activeTab = 'program'
      }, 300)
      if (this.dropdownNotification.state) {
        this.fetchNotification()
      }
    },
    changeNotificationTab (type) {
      this.dropdownNotification.activeTab = type
    },
    openDropdownProfile () {
      this.dropdownProfile = true
    },
    closeDropdownAllMobileDropdown () {
      this.categoryStateMobile = false
      this.dropdown = false
    },
    closeDropdownAllDropdown () {
      this.dropdownProfile = false
      this.dropdownNotification.state = false
      this.categoryState = false
      this.businessUnitState = false
    },
    closeDropdownAllDropdownNative (e) {
      if (!this.$el.contains(e.target) && !this.isTourRunning) {
        this.dropdownProfile = false
        this.dropdownNotification.state = false
        this.categoryState = false
        this.businessUnitState = false
      }
    },
    toggleBUDropdown () {
      let state = this.businessUnitState
      this.closeDropdownAllDropdown()
      this.businessUnitState = !state
    },
    toggleCategoryDropdown () {
      let state = this.categoryState
      this.closeDropdownAllDropdown()
      this.categoryState = !state
      if (this.isLogin) {
        const additionalInfo = [{ user_is_parent: !!((this.userInfo.profession_name === 'Orangtua' || this.userInfo.child.length)) }]
        this.logEvent('daftar_produk_click', additionalInfo)
      } else {
        this.logEvent('daftar_produk_click')
      }
    },
    toggleCategoryMobileDropdown () {
      let state = this.categoryStateMobile
      this.closeDropdownAllMobileDropdown()
      this.categoryStateMobile = !state
      if (this.isLogin) {
        const additionalInfo = [{ user_is_parent: !!((this.userInfo.profession_name === 'Orangtua' || this.userInfo.child.length)) }]
        this.logEvent('daftar_produk_click', additionalInfo)
      } else {
        this.logEvent('daftar_produk_click')
      }
    },
    // logic danang
    searchProgram () {
      this.$emit('closeOverlay', false)
      this.searchSelect = this.envPlatinum ? 'all' : this.envBelajar ? 'sekolahmu-web' : 'PRAKERJA'
      if (this.searchTitle) {
        let payload = {
          data: {
            category: this.searchSelect,
            search: this.searchTitle,
            subject: '',
            price: '',
            duration: '',
            year_level: '',
            sort: '',
            institution: this.envPlatinum ? this.platinumInstitutionInfo.name : '',
            url: `${window.location.href.split('?')[0]}`,
            user_id: this.userInfo.id,
            source: `${deviceDetector.isMobile ? 'web-mobile' : 'web'}`
          },
          fromNavbar: true
        }

        this.$store.commit('program/CHANGE_SEARCH_PROCESS', true)
        this.$store.commit('program/CHANGE_SEARCH', this.searchTitle)
        this.$store.dispatch('program/searchProgramV2', payload)

        this.$router.push(`/program?search=${this.searchTitle}${this.envPlatinum ? '&institution=' + this.platinumInstitutionInfo.name : ''}&utm_source=sekolahmu&utm_medium=navigation-bar-search `)
      }
    },
    backToProgrammu () {
      this.searchTitle = ''
      this.$store.commit('program/CHANGE_SEARCH', '')
    },
    // end logic danang
    toProfile () {
      this.$router.push('/profile')
    },
    logout () {
      this.$store.dispatch('storage/logoutRemoveStorage')
      // localStorage.clear()

      this.$store.commit('user/CHANGE_IS_LOGIN', false)
      this.$store.commit('user/CHANGE_IS_LOGIN_FROM_PROGRAM_DETAIL', false)
      this.$route.path !== '/' && this.$router.push('/')
      // you can use promise from v1.1.0 also
      this.$store.commit('user/SET_IS_CIKAL', false)
      this.$store.commit('login/SET_EMAIL', '')
      this.$store.commit('user/SET_RESEND_COUNTER', 0)

      //* reset programmu
      const myProfile = this.$store.state.user.myProfile
      let payload = {}
      payload.id = myProfile.id
      payload.name = myProfile.name
      payload.avatar = myProfile.avatar
      payload.year_level = myProfile.year_level
      this.$store.commit('user/CHANGE_USER', payload)
      if (this.$store.state.tanyamu.qiscus) {
        this.$store.dispatch('tanyamu/logoutQiscus')
      }

      if (this.msgService) {
        this.msgService.deleteTokenAsync()
      }
    },
    toRouting (route) {
      this.$router.push(`${route}`)
    },
    route (route) {
      if (this.$route.path === route) {
        return true
      } else return false
    },
    routeFullPath (route, type) {
      if (type === 'includes') {
        if (this.$route.fullPath.includes(route)) {
          return true
        } else {
          return false
        }
      } else {
        if (this.$route.fullPath === route) {
          return true
        } else return false
      }
    },
    kartuPrakerja () {
      if (this.$route.path === '/kartuprakerja' || this.$route.path === '/panduan-prakerja' || this.$route.path === '/mitra-kolaborasi') {
        return true
      } else return false
    },
    karirmu () {
      if (this.$route.path === '/kariermu/industri-kreatif' || this.$route.path === '/kariermu/mesin-konstruksi' || this.$route.path === '/kariermu/pariwisata' || this.$route.path === '/kariermu/layanan-perawatan' || this.$route.path === '/kariermu') {
        return true
      } else return false
    },
    kelasmu () {
      if (this.$route.path === '/kelasmu/pelajar' || this.$route.path === '/kelasmu/mahasiswa' || this.$route.path === '/kelasmu/guru' || this.$route.path === '/kelasmu/orangtua') {
        return true
      } else return false
    },
    ikutProgram () {
      if (this.$route.path === '/ikutprogram') {
        return true
      } else return false
    },
    onOver (ref) {
      this.$refs[ref].visible = true
    },
    onLeave (ref) {
      this.$refs[ref].visible = false
    },
    validateData (dataParts) {
      this.closeDropdownAllMobileDropdown()
      this.closeDropdownAllDropdown()
      if (dataParts.includes('email') && !this.isEmailVerified && !this.userInfo.is_created_without_email) {
        // Tiket 358 avenger, lepas validasi verifikasi email, dipindahkan saat lihat profile
        // this.isShowEmailVerificationModal = true
      } else if (dataParts.includes('profile') && !this.isProfileComplete) {
        this.isShowProfileUncompleted = true
      }
    },
    validateRoute (to, dataParts) {
      const validateProfession = this.professions.filter(profession => this.getProfession.includes(profession.name))
      // Tiket 358 avenger, lepas validasi verifikasi email, dipindahkan saat lihat profile
      // if ((dataParts.includes('email') && !this.isEmailVerified) || (dataParts.includes('profile') && !this.isProfileComplete)) {
      if ((!validateProfession.length) && (to !== '/tanyamu' && to !== '/daftar-pilihan' && to !== '/daftar-transaksi')) {
        return '/daftar'
      } else if (dataParts.includes('profile') && !this.isProfileComplete) {
        return ''
      } else {
        return to
      }
    },
    async verification () {
      const { data } = await axiosServer({
        url: '/user/email_verification/',
        method: 'post',
        headers: {
          Authorization: process.env.VUE_APP_APP_KEY
        },
        data: {
          email: this.userVerification,
          code: this.codeVerification,
          source: 'web'
        },
        generateQueryParamSource: true
      })
      if (data.status === 200) {
        Swal.fire({
          title: 'Email Terverifikasi',
          text: 'Selamat, email anda telah terverifikasi',
          icon: 'success'
        })
        this.$store.dispatch('storage/loginSetStorage', data)
        this.$store.dispatch('user/fetchUserData')
      } else {
        this.$router.push('/404')
      }
      this.$store.commit('SET_VERIFICATION', {
        verification: null, code: null
      })
    },
    async setFbMessaging () {
      this.msgService = new MessagingService()
      // Request permission
      await this.msgService.requestPermissionAsync()
      return this.msgService.getTokenAsync()
    },
    listenFirebaseNotif () {
      if (Notification?.permission === 'granted') {
        this.setFbMessaging()

        window.firebaseMessaging.onMessage((payload) => {
          this.$store.commit('notification/SET_NEW_NOTIF', true)
          desktopNotification(payload.data.title, {
            body: payload.data.body,
            image: payload.data.icon,
            icon: 'https://cdn.sekolah.mu/assets/home/footer_logo.svg'
          }, '/notifikasi')
        })
      }
    },
    saveLoginRedirect (eventName) {
      this.$store.commit('SET_LOGIN_REDIRECTION', {
        path: this.$route.path,
        name: this.$route.name
      })
      window.firebaseAnalytics.logEvent(eventName, {
        screen_url: this.$route.path,
        user_profession: '',
        user_year_level: '',
        user_is_admin: '',
        source: this.isMobile ? 'web_mobile_sekolahmu' : 'web_sekolahmu'
      })
    },
    toggleModalAuthentication (path) {
      this.isOpenModalAuth = !this.isOpenModalAuth
      this.redirectPath = path
    },
    initTour () {
      if (this.hasCoachmark || !this.isParent) {
        return false
      }

      this.isTourRunning = true

      this.$nextTick(() => {
        const tour = this.$shepherd(tourHelper.option)

        let payload = []
        // account-relation-list-container
        if (this.isMobile) {
          payload = [
            {
              element: '#account-relation-list-container',
              image: 'https://cdn.sekolah.mu/assets/coachmark/registrasi/lihat-daftar-relasi.webp',
              title: 'Lihat Daftar Relasi',
              description: 'Kamu bisa melihat akun anak yang telah direlasikan di sini.',
              position: 'bottom',
              usingCustomFunction: true,
              customFunction: () => {
                this.setRelationListMobile(false)
                window.scrollTo(0, 30)
              }
            },
            {
              element: '#account-relation-list-container',
              image: 'https://cdn.sekolah.mu/assets/coachmark/registrasi/tambah-akun-anak.webp',
              title: 'Tambah Akun Anak',
              description: 'Kamu bisa merelasikan atau mendaftarkan akun anak di sini.',
              position: 'bottom',
              usingCustomFunction: true,
              customFunction: () => {
                this.setRelationListMobile(true)
                window.scrollTo(0, 120)
              },
              finishButton: 'Selesai'
            }
          ]
        } else {
          payload = [
            {
              element: '#profile-relation-list',
              image: 'https://cdn.sekolah.mu/assets/coachmark/registrasi/lihat-daftar-relasi.webp',
              title: 'Lihat Daftar Relasi',
              description: 'Kamu bisa melihat akun anak yang telah direlasikan di sini.',
              position: 'left',
              usingCustomFunction: true,
              customFunction: () => {
                this.setRelationList(false)
                window.scrollTo(0, 0)
              },
              scrollTo: true
            },
            { element: '#profile-relation-list',
              image: 'https://cdn.sekolah.mu/assets/coachmark/registrasi/tambah-akun-anak.webp',
              title: 'Tambah Akun Anak',
              description: 'Kamu bisa merelasikan atau mendaftarkan akun anak di sini.',
              position: 'left',
              usingCustomFunction: true,
              customFunction: () => {
                this.setRelationList(true)
                window.scrollTo(0, 120)
              },
              scrollTo: true,
              finishButton: 'Selesai'
            }
          ]
        }

        const steps = tourHelper.parseTourPayload({
          tour,
          payload
        })

        tour.addSteps(steps)
        tour.start()

        // add a scroll 3px to fix bug tour not placed right in filter
        window.scrollTo(0, 30)
        tour.on('complete', () => {
          setTimeout(() => {
            this.isTourRunning = false
          }, 500)
          this.$store.dispatch('coachmark/updateCoachmarkConfig', {
            name: 'relation'
          })
        })
        tour.on('cancel', () => {
          window.scrollTo(0, 0)
          setTimeout(() => {
            this.$store.dispatch('coachmark/updateCoachmarkConfig', {
              name: 'relation',
              status: false
            })
          }, 500)
        })
      })
    },
    autoExpandRelation () {
      if (this.hasCoachmark && this.totalRelations < 3) {
        this.$refs.profileHeaderRef && this.$refs.profileHeaderRef.toggleRelationList(true)
        this.$refs.profileHeaderMobileRef && this.$refs.profileHeaderMobileRef.toggleRelationList(true)
      }
    }
  },
  computed: {
    ...mapState('accountMenu', ['accountMenu']),
    accountMenuConfig () {
      return this.accountMenu.community_page
    },
    dapodikChildren () {
      return this.$store.state.parentPage.dapodikChildren
    },
    showProductLabel () {
      if (!this.isMounted) return false
      return !this.isLogin || !this.$refs['desktop-searchbar'].isShowSuggestion
    },
    enableFunReport () {
      return (String(process.env.VUE_APP_SET_ENABLE_FUN_REPORT) === 'true')
    },
    isShowFunReport () {
      return this.isLogin && this.userInfo.is_have_fun_report && this.enableFunReport
    },
    adminURL () {
      return process.env.VUE_APP_ADMIN_URL
    },
    msgService: {
      get () {
        return this.$store.state.notification.msgService
      },
      set (value) {
        this.$store.commit('notification/SET_MSG_SERVICE', value)
      }
    },
    // dynamic menu list by Harfi
    isJoinedProgramTerbatas () {
      return this.$store.state.program.program.is_joined
    },
    selectedProgramTerbatas () {
      return this.$store.state.program.selectedProgramTerbatas
    },
    showBlockerProgramTerbatas () {
      return this.$store.state.program.isOpeningProgramTerbatas
    },
    userVerification () {
      return this.$store.state.userVerification
    },
    codeVerification () {
      return this.$store.state.codeVerification
    },
    menuList () {
      if (this.envBelajar) {
        return this.$store.state.subnavbar.menuBelajar
      } else {
        return this.$store.state.subnavbar.menuPrakerja
      }
    },
    professions () {
      return this.$store.state.profession.professions
    },
    userInfo () {
      return this.$store.state.user.userInfo
    },
    isEmailVerified () {
      return this.$store.state.user.userInfo.is_email_verified
    },
    isCreatedWithoutEmail () {
      return this.$store.state.user.userInfo.is_created_without_email
    },
    isProfileComplete () {
      const { email, name, year_level_id, city, occupation } = this.$store.state.user.userInfo

      if (this.getProfession.toLowerCase().includes('murid')) {
        return !!email && !!name && !!year_level_id /* && !!institution */
      } else if (this.getProfession.toLowerCase().includes('guru')) {
        return !!email && !!name /* && !!institution */
      } else if (this.getProfession.toLowerCase().includes('orangtua')) {
        return !!email && !!name
      } else if (this.getProfession.toLowerCase().includes('mahasiswa')) {
        return !!email && !!name /* && !!institution */
      } else if (this.getProfession.toLowerCase().includes('profesional') || this.getProfession.toLowerCase().includes('professional')) {
        return !!email && !!name
      } else if (this.getProfession.toLowerCase().includes('prakerja')) {
        return !!email && !!name && !!city && !!occupation
      } else {
        return false
      }
    },
    getName () {
      return this.$store.state.user.userInfo.name || this.$store.state.storage.name
    },
    getProfession () {
      return this.$store.state.user.userInfo.profession_name || this.$store.state.storage.profession
    },
    getAvatar () {
      return this.$store.state.user.userInfo.avatar || this.$store.state.storage.avatar
    },
    urlServerImage () {
      return process.env.VUE_APP_URL_SERVER_IMAGE
    },
    activeEnv () {
      return this.$store.state.activeEnv
    },
    imageDropdown () {
      if (this.dropdown) {
        return 'https://cdn.sekolah.mu/assets/navbar_v2/icons/blue-close-icon.svg'
      } else {
        return 'https://cdn.sekolah.mu/assets/navbar_v2/icons/hamburger-menu-icon.svg'
      }
    },
    imageDropdownLogin () {
      if (this.dropdownLogin) {
        return require('../../assets/icons/icon_close_nav.svg')
      } else {
        return require('../../assets/icons/icon_bars.svg')
      }
    },
    inputType () {
      if (this.isShowPassword) {
        return 'text'
      } else {
        return 'password'
      }
    },
    isLogin () {
      return this.$store.state.user.isLogin
    },
    isCikal () {
      return this.$store.state.user.isCikal
    },
    envBelajar () {
      return this.$store.state.envBelajar
    },
    envPlatinum () {
      return this.$store.state.envPlatinum
    },
    platinumInstitutionInfo () {
      return this.$store.state.institution.platinumInstitutionInfo
    },
    activePrakerja () {
      return !this.$store.state.envBelajar
    },
    isDomainPrakerja () {
      if (window.location.hostname.includes('prakerja') || window.location.pathname.includes('prakerja')) {
        return true
      } else {
        return false
      }
    },
    errorHandling5xx_img () {
      if (window.innerWidth > 1000) {
        return errorHandler5xx_desktop
      } else {
        return errorHandler5xx_mobile
      }
    },
    errorHandling5xx_width () {
      if (window.innerWidth > 700) {
        return 328
      } else {
        return 278
      }
    },
    showReflection () {
      return process.env.VUE_APP_REFLECTION_CONFIG === 'show'
    },
    paymentUrl () {
      return process.env.VUE_APP_DOMAIN_BAYAR + '/paket'
    },
    term () {
      return this.$store.getters.termText
    },
    isNotificationSupported () {
      return this.$store.state.notification.isNotificationSupported
    },
    isParent () {
      return this.userInfo.profession_name !== 'Murid'
    },
    isShowingBurgerNotification () {
      return !this.dropdown && this.isParent && !this.hasCoachmark && this.isLogin
    },
    totalRelations () {
      return (this.userInfo.child?.length || 0) + (this.userInfo.sibling?.length || 0) + (this.userInfo.parent?.length || 0)
    },
    platinumConfig () {
      return this.$store.state.institution.platinumConfig
    },
    userDataCompleteness () {
      return this.$store.state.parentPage.dataCompleteness
    },
    redirectData () {
      return {
        academicYear: this.userDataCompleteness.academic_year,
        childrenId: this.userDataCompleteness.children.length ? this.userDataCompleteness.children[0].child_id : this.userInfo.id
      }
    },
    dataWajibMuridUrl () {
      const academicYear = this.academicYears[0] ? this.academicYears[0].label : this.redirectData.academicYear
      if (!this.isMobile) {
        const formattedYear = this.formatAcademicYear(academicYear)
        return `/data-pendidikan/${formattedYear}/profil-anak/${this.redirectData.childrenId}/data-utama`
      } else if (this.isMobile) {
        return '/halaman-orang-tua/dokumen-panduan?show-menu=1&utm_source=sekolahmu&utm_medium=navigation-bar-akun'
      }
    },
    isDev () {
      const hostName = location.hostname.trim().toLowerCase()
      return hostName.includes('.dev.sekolah.mu') || hostName.includes('localhost')
    },
    academicYears () {
      return this.$store.state.parentPage.academicYears
    }
  },
  watch: {
    emailUser (newValue, oldValue) {
      if (this.passwordUser && newValue) {
        this.isClickable = true
      } else {
        this.isClickable = false
      }
    },
    passwordUser (newValue, oldValue) {
      if (this.emailUser && newValue) {
        this.isClickable = true
      } else {
        this.isClickable = false
      }
    },
    searchTitle (val) {
      if (val === '') {
        this.$store.commit('program/CHANGE_SEARCH_PROCESS', false)
      }
    },
    isLogin (isLoggedIn) {
      if (isLoggedIn && !this.professions.length) {
        this.$store.dispatch('profession/fetchProfessionList')
      }
    },
    'dropdownNotification.activeTab' () {
      if (this.dropdownNotification.state) {
        this.fetchNotification()
      }
    }
  },
  created () {
    document.addEventListener('click', this.closeDropdownAllDropdownNative)

    setTimeout(() => {
      if (this.$route.query.masuk === 'email') {
        if (!this.isLogin) {
          this.toggleMasuk()
        }
      } else if (this.$route.query.masuk === 'passwordbaru') {
        if (this.isLogin) {
          this.toggleNewPassword()
        }
      } else if (this.$route.query.daftar === 'email') {
        if (!this.isLogin) {
          this.toggleDaftar()
        }
      }
    }, 300)
    if (this.isLogin && !this.professions.length) {
      this.$store.dispatch('profession/fetchProfessionList')
    }

    // verification on every page by Harfi
    if (this.userVerification && this.codeVerification) {
      this.verification()
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.isMounted = true
    })
  }
}
</script>
<style>
.modal-dialog{
  margin: 1.5rem auto
}
</style>
<style scoped>
@import "../../styles/NavbarStyle.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

a {
  text-decoration: none;
  color: inherit;
}
#separation-prakerja {
  border-left: 2px solid #4d4d4d;
  width: 1px;
  height: 25px;
}
.product-overlay {
  position: fixed;
  top: 65px;
  left: 0;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  opacity: 0.5;
  background-color: #000;
}

.modal-dialog {
  max-width: 50px !important;
}

nav {
  background-color: var(--color-neutral-100);
  height: 64px;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 8px rgba(51, 51, 51, 0.1);
  position: fixed;
  width: 100vw;
  z-index: 100;
}

.nav-login-pc {
  position: relative;
}

.pos-relative {
  position: relative;
}

.pos-relative span {
  position: absolute;
  background: #eb5757;
  border-radius: 10px;
  color: var(--color-neutral-100);
  font-weight: 600;
  font-size: 8px;
  padding: 0px 4px;
  right: -28px;
  top: 0px;
}

.close-searchbar {
  width: 54px !important;
  height: 54px !important;
  text-decoration: none;
  color: var(--color-text-100);
}

.select-search {
  background-image: linear-gradient(45deg, transparent 50%, var(--color-neutral-500) 60%),
    linear-gradient(135deg, var(--color-neutral-500) 40%, transparent 50%) !important;
  background-position: calc(105% - 25px) 11px, calc(105% - 15px) 11px, 100% 0;
  background-size: 10px 10px, 10px 10px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: rgba(0, 0, 0, 0.7);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border: 0;
  text-indent: 5px;
  width: 150px;
  outline: none;
  height: 100%;
  line-height: 15px;
  font-size: 14px;
}

.prakerja-logo{
  width: 70px;
  cursor: pointer;
  margin-right: 40px;
}

.search-mobile {
  width: 100%;
  padding-right: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s;
}

.search-bar2 {
  display: block;
}

.navbar {
  display: flex;
  justify-content: space-between;
  transition: 0.3s;
  margin: 0 auto;
  width: 1344px;
  flex-wrap: nowrap;
}

.login-register-wrapper {
  display: flex;
  gap: 16px;
}

#nav-collapse {
  position: absolute;
  top: 64px;
  width: 100%;
  background-color: var(--color-neutral-100);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow: auto;
}

#nav-collapse-year-level-mobile{
  position: absolute;
  top: 64px;
  width: 100%;
  background-color: var(--color-neutral-100);
  overflow: auto;
}

#nav-collapse-profile {
  position: absolute;
  top: 68px;
  right: 5px;
  width: 285px;
  background-color: var(--color-neutral-100);
  display: flex;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid var(--color-neutral-300);
  box-sizing: border-box;
  border-radius: 10px;
  z-index: 2;
  max-height: 90vh;
  overflow-y: auto;
}

.notification-counter{
  position: absolute;
  top: 0px;
  right: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  background: #FEC203;
  color: #000000;
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

#nav-collapse-notification{
  position: absolute;
  top: 68px;
  right: 10px;
  width: 382px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid var(--color-neutral-300);
  background-color: var(--color-neutral-100);
  box-sizing: border-box;
  border-radius: 10px;
  z-index: 3;
}

.notification-tab{
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-bottom: 2px solid var(--color-neutral-500);
}

.notif-tab-child{
  width: 50%;
  text-align: center;
  padding: 15px 0;
  color: #8C8C8C;
  cursor: pointer;
}

.notif-tab-child-active{
  width: 50%;
  text-align: center;
  padding: 15px 0;
  padding-bottom: 11px;
  border-bottom: 4px solid var(--color-persian-blue-500);
  color: var(--color-persian-blue-500);
  cursor: pointer;
}

.notification-empty-state{
  text-align: center;
  padding-top: 145px;
  padding-bottom: 155px;
}

.notification-empty-state-wording .wording-title{
  margin-top: 32px;
  text-align: center;
}

.notification-empty-state-wording .wording-body{
  margin-top: 8px;
  text-align: center;
}

.notification-loading{
  text-align: center;
  margin-top: 30px;
  margin-bottom: 303px;
}

.notification-list-container{
  height: 303px;
  overflow-y: scroll;
}

.notification-list-more{
  padding: 0px 16px 10px 16px;
  text-align: right;
  color: var(--color-persian-blue-500);
}

.expand-notification-btn {
  display: flex;
  height: 40px;
  justify-content: end;
  align-items: center;
}

.inside-profile {
  align-items: center;
  justify-content: center;
  padding: 24px 16px 8px 16px;
  width: 100%;
}

.image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
  max-width: 176px;
}

.image-wrapper img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 100%;
}

.user-info {
  margin-top: 5px;
  word-break: break-word;
  text-align: center;
  /* margin-left: 14px */
}

.inside-name {
  color: #414141;
  font-weight: bold;
  font-size: smaller;
}

.inside-profession {
  color: #d5d5d5;
  font-weight: 600;
  font-size: 12px;
}

.inside-profile .nav-menus {
  margin: 10px 0px;
}

.inside-collapse {
  align-items: center;
  justify-content: center;
  padding-bottom: 25px;
}

.inside-collapse .nav-menus {
  margin: 25px 0px;
}

.inside-collapse img {
  width: 80px;
  border-radius: 100%;
  margin-top: 25px;
}

.button-masuk-new {
  width: 164px;
  padding: 7px 31px;
  background: var(--color-persian-blue-500);
  color: var(--color-neutral-100);
  font-weight: 600 !important;
  border-radius: 50px;
  transition: 0.3s;
  cursor: pointer;
  white-space: nowrap;
  border: 1px solid var(--color-persian-blue-500);
}

.button-masuk-new:hover {
  color: var(--color-persian-blue-500);
  background: var(--color-neutral-100);
}

.product-dropdown{
  width: fit-content;
  min-width: 135px;
  padding: 8px 16px;
  margin: 0 16px;
  color: var(--color-persian-blue-500);
  background: var(--color-neutral-100);
  border: 1px solid var(--color-persian-blue-500);
  border-radius: 12px;
  cursor: pointer;
}

.min-width-0 {
  min-width: unset;
}

.business-unit-dropdown-new{
  width: 99px;
  font-weight: 600 !important;
  margin-right: 28px;
  cursor: pointer;
  color: var(--color-neutral-800);
}

.business-unit-dropdown-new-active{
  width: 99px;
  font-weight: 600 !important;
  margin-right: 28px;
  cursor: pointer;
  color: var(--color-persian-blue-500);
}

.chevron-down {
  box-sizing: border-box;
  position: relative;
  transform: scale(1);
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
  margin-left: 8px;
}
.chevron-down::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 4px;
  top: 2px
}

.chevron-up {
  box-sizing: border-box;
  position: relative;
  transform: scale(1);
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
  margin-left: 8px;
}
.chevron-up::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(225deg);
  left: 4px;
  top: 7px
}

.nav-burger {
  height: 100%;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.nav-burger img {
  width: 16px;
  height: 16px;
  transition: 0.3s;
}

.left-nav {
  display: flex;
  gap: 28px;
  align-items: center;
  width: fit-content;
}

.mobile-right-nav-wrapper {
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: flex-end;
  width: fit-content;
}

.right-nav-wrapper,
.desktop-right-nav-wrapper {
  display: flex;
  align-items: center;
}

#nav-menu {
  margin-left: 28px;
  margin-right: 28px;
  width: 100%;
}

.nav-menus {
  margin-left: 20px;
  margin-right: 20px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown-wrapper {
  position: relative;
  margin-right: 10px;
}

.dropdown-custom {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid var(--color-neutral-500);
  position: absolute;
  top: 6px;
  right: -15px;
}

#dropdown-tentang-kami {
  padding: 0px 20px;
  width: 100%;
}

#dropdown-kariermu {
  padding: 0px 20px;
  padding-bottom: 10px;
  width: 200px;
}

.menu-dropdown {
  color: rgba(0, 0, 0, 0.6);
  margin: 6px 0px;
  cursor: pointer;
  font-size: 14px;
}

.menu-dropdown:hover {
  text-decoration: underline;
}

.nav-ikutprogram {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  margin-right: 20px;
}

.nav-programmu {
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  font-size: 14px;
}

.icon-navbar {
  margin-right: 10px;
  cursor: pointer;
}

.notification-icon{
  position: relative;
}

.image-user {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  border: 2px solid var(--color-persian-blue-500);
}

#button-masuk {
  border: 1px solid var(--color-persian-blue-900);
  box-sizing: border-box;
  border-radius: 20px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 83px;
  height: 30px;
  cursor: pointer;
  transition: 0.3s;
}

#button-masuk:hover {
  background-color: var(--color-persian-blue-900);
}

#button-masuk:hover .text-inside-button {
  color: var(--color-neutral-100);
}

.text-inside-button {
  font-weight: 500;
  font-size: 14px;
  color: var(--color-persian-blue-900);
  cursor: pointer;
  transition: 0.3s;
}

#button-daftar {
  border-radius: 20px;
  background: var(--color-deep-lemon-500);
  margin-left: 5px;
  border: 1px solid var(--color-deep-lemon-500);
  box-sizing: border-box;
  width: 83px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
}
#button-daftar:hover {
  border: 1px solid var(--color-deep-lemon-500);
  box-sizing: border-box;
  background-color: var(--color-neutral-100);
}

#button-daftar:hover .text-inside-button {
  color: var(--color-deep-lemon-500);
}

#body-login {
  padding: 0px 13px;
}

.modal-body {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.icon-close {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.icon-close img {
  cursor: pointer;
}

.modal-title {
  text-align: center;
  color: var(--color-ui-text-main-title);
  margin-top: 10px;
  margin-bottom: 8px;
}

.modal-description {
  color: var(--color-ui-text-primary);
  margin-top: 8px;
  margin-bottom: 5px;
  text-align: center;
}

.error-message {
  color: #eb5757;
  margin-top: 6px;
  font-size: 14px;
}

.modal-description span {
  font-weight: 600;
  cursor: pointer;
}

.input-email {
  border: 1px solid var(--color-neutral-500);
  border-radius: 10px;
  margin-top: 20px;
}

.input-masuk {
  margin-top: 10px;
  border: 1px solid var(--color-neutral-500);
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  padding: 19px 15px;
  color: var(--color-ui-text-primary);
}

.input-masuk::placeholder {
  color: var(--color-ui-text-placeholder);
}

.error-input {
  border: 1px solid #eb5757;
}

.password-wrapper {
  position: relative;
}

.icon-wrapper {
  position: absolute;
  color: var(--color-neutral-500);
  top: 14px;
  right: 15px;
  font-size: 22px;
  cursor: pointer;
}

.input-send-password {
  margin-top: 13px;
  margin-bottom: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input-checkbox {
  display: flex;
  align-items: center;
  border-radius: 0;
}

.checkbox-rule {
  margin-right: 10px;
}

.rule-text {
  color: #bdbdbd;
}

.new-password {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 700 !important;
  cursor: pointer;
}

.new-password:hover {
  text-decoration: underline;
}

.button-sign {
  background-color: var(--color-persian-blue-500);
  border: 0;
  padding: 8px 0;
  font-weight: 600;
  font-size: 16px;
  color: var(--color-neutral-100);
  border-radius: 10px;
}

.button-sign:hover {
  background-color: var(--color-persian-blue-400);
}

.button-sign:active {
  background-color: #236693;
}

.button-sign:disabled {
  background: var(--color-neutral-500);
  color: var(--color-ui-text-placeholder);
}

.category-section img{
  height: 12px;
  margin-left: 3px;
  transform: rotate(-90deg);
}

#atau-login {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.5);
}

#atau-login hr {
  flex-grow: 1;
}

#atau-login div {
  padding: 10px;
}

#google-oauth {
  height: 40px;
  width: 100%;
  font-size: 14px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, .54);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  border: transparent;
  background: var(--color-neutral-100);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  outline : none;
}

#google-oauth img {
  width: 18px;
  height: 18px;
  margin-right: 24px;
}

#login-otp {
  display: flex;
  align-items: center;
  width: 100%;
  color: rgba(0, 0, 0, 0.7);
  border: 1px solid var(--color-neutral-500);
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 10px;
  padding: 10px 20px;
  font-weight: 600;
}

#login-otp img {
  padding-right: 10px;
}

#login-register {
  margin: 15px 0 10px;
  color: var(--color-ui-text-primary);
  text-align: center;
  font-weight: 600 !important;
}

#login-register .login-register {
  color: var(--color-persian-blue-500);
  font-weight: 600;
  cursor: pointer;
}

#login-register .login-register:hover {
  text-decoration: underline;
}

#help-report {
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 15px;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 16px;
}

#help-report span {
  font-weight: 600;
}

.modal-footer {
  border: 0;
  display: flex;
  justify-content: center;
}

.modal-footer span {
  color: var(--color-deep-lemon-500);
  cursor: pointer;
}

.toggle-search {
  width: 24px;
  cursor: pointer;
}

.product-dropdown-mobile{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: fit-content;
  min-width: 0;
  padding: 0px 2px;
}

.active-nav {
  color: var(--color-persian-blue-500);
  font-weight: 600;
}

#logo-cikal {
  width: 70px;
  cursor: pointer;
}

#nav-collapse-category{
  position: absolute;
  top: 68px;
  width: 180px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid var(--color-neutral-300);
  background-color: var(--color-neutral-100);
  box-sizing: border-box;
  border-radius: 10px;
  z-index: 3;
}

#nav-collapse-business-unit{
  position: absolute;
  top: 66px;
  right: 226px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid var(--color-neutral-300);
  background-color: var(--color-neutral-100);
  box-sizing: border-box;
  border-radius: 10px;
  z-index: 3;
}

.nav-collapse-business-unit-login {
  right: 353px !important;
}

.category-option-container{
  padding: 15px;
}

.list-category{
  margin-bottom: 20px;
  display: block;
}

.list-category:last-child{
  margin-bottom: 0;
}

.list-category-active{
  margin-bottom: 20px;
  display: block;
}

.list-category-active:last-child{
  margin-bottom: 0;
}

.list-category h2{
  color: var(--color-persian-blue-500);
  cursor: pointer;
  font-weight: 600 !important;
  line-height: 16px !important;
}

.list-category-active h2{
  color: var(--color-persian-blue-500);
  cursor: pointer;
  font-size: 14px;
  font-weight: 600 !important;
  line-height: 16px !important;
}

.navigation-icon-list{
  margin-right: 12px;
}

.sekolahmu-logo {
  content: url('https://cdn.sekolah.mu/assets/home/sekolahmu_logo.svg');
  width: 168px;
  height: 48px;
  cursor: pointer;
}

#mitra-platinum-logo {
  max-height: 46px;
  max-width: 119px;
  object-fit: contain;
  cursor: pointer;
}

.nav-menus-new-mark{
  padding: 4px 16px;
  margin-left: 12px;
  background: var(--color-background-soft-yellow);
  border-radius: 17.5px;
  color: var(--color-deep-lemon-500);;
}

.inside-profile-header{
  padding-bottom: 16px;
  border-bottom: 1px solid var(--color-neutral-500);
}

.inside-profile-header .get-profile-name{
  font-weight: 600 !important;
  color: var(--color-ui-text-blue);
  margin-bottom: 8px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.inside-profile-header .user-year-level{
  font-weight: 600 !important;
  line-height: 16px !important;
  color: var(--color-persian-blue-500);
  background: var(--color-persian-blue-100);
  padding: 4px 12px;
  border-radius: 24px;
}
.inside-profile-nav{
  margin: 0;
  line-height: 24px !important;
  color: var(--color-persian-blue-500);
}
.profile-nav-container{
  display: flex;
  margin-bottom: 8px;
  align-items: center;
}
.profile-nav-container:first-child{
  margin-top: 18px;
}
.profile-nav-container:last-child{
  margin-bottom: 0;
}
.feature-icon{
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.inside-profile-nav-section{
  padding-bottom: 20px;
  border-bottom: 1px solid var(--color-neutral-500);
}
.view-profile{
  margin-bottom: 10px;
}
.user-logout-button{
  margin: 0;
  line-height: 24px !important;
  cursor: pointer;
  color: var(--color-persian-blue-500);
}

.new-inside-profile-nav{
  padding: 4px 16px;
  background: var(--color-ui-element-light-blue);
  color: var(--color-persian-blue-500);
  border-radius: 17.5px;
  margin-left: 8px;
}

.clickable{
  cursor: pointer;
}

.icon-filter-persian-blue-400 {
  filter: var(--filter-color-persian-blue-400);
}

@media screen and (min-width: 921px) {
  .nav-burger {
    display: none;
  }

  #nav-collapse {
    display: none;
  }

  .toggle-search {
    display: none;
  }

  .product-dropdown-mobile{
    display: none;
  }
}

@media screen and (max-width: 920px) {
  #mitra-platinum-logo{
    width: auto;
  }

  .right-nav-wrapper,
  .desktop-right-nav-wrapper {
    display: none;
  }

  #nav-menu {
    display: none;
  }

  .search-bar {
    display: none;
  }

  .prakerja-logo{
    margin: 0;
  }

  nav{
    border-bottom: 1px solid #EDEDED;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.navbar-avatar-wrapper {
  position: relative;
  cursor: pointer;
}

.navbar-mobile-notification {
  width: 8px;
  height: 8px;
  position: absolute;
  top: -10px;
  right: -10px;
  background: var(--color-crimson-500);
  border-radius: 32px;
}

@media screen and (max-width: 500px) {
  .sekolahmu-logo {
    content: url('https://cdn.sekolah.mu/assets/mini-icons/sekolahmu.svg');
    width: fit-content;
    height: 24px;
  }

  .mobile-platinum-hide{
    display: none;
  }

  .left-nav {
    gap: 16px;
  }

  .product-dropdown {
    padding: 0 8px;
  }
}
@media screen and (max-width: 450px) {
  #separation-prakerja{
    display: none;
  }
}
@media screen and (max-width: 420px) {
  .product-dropdown-mobile{
    display: flex;
  }
}
@media screen and (max-width: 375px) {
  #logo-cikal {
    width: 50px;
  }
}

@media screen and (max-width: 325px) {
  .toggle-search {
    width: 20px;
  }

  #bag-mobile {
    width: 20px;
  }

  .nav-burger {
    width: 10px;
  }
}
</style>
