const routes = [
  {
    path: '/prakerja-gojek',
    name: 'PrakerjaGojek',
    meta: {
      title: 'Prakerja Gojek'
    },
    component: () => import(/* webpackChunkName: "prakerjaGojek" */ '../views/prakerja/PrakerjaGojek.vue')
  },
  {
    path: '/program-gojek',
    name: 'ProgramGojek',
    meta: {
      title: 'Program Gojek'
    },
    component: () => import(/* webpackChunkName: "programGojek" */ '../views/prakerja/ProgramGojek.vue')
  },
  {
    path: '/kartuprakerja',
    name: 'Prakerja',
    meta: {
      title: '3 Langkah dapat Kartu Prakerja | Sekolah.mu',
      metaTags: [
        {
          name: 'description',
          content: 'Pelatihan khusus Kartu Prakerja, langsung diaplikasikan sesuai keahlian dan minatmu. Program kelas online bersertifikat. Cepat untuk siap kerja lagi.'
        }
      ]
    },
    component: () => import(/* webpackChunkName: "prakerja" */ '../views/prakerja/PrakerjaBase.vue')
    // beforeEnter (to, from, next) {
    //   const hostname = location.hostname
    //   if (hostname === 'www.sekolah.mu') {
    //     location.replace('https://prakerja.sekolah.mu/kartuprakerja')
    //   } else if (hostname === 'staging-belajar.sekolah.mu') {
    //     location.replace('https://staging-prakerja.sekolah.mu/kartuprakerja')
    //   } else {
    //     next()
    //   }
    // }
  },
  {
    path: '/kartuprakerja/usaha-rumahan',
    name: 'Prakerja Usaha Rumahan',
    meta: {
      title: '3 Langkah dapat Kartu Prakerja untuk Usaha Rumahan',
      metaTags: [
        {
          name: 'description',
          content: 'Pelatihan khusus Kartu Prakerja, Disesuaikan untuk bisa langsung diaplikasikan di profesimu. Program kelas online bersertifikat diajarkan oleh tenaga & lembaga ahli terpercaya.'
        }
      ]
    },
    component: () => import(/* webpackChunkName: "prakerjaUsahaRumahan" */ '../views/prakerja/PrakerjaUsahaRumahan.vue')
  },
  {
    path: '/kartuprakerja/guru',
    name: 'Prakerja Guru',
    meta: {
      title: '3 Langkah dapat Kartu Prakerja untuk Meningkatkan Penghasilanmu',
      metaTags: [
        {
          name: 'description',
          content: 'Pelatihan khusus Kartu Prakerja, Disesuaikan dengan profesimu untuk bisa meningkatkan penghasilan. Program kelas online bersertifikat diajarkan oleh tenaga & lembaga ahli terpercaya."'
        }
      ]
    },
    component: () => import(/* webpackChunkName: "prakerjaGuru" */ '../views/prakerja/PrakerjaGuru.vue')
  },
  {
    path: '/kartuprakerja/ojol',
    name: 'Prakerja Ojol',
    meta: {
      title: '3 Langkah dapat Kartu Prakerja bagi Tekerja Transportasi Online',
      metaTags: [
        {
          name: 'description',
          content: 'Pelatihan khusus Kartu Prakerja dari Sekolah.mu, Sesuai untuk pekerja ojol & transportasi online. Cepat untuk siap kerja lagi.'
        }
      ]
    },
    component: () => import(/* webpackChunkName: "prakerjaOjol" */ '../views/prakerja/PrakerjaOjol.vue')
  },
  {
    path: '/kartuprakerja/pembayaran/:id',
    name: 'PaymentPrakerja',
    meta: {
      title: 'Pembayaran Prakerja'
    },
    component: () => import(/* webpackChunkName: 'payment-prakerja' */ '../views/prakerja/Payment.vue')
  }
]

export default routes
