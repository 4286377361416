import axiosServerKariermu from '../../configs/axiosServerKariermu'
import AuthAPI from '../../api/auth'
import router from '@/router/index'

import deviceDetector from 'mobile-device-detect'
const isMobile = deviceDetector.isMobile
const browserName = deviceDetector.browserName
const OSName = `${deviceDetector.browserName} ${deviceDetector.browserVersion}`

/* global $cookies */
const user = {
  namespaced: true,
  state: {
    showEditDataMobile: false,
    showConfirmationModal: false,
    showCropper: false,
    showChangePasswordModal: false,
    userInfo: {},
    isLogin: false,
    isLoginFromProgramDetail: false,
    userValidation: {},
    myProfile: {},
    currentUser: {},
    isCikal: false,
    resendEmailCounter: 0,
    relations: [],
    // otp verification
    canSendOTP: true,
    isOpenVerificationInvitation: false,
    isOpenVerificationOtp: false,
    isHideSkipVerification: false,
    isVerificationMandatory: false,
    isTemporarilyFreezeOtpVerification: false,
    isShowUnfreezeVerificationSnackbar: false,
    unfreezeVerificationSnackbarTextType: 'active',
    isToggleFloatBar: true,
    hasActivity: false
  },
  mutations: {
    SET_USER (state, payload) {
      state.userInfo = payload
    },
    SET_RELATION (state, payload) {
      state.relations = payload
    },
    SET_AVATAR (state, avatar) {
      state.userInfo = { ...state.userInfo, avatar }
    },
    SET_PRAKERJA_ID (state, prakerja_id) {
      state.userInfo = { ...state.userInfo, prakerja_id }
    },
    SET_SHOW_MODAL (state, payload) {
      if (payload) {
        state.showChangePasswordModal = payload
      } else {
        state.showChangePasswordModal = !state.showChangePasswordModal
      }
    },
    SET_SHOW_CROPPER (state) {
      state.showCropper = !state.showCropper
    },
    SET_SHOW_CONFIRMATION (state) {
      state.showConfirmationModal = !state.showConfirmationModal
    },
    SET_SHOW_EDIT_DATA (state) {
      state.showEditDataMobile = !state.showEditDataMobile
    },
    CHANGE_IS_LOGIN (state, payload) {
      state.isLogin = payload
      if (!payload) {
        localStorage.removeItem('OTP-timestamp')
      }
    },
    CHANGE_IS_LOGIN_FROM_PROGRAM_DETAIL (state, payload) {
      state.isLoginFromProgramDetail = payload
    },
    CHANGE_CURRENT_USER (state, payload) {
      state.userValidation = payload
    },
    CHANGE_MY_PROFILE (state, payload) {
      state.myProfile = payload
    },
    CHANGE_USER (state, payload) {
      state.currentUser = payload
    },
    SET_IS_CIKAL (state, payload) {
      // state.isCikal = payload
      state.isCikal = false
    },
    SET_RESEND_COUNTER (state, payload) {
      state.resendEmailCounter = payload
    },
    CHANGE_CAN_SEND_OTP (state, payload) {
      state.canSendOTP = payload
    },
    CHANGE_IS_OPEN_VERIFICATION_INVITATION (state, payload) {
      state.isOpenVerificationInvitation = payload
    },
    CHANGE_IS_OPEN_VERIFICATION_OTP (state, payload) {
      state.isOpenVerificationOtp = payload
    },
    CHANGE_IS_HIDE_SKIP_VERIFICATION (state, payload) {
      state.isHideSkipVerification = payload
    },
    CHANGE_IS_VERIFICATION_MANDATORY (state, payload) {
      state.isVerificationMandatory = payload
    },
    CHANGE_IS_TEMPORARILY_FREEZE_OTP_VERIFICATION (state, payload) {
      state.isTemporarilyFreezeOtpVerification = payload
    },
    CHANGE_IS_SHOW_UNFREEZE_SNACKBAR (state, payload) {
      state.isShowUnfreezeVerificationSnackbar = payload
    },
    CHANGE_UNFREEZE_VERIFICATION_SNACKBAR_TEXT_TYPE (state, payload) {
      state.unfreezeVerificationSnackbarTextType = payload
    },
    SET_TOGGLE_FLOAT_BAR (state, payload) {
      state.isToggleFloatBar = payload
    },
    SET_HAS_ACTIVITY (state, payload) {
      state.hasActivity = payload
    }
  },
  actions: {
    async getPrakerjaProfile ({ commit, rootState }) {
      try {
        const result = await AuthAPI.getUserProfile(rootState.storage.accessToken)
        if (result.status === 200 || result.message === 'OK') {
          commit('CHANGE_CURRENT_USER', result.data)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getUserProfile ({ commit, rootState }, payload) {
      try {
        const { data } = await axiosServerKariermu({
          url: `/user/view/get_bulk/${payload}`,
          method: 'GET',
          headers: {
            Authorization: rootState.storage.accessToken
          }
        })
        if (data.status === 200 || data.message === 'OK') {
          commit('CHANGE_CURRENT_USER', data.data[0])
        }
      } catch (error) {
        console.error(error)
      }
    },
    async fetchUserData ({ commit, rootState }, payload) {
      try {
        let newPayload = payload || {}
        const token = newPayload.token || rootState.storage.accessToken

        if (!token) {
          // prevent fetch when token is undefined
          commit('SET_IS_CIKAL', false)
          return {
            status: 418,
            message: 'Token required'
          }
        }

        const result = await AuthAPI.getUserProfile(token, newPayload.isRefresh)

        if (result.status === 200) {
          commit('SET_USER', result.data)
          if (newPayload.token) {
            commit(
              'storage/SET_ACCESS_TOKEN',
              newPayload.token,
              { root: true }
            )
          }
          commit(
            'storage/SET_USER_ID',
            result.data.id,
            { root: true }
          )
          switch (rootState.activeEnv) {
            case 'staging':
              commit('SET_IS_CIKAL', !!result.data.user_group.filter(group => group.group_id === 231).length)
              break
            case 'production':
              commit('SET_IS_CIKAL', !!result.data.user_group.filter(group => group.group_id === 162).length)
              break
            default:
              commit('SET_IS_CIKAL', !!result.data.user_group.filter(group => group.group_id === 230).length)
              break
          }
          if (!result.data.year_level_id) {
            const redirectionGoogle = `/register-google${newPayload.isFromBayar ? '?from=bayar' : ''}`
            router.push(redirectionGoogle).catch(() => {})
          }
        } else {
          commit('SET_IS_CIKAL', false)
        }
        return result
      } catch (err) {
        console.error(err)
        commit('SET_IS_CIKAL', false)
        return { status: 400 }
      }
    },
    async fetchRelationData ({ commit, rootState }) {
      try {
        const { data } = await axiosServerKariermu.get('/user/relation_user/get/request/', {
          headers: {
            Authorization: rootState.storage.accessToken
          }
        })
        if (data.status === 200) {
          commit('SET_RELATION', data.data)
        } else {
          commit('SET_RELATION', [])
        }
      } catch (err) {
        commit('SET_RELATION', [])
      }
    },
    async saveEditedProfile ({ dispatch, rootState }, payload) {
      if (payload.birth_date === '') {
        payload.birth_date = null
      }
      try {
        const { data } = await axiosServerKariermu.post('/user/manage/', payload, {
          headers: {
            Authorization: rootState.storage.accessToken
          }
        })
        if (data.status === 200) {
          dispatch('fetchUserData')
          const alertMessage = payload.hasOwnProperty('username') ? 'Data utama berhasil diubah' : 'Data tambahan berhasil diubah'
          return {
            variant: 'success',
            alertMessage: alertMessage
          }
        } else {
          return {
            variant: 'danger',
            alertMessage: data.message || 'Terjadi kesalahan pada server, data tidak disimpan'
          }
        }
      } catch (error) {
        return {
          variant: 'danger',
          alertMessage: 'Terjadi kesalahan pada server, data tidak disimpan'
        }
      }
    },
    async forgotPassword ({ commit }, payload) {
      try {
        const { data } = await axiosServerKariermu({
          method: 'POST',
          url: '/user/recover_password/',
          headers: {
            Authorization: process.env.VUE_APP_APP_KEY
          },
          data: {
            email: payload
          }
        })
        return data
      } catch (error) {
        console.error(error)
      }
    },
    async saveEditedAvatar ({ rootState }, payload) {
      try {
        await axiosServerKariermu.post('/user/manage/', payload, {
          headers: {
            Authorization: rootState.storage.accessToken
          }
        })
      } catch (error) {
        console.error(error)
      }
    },
    async getMyProfile ({ commit, rootState }) {
      try {
        const result = await AuthAPI.getUserProfile(rootState.storage.accessToken)

        if (result.status === 200 || result.message === 'OK') {
          commit('CHANGE_MY_PROFILE', result.data)
          let currentUser = {}
          currentUser.name = result.data.name
          currentUser.id = result.data.id
          currentUser.year_level = result.data.year_level_name
          currentUser.avatar = result.data.avatar
          currentUser.has_catalog = result.data.has_catalog
          commit('CHANGE_USER', currentUser)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async fetchNotification ({ commit, rootState }, payload) {
      // prevent fetch when username is undefined
      if (!rootState.user.userInfo.username) {
        return
      }
      const collectionPath = `notifications/general_notifications/users/${rootState.user.userInfo.username}/notifications`
      const db = window.firestore.collection(collectionPath)
      return db
        .where('object_type', 'in', payload.types)
        .orderBy('sent_at', payload.sort)
        .limit(payload.total_item)
    },
    setToggleFloatBar ({ commit, rootState }, payload) {
      if (payload) {
        commit('SET_TOGGLE_FLOAT_BAR', payload)
      } else {
        commit('SET_TOGGLE_FLOAT_BAR', !rootState.user.isToggleFloatBar)
      }
    },
    async switchAccount ({ rootState, dispatch }, targetAccountId) {
      try {
        const authorization = rootState.storage.accessToken
        const payload = {
          target_account_id: targetAccountId,
          source: isMobile ? btoa('sekolahmu-webview') : btoa('sekolahmu-web'),
          agent: browserName,
          operation_system: OSName
        }
        const { data } = await AuthAPI.switchAccount({
          authorization,
          payload
        })
        if (data.status === 200) {
          dispatch('storage/loginSetStorage', data, { root: true })
          await dispatch('user/fetchUserData', { isRefresh: true }, { root: true })
          dispatch('notification/removeAllSubscription', undefined, { root: true })
          dispatch('notification/getNotificationCounter', undefined, { root: true })
          $cookies.remove('parentGuideLastActivity')
        }
      } catch (error) {}
    }
  }
}

export default user
