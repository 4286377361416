<template>
  <div id="navbar-profile-collapse-mobile">
    <!-- Account -->
    <div class="account-info-container">
      <h5 class="font-subtitle-3 title">Hai, {{ userInfo.parent_login_id ? parentLogin.parent_name : userInfo.name }}</h5>
      <p class="font-label-3 subtitle">Kamu sedang berada di akun <span :class="userInfo.parent_login_id ? 'font-label-2' : ''">{{ userInfo.parent_login_id ? userInfo.name : 'utama' }}</span>.</p>
      <div class="account-info">
        <b-img-lazy :src="userInfo.avatar || ''" alt="Sekolah.mu" class="account-avatar"></b-img-lazy>
        <div class="account-name">
          <h5 class="font-subtitle-5 user-name">{{ userInfo.name }}</h5>
          <span class="font-label-4 user-year-level">{{ yearLevelType }}</span>
        </div>
        <h5 class="font-subtitle-5 button-profile-nav" @click="toRouting('/profile?utm_source=sekolahmu&utm_medium=navigation-bar&page=data-diri')" id="navbar-profile-route-mobile">
          Lihat Profil
        </h5>
      </div>
      <div id="account-relation-list-container" class="account-relation-list-container" v-if="isShowRelationList">
        <div id="toggle-dropdown-mobile" role="button" class="header-toggle" @click="toggleDropdownRelation">
          <h5 class="font-subtitle-sb-3 relation-header-text">Daftar Relasi</h5>
          <i :class="{ 'chevron-down': !isOpenRelationList, 'chevron-up': isOpenRelationList }"></i>
        </div>
        <b-collapse
          v-model="isOpenRelationList"
          class="relation-list-collapse"
        >
          <div class="flex-container">
            <template v-if="yearLevelType === 'Dewasa & Orangtua'">
              <div class="relation-list" v-for="(item, index) in childList" :key="index" @click="switchAccount(item.child_user_id)">
                <b-img-lazy :src="item.child_avatar" alt="sekolah.mu" class="relation-list-avatar"></b-img-lazy>
                <h5 class="font-label-4 relation-list-name">{{ item.child_name }}</h5>
              </div>
              <div class="font-subtitle-sb-4 add-child-route" id="add-child-route" @click="gotoRelationPage" v-if="childList.length < 5">
                <div class="plus-icon-container">
                  <img src="@/assets/icons/add_relation_icon.svg" alt="sekolah.mu" class="add-relation-icon">
                  <h5 class="font-label-4 add-relation-text">Tambahkan Akun Anak</h5>
                </div>
              </div>
            </template>
            <template v-else-if="yearLevelType === 'Anak/Remaja' && userInfo.parent_login_id !== 0">
              <div class="relation-list" @click="switchAccount(parentLogin.parent_user_id)">
                <b-img-lazy :src="parentLogin.parent_avatar" alt="sekolah.mu" class="relation-list-avatar"></b-img-lazy>
                <div class=green-dot></div>
                <h5 class="font-label-4 relation-list-name">{{ parentLogin.parent_name }}</h5>
              </div>
              <div class="relation-list" v-for="(item, index) in siblinglist" :key="`${index}-0`" @click="switchAccount(item.child_user_id)">
                <b-img-lazy :src="item.child_avatar" alt="sekolah.mu" class="relation-list-avatar"></b-img-lazy>
                <h5 class="font-label-4 relation-list-name">{{ item.child_name }}</h5>
              </div>
            </template>
          </div>
        </b-collapse>
      </div>
    </div>
    <!-- Feature Sekolah.mu -->
    <div class="feature-navigation-container" v-for="(container, index_i) in featureNavigation" :key="index_i">
      <template v-for="(feature, index_j) in container">
        <div class="feature-wrapper" :key="index_j" v-if="!feature.hasCondition">
          <icon-cart v-if="feature.name === 'Daftar Pilihan'" class="feature-icon" />
          <img v-else :src="feature.icon" alt="Sekolah.mu" class="feature-icon">
          <h5 class="font-body-3 feature-navigation" v-if="feature.type === 'route'" @click="toRouting(feature.path, feature.eventTracker)" :id="`navbar-${feature.name}-feature-route-mobile`">{{ feature.name }}</h5>
          <a class="font-body-3 feature-navigation" v-else-if="feature.type === 'link'" :href="feature.path" :id="`navbar-${feature.name}-feature-route-mobile`" rel="noopener" target="_blank">{{ feature.name }}</a>
          <h5 class="font-body-3 feature-navigation" v-else-if="feature.type === 'function'" @click="feature.path" :id="`navbar-${feature.name}-feature-route-mobile`">{{ feature.name }}</h5>
          <s-tag
            v-if="feature.isNew"
            variant="solid-crimson"
            type="category"
            class="ml-2"
          >
            Baru
          </s-tag>
        </div>
      </template>
    </div>
    <!-- Other Navigation -->
    <div class="other-navigation">
      <s-button class="font-body-2 helper-navigation" variant="link" @onClick="toRouting('/bantuan')" id="navbar-help-route-mobile">
        <img src="https://cdn.sekolah.mu/assets/v2/menu-icon/bantuan.svg" alt="Sekolah.mu" class="feature-icon">
        Bantuan
      </s-button>
      <s-button class="font-body-2 logout-navigation" variant="link" @onClick="logout" id="navbar-logout-button-mobile">
        <img src="https://cdn.sekolah.mu/assets/v2/menu-icon/exit.svg" alt="Sekolah.mu" class="feature-icon">
        Keluar
      </s-button>
    </div>

  </div>
</template>
<script>
import { mapState } from 'vuex'
import { isMobile } from 'mobile-device-detect'
import authAPI from '@/api/auth.js'

import SButton from '@/components/atoms/buttons/SButton.vue'

// MIXINS
import setAuthPayload from '@/mixins/setAuthPayload.js'
import smmConfig from '@/mixins/smmConfig'
import parentPageEventTrackerMixin from '@/mixins/parentPageEventTrackerMixin.js'
import firebaseLogEvent from '@/mixins/firebaseLogEvent.js'

export default {
  name: 'NavbarProfileColapseMobile',
  components: {
    SButton,
    IconCart: () => import('@/components/navbar/iconNavbar/IconCart.vue')
  },
  data () {
    return {
      featureNavigation: [],
      isOpenRelationList: false,
      isMobile,
      tagStyle: {
        width: '32px',
        height: '16px',
        padding: '0px',
        fontSize: '10px !important',
        fontWeight: '700 !important'
      }
    }
  },
  mixins: [setAuthPayload, smmConfig, parentPageEventTrackerMixin, firebaseLogEvent],
  computed: {
    ...mapState('accountMenu', ['accountMenu', 'isLoadingAccountMenu']),
    accountMenuConfig () {
      return this.accountMenu.community_page
    },
    yearLevelType () {
      if (this.userInfo.profession_name === 'Murid') {
        return 'Anak/Remaja'
      } else {
        return 'Dewasa & Orangtua'
      }
    },
    dapodikChildren () {
      return this.$store.state.parentPage.dapodikChildren
    },
    isShowRelationList () {
      if (this.yearLevelType === 'Dewasa & Orangtua') {
        return true
      } else if (this.yearLevelType === 'Anak/Remaja') {
        return this.userInfo.parent_login_id !== 0
      }
    },
    childList () {
      return this.userInfo.child || []
    },
    parentList () {
      return this.userInfo.parent || []
    },
    parentLogin () {
      return this.parentList.find(item => item.parent_user_id === this.userInfo.parent_login_id)
    },
    siblinglist () {
      return this.userInfo.sibling || []
    },
    accessToken () {
      return this.$store.state.storage.accessToken
    },
    enableFunReport () {
      return (String(process.env.VUE_APP_SET_ENABLE_FUN_REPORT) === 'true')
    },
    isShowFunReport () {
      return this.userInfo.is_have_fun_report && this.enableFunReport
    },
    envPlatinum () {
      return this.$store.state.envPlatinum
    },
    userInfo () {
      return this.$store.state.user.userInfo
    },
    paymentUrl () {
      return process.env.VUE_APP_DOMAIN_BAYAR + '/paket'
    },
    activeEnv () {
      return this.$store.state.activeEnv
    },
    isParent () {
      return this.userInfo.profession_name && this.userInfo.profession_name.toLowerCase() !== 'murid'
    },
    platinumConfig () {
      return this.$store.state.institution.platinumConfig
    },
    isSMM () {
      return this.$store.state.isSMM
    },
    userDataCompleteness () {
      return this.$store.state.parentPage.dataCompleteness
    },
    redirectData () {
      return {
        academicYear: this.userDataCompleteness.academic_year,
        childrenId: this.userDataCompleteness.children.length ? this.userDataCompleteness.children[0].child_id : this.userInfo.id
      }
    },
    academicYears () {
      return this.$store.state.parentPage.academicYears
    },
    isDev () {
      const hostName = location.hostname.trim().toLowerCase()
      return hostName.includes('.dev.sekolah.mu') || hostName.includes('localhost')
    }
  },
  methods: {
    toggleDropdownRelation () {
      this.isOpenRelationList = !this.isOpenRelationList
      if (this.isOpenRelationList) {
        this.createFirebaseEvent('lihat_daftar_relasi_click')
      }
    },
    logout () {
      this.$emit('logout')
      this.$emit('closeDropdown')
    },
    gotoRelationPage () {
      this.$emit('closeDropdown')
      this.$router.push('/profile?page=akun-relasi')
    },
    async switchAccount (targetAccountId) {
      try {
        const authorization = this.accessToken
        const payload = {
          target_account_id: targetAccountId,
          source: btoa('sekolahmu-webview'),
          agent: this.browserName,
          operation_system: this.OSName,
          longitude: this.longitude,
          latitude: this.latitude
        }
        const { data } = await authAPI.switchAccount({
          authorization,
          payload
        })
        if (data.status === 200) {
          this.createFirebaseEvent('akun_relasi_click')
          this.isOpenRelationList = false
          this.$store.dispatch('storage/loginSetStorage', data)
          await this.$store.dispatch('user/fetchUserData', { isRefresh: true })
          this.$emit('closeDropdown')
          setTimeout(() => {
            this.$router.replace('/switch-account-redirection')
          }, 500)

          this.initFeatureNavigation()
        }
      } catch (error) {}
    },
    initFeatureNavigation () {
      // careful when adding another value between existing value.
      // under featureNavigation contain precise indexing for splice
      this.featureNavigation = [
        [
          { name: 'Kelasku', path: '/programmu?utm_source=sekolahmu&utm_medium=navigation-bar', type: 'route', icon: 'https://cdn.sekolah.mu/assets/v2/menu-icon/kelasku.svg', eventTracker: 'kelasku_quick_access_click' },
          { name: 'Jadwal', path: '/jadwal?utm_source=sekolahmu&utm_medium=navigation-bar', type: 'route', icon: 'https://cdn.sekolah.mu/assets/v2/menu-icon/jadwal.svg', eventTracker: 'jadwal_quick_access_click' },
          { name: 'Notifikasi', path: '/notifikasi?utm_source=sekolahmu&utm_medium=navigation-bar', type: 'route', icon: 'https://cdn.sekolah.mu/assets/v2/menu-icon/notifikasi.svg', eventTracker: 'notifikasi_quick_access_click' }
        ],
        [
          { name: 'Daftar Pilihan', path: '/daftar-pilihan?utm_source=sekolahmu&utm_medium=navigation-bar', type: 'route', icon: 'https://cdn.sekolah.mu/assets/v2/menu-icon/daftar-pilihan.svg', eventTracker: 'daftar_pilihan_quick_access_click' },
          { name: 'Paket Kelas', path: `${this.paymentUrl}?utm_source=sekolahmu&utm_medium=navigation-bar`, type: 'link', icon: 'https://cdn.sekolah.mu/assets/v2/menu-icon/paket-kelas.svg' }
        ],
        [
          { name: 'Tanya.mu', path: '/tanyamu?utm_source=sekolahmu&utm_medium=navigation-bar', type: 'route', icon: 'https://cdn.sekolah.mu/assets/v2/menu-icon/tanyamu.svg' }
        ],
        [
          { name: 'Jurnal Refleksimu 2021', path: this.openFunReport, type: 'function', icon: 'https://cdn.sekolah.mu/avatar/112189/a1116d16902a45e0b229385ecaba95d3.png', hasCondition: !this.isShowFunReport, isNew: true },
          { name: 'Portofolio', path: '/portofolio/profil?utm_source=sekolahmu&utm_medium=navigation-bar-akun', type: 'route', icon: 'https://cdn.sekolah.mu/assets/v2/menu-icon/portfolio.svg' },
          { name: 'Rapor', path: '/rapor-murid?utm_source=sekolahmu&utm_medium=navigation-bar-akun', type: 'route', icon: 'https://cdn.sekolah.mu/assets/v2/menu-icon/transkrip.svg' }
        ],
        [
          { name: 'Tukar Voucher', path: '/tukar-voucher?utm_source=sekolahmu&utm_medium=navigation-bar', type: 'route', icon: 'https://cdn.sekolah.mu/assets/v2/menu-icon/tukar-voucher.svg' },
          { name: 'Daftar Transaksi', path: '/daftar-transaksi?utm_source=sekolahmu&utm_medium=navigation-bar', type: 'route', extensionFunc: this.paymentResetTabs, icon: 'https://cdn.sekolah.mu/assets/v2/menu-icon/daftar-transaksi.svg' },
          { name: 'Daftar Tagihan', path: '/daftar-tagihan?utm_source=sekolahmu&utm_medium=navigation-bar', type: 'route', icon: 'https://cdn.sekolah.mu/assets/v2/menu-icon/daftar-tagihan.svg' }
        ],
        [
          { name: 'Pengaturan Akun', path: `/profile?utm_source=sekolahmu&utm_medium=navigation-bar`, type: 'route', icon: 'https://cdn.sekolah.mu/assets/v2/menu-icon/lihat-profil.svg' }
        ]
      ]

      setTimeout(() => {
        if (this.isSMM) {
          this.featureNavigation.splice(1, 1, [
            { name: 'Daftar Pilihan', path: '/daftar-pilihan?utm_source=sekolahmu&utm_medium=navigation-bar', type: 'route', icon: 'https://cdn.sekolah.mu/assets/v2/menu-icon/daftar-pilihan.svg' }
          ])

          this.featureNavigation.splice(4, 1, [
            { name: 'Daftar Transaksi', path: '/daftar-transaksi?utm_source=sekolahmu&utm_medium=navigation-bar', type: 'route', extensionFunc: this.paymentResetTabs, icon: 'https://cdn.sekolah.mu/assets/v2/menu-icon/daftar-transaksi.svg' },
            { name: 'Daftar Tagihan', path: '/daftar-tagihan?utm_source=sekolahmu&utm_medium=navigation-bar', type: 'route', icon: 'https://cdn.sekolah.mu/assets/v2/menu-icon/daftar-tagihan.svg' }
          ])
        }

        if (this.accountMenuConfig) {
          this.featureNavigation.splice(1, 0, [
            { name: 'Data Wajib Murid', path: this.dataWajibMuridUrl, type: 'function', icon: require('@/assets/icons/data-pendidikan.svg'), isNew: true },
            { name: 'Dokumen Panduan', path: this.toGuideDocument, type: 'function', icon: 'https://cdn.sekolah.mu/assets/icon/parent-guide/panduan-orang-tua-sidebar.svg' }
          ])
        }
      }, 500)
    },
    paymentResetTabs () {
      this.$store.commit('paymentHistory/RESET_TABS')
    },
    toParentPage () {
      this.sendClickEventTracker('halaman_orang_tua_menu_click')
      this.$router.push('/halaman-orang-tua/dokumen-panduan?utm_source=sekolahmu&utm_medium=navigation-bar')
    },
    toGuideDocument () {
      this.sendClickEventTracker('halaman_orang_tua_menu_click')
      this.$router.push('/dokumen-panduan?utm_source=sekolahmu&utm_medium=navigation-bar')
    },
    toRouting (route, eventName) {
      if (eventName) {
        this.createFirebaseEvent(eventName)
      }

      this.$router.push(route)
      this.$emit('closeDropdown')
    },
    openFunReport () {
      this.$store.commit('funReport/CHANGE_IS_OPEN_MODAL_INVITATION', true)
      window.firebaseAnalytics.logEvent('jurnal_refleksimu_click', {
        user_profession: this.userInfo.profession_name,
        user_year_level: this.userInfo.year_level_name,
        user_is_parent: !!((this.userInfo.profession_name === 'Orangtua' || this.userInfo.child.length)),
        user_id: this.userInfo.id,
        source: 'mobile-web'
      })
    },
    // navbar tour toggle function
    toggleRelationList (value) {
      this.isOpenRelationList = value
    },
    createFirebaseEvent (eventName) {
      window.firebaseAnalytics.logEvent(eventName, {
        screen_url: window.location.href,
        user_id: this.userInfo.id,
        // source: `${this.isMobile ? 'mweb' : 'web'}_${this.isSMM ? 'smm.sekolahmu' : 'sekolahmu'}`
        source: `${this.isSMM ? 'smm.' : ''}${this.isMobile ? 'sekolahmu-webview' : 'sekolahmu-web'}`
      })
    },
    dataWajibMuridUrl () {
      const academicYear = this.academicYears[0] ? this.academicYears[0].label : this.redirectData.academicYear
      const childYearLevelId = this.dapodikChildren.find(child => child.id === this.redirectData.childrenId).year_level_id
      this.logEvent('menudatawajibmurid_click', [{
        child_year_level_id: childYearLevelId,
        child_id: this.redirectData.childrenId
      }])
      if (!this.isMobile) {
        const formattedYear = this.formatAcademicYear(academicYear)
        this.$router.push(`/data-pendidikan/${formattedYear}/profil-anak/${this.redirectData.childrenId}/data-utama`)
      } else if (this.isMobile) {
        this.$router.push('/halaman-orang-tua/dokumen-panduan?show-menu=1&utm_source=sekolahmu&utm_medium=navigation-bar-akun')
      }
    },
    formatAcademicYear (year = '2024/2025') {
      return year.replace('/', '-')
    }
  },
  mounted () {
    this.initFeatureNavigation()
  }
}
</script>
<style scoped>
a{
  text-decoration: none;
}
h5, p{
  margin: 0;
}
#navbar-profile-collapse-mobile {
  max-height: calc(100vh - 50px);
  min-height: calc(100vh - 50px);
  padding: 16px 16px 32px 16px;
  overflow-y: scroll;
  background: var(--color-neutral-200);

  -ms-overflow-style: none;
  scrollbar-width: none;
}
#navbar-profile-collapse-mobile::-webkit-scrollbar {
  display: none;
}
.title{
  font-weight: 600 !important;
  color: var(--color-ui-text-primary);
}
.subtitle{
  margin-bottom: 16px;
}
.account-info{
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 16px;
}
.account-avatar{
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 8px;
}
.account-name{
  flex-grow: 2;
  margin-right: 8px;
}
.user-name{
  font-weight: 600 !important;
  line-height: 16px !important;
  color: var(--color-ui-text-primary);
  margin-bottom: 4px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.user-year-level{
  font-weight: 600 !important;
  line-height: 16px !important;
  color: var(--color-persian-blue-500);
  background: var(--color-persian-blue-100);
  padding: 4px 12px;
  border-radius: 24px;
}
.button-profile-nav{
  font-weight: 600 !important;
  line-height: 20px !important;
  align-self: baseline;
  color: var(--color-persian-blue-500);
}
.feature-navigation-container{
  padding: 18px 0;
  border-bottom: 1px solid var(--color-neutral-500);
}
.feature-wrapper{
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-left: 6px;
}
.feature-wrapper:last-child{
  margin-bottom: 0;
}
.feature-icon{
  width: 24px;
  height: 24px;
  margin-right: 24px;
}
.feature-navigation{
  line-height: 16px !important;
  color: var(--color-persian-blue-500);
}
.year-level-container{
  margin-top: 32px;
}
.year-level-navigation{
  line-height: 20px !important;
  color: var(--color-ui-text-blue);
  margin-left: 6px;
  padding: 20px 0 4px 0;
  border-bottom: 1px solid var(--color-neutral-500);
}
.other-navigation{
  margin-top: 32px;
  padding-bottom: 36px;
}
.helper-navigation{
  color: var(--color-persian-blue-500);
  margin-left: 6px;
  display: block;
  margin-bottom: 18px;
}
.logout-navigation{
  color: var(--color-persian-blue-500);
  margin-left: 6px;
}
.new-inside-profile-nav{
  padding: 4px 16px;
  background: var(--color-ui-element-light-blue);
  color: var(--color-persian-blue-500);
  border-radius: 17.5px;
  margin-left: 8px;
}
.chevron-down {
  box-sizing: border-box;
  position: relative;
  transform: scale(var(--ggs,1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
  margin-left: 8px;
}
.chevron-down::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 4px;
  top: 2px
}
.chevron-up {
  box-sizing: border-box;
  position: relative;
  transform: scale(var(--ggs,1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
  margin-left: 8px;
}
.chevron-up::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(225deg);
  left: 4px;
  top: 7px
}
.header-toggle{
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-persian-blue-700);
  margin-bottom: 12px;
}
.account-relation-list-container{
  margin-bottom: 20px;
}
.flex-container{
  display: flex;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.flex-container::-webkit-scrollbar {
  display: none;
}
.relation-list{
  width: 56px;
  min-width: 56px;
  margin-right: 8px;
  text-align: center;
}
.relation-list:last-child{
  margin-right: 0;
}
.relation-list-avatar{
  width: 42px;
  height: 42px;
  border-radius: 50%;
}
.relation-list-name{
  word-break: break-word;
}
.add-relation-icon{
  margin-top: 4px;
}
.plus-icon-container{
  width: 75px;
  text-align: center;
}
.add-relation-text{
  margin-top: 7px;
  color: var(--color-persian-blue-500);
}
.green-dot {
  position: relative;
  bottom: 12px;
  left: 38px;
  border-radius: 100%;
  border: 2px solid var(--color-neutral-100);
  background-color: var(--color-eucalyptus-600);
  width: 12px;
  height: 12px;
}
</style>
