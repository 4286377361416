import axiosServerKariermu from '@/configs/axiosServerKariermu'

const baseUrl = '/user/coachmark'

async function apiCall ({ accessToken, url, payload, method }) {
  try {
    const result = await axiosServerKariermu({
      version: 'v2',
      method,
      url,
      headers: {
        Authorization: accessToken
      },
      data: payload
    })
    if (result.status === 200) {
      return result.data
    } else {
      throw new Error(result)
    }
  } catch (error) {
    return { status: error.status, message: error.message }
  }
}

export default {
  async getCoachmarkConfig ({ accessToken }) {
    const url = baseUrl + '?source=sekolahmu&device=web'
    const method = 'GET'
    const result = await apiCall({ accessToken, url, method })
    return result
  },
  async updateCoachmarkConfig ({ accessToken, payload }) {
    const url = baseUrl
    const method = 'POST'
    const result = await apiCall({ accessToken, url, method, payload })
    return result
  }
}
