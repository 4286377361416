import axiosServer from '../../configs/axiosServer'
import axiosServerKariermu from '../../configs/axiosServerKariermu'
import deviceDetector from 'mobile-device-detect'

const institution = {
  namespaced: true,
  state: {
    nextUrl: '',
    platinumInstitutionInfo: {},
    platinumConfig: {}
  },
  mutations: {
    CHANGE_NEXT_URL (state, payload) {
      state.nextUrl = payload
    },
    SET_PLATINUM_INSTITUTION_INFO (state, payload) {
      state.platinumInstitutionInfo = payload
    },
    SET_PLATINUM_CONFIG_JSON (state, payload) {
      state.platinumConfig = payload
    },
    INIT_PLATINUM_CONFIG_JSON (state) {
      state.platinumConfig = require('@/configs/platinum/default.json')
    }
  },
  actions: {
    async getInstitutionPlatinumBySubdomain ({ rootState, commit, dispatch, state }, payload) {
      try {
        const { data } = await axiosServerKariermu({
          url: `/user/institution/get-by-domain/${rootState.platinumSubdomain}`,
          method: 'GET',
          headers: {
            Authorization: process.env.VUE_APP_APP_KEY
          }
        })
        if (data.status === 200) {
          commit('SET_PLATINUM_INSTITUTION_INFO', data.data)
          commit('SET_DOMAIN_STATE', 'ready', { root: true })
          dispatch('fetchConfigJSON', data.data.config_json)
        } else {
          throw new Error(data)
        }
      } catch (error) {
        window.open('https://www.sekolah.mu', '_self')
        console.error(error)
      }
    },
    async fetchConfigJSON ({ commit }, payload = '') {
      try {
        const configType = payload.includes('smm') ? 'smm' : 'default'
        const data = await require(`../../configs/platinum/${configType}.json`)
        commit('SET_PLATINUM_CONFIG_JSON', data)
      } catch (err) {
        console.error(err)
      }
    },
    async fetchInstitutionProgram ({ rootState, dispatch }, payload) {
      let slug = payload.slug
      let page = payload.page ? payload.page : 1
      let perPage = payload.perPage ? payload.perPage : 30
      let year = payload.year ? payload.year : ''
      let subject = payload.subject ? encodeURIComponent(payload.subject) : ''
      let search = payload.search ? payload.search : ''
      let requestUrl = ''
      let searchAnalytic = `url=${window.location.href}&source=${deviceDetector.isMobile ? 'web-mobile' : 'web'}`
      if (year && search && subject) {
        requestUrl = `/program/institution/${slug}/${page}/${perPage}?year=${year}&search=${search}&subject=${subject}&${searchAnalytic}`
      } else if (year && search && !subject) {
        requestUrl = `/program/institution/${slug}/${page}/${perPage}?year=${year}&search=${search}&${searchAnalytic}`
      } else if (year && !search && subject) {
        requestUrl = `/program/institution/${slug}/${page}/${perPage}?year=${year}&subject=${subject}&${searchAnalytic}`
      } else if (!year && search && subject) {
        requestUrl = `/program/institution/${slug}/${page}/${perPage}?search=${search}&subject=${subject}&${searchAnalytic}`
      } else if (year && !search && !subject) {
        requestUrl = `/program/institution/${slug}/${page}/${perPage}?year=${year}&${searchAnalytic}`
      } else if (!year && search && !subject) {
        requestUrl = `/program/institution/${slug}/${page}/${perPage}?search=${search}&${searchAnalytic}`
      } else if (!year && !search && subject) {
        requestUrl = `/program/institution/${slug}/${page}/${perPage}?subject=${subject}&${searchAnalytic}`
      } else {
        requestUrl = `/program/institution/${slug}/${page}/${perPage}?${searchAnalytic}`
      }
      try {
        const { data } = await axiosServer({
          url: requestUrl,
          method: 'GET',
          headers: {
            Authorization: rootState.storage.accessToken ? rootState.storage.accessToken : process.env.VUE_APP_APP_KEY
          }
        })
        if (data.status === 200) {
          return data
        } else {
          dispatch('storage/checkToken', data, { root: true })
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchInstitutionProfile ({ rootState, dispatch }, payload) {
      try {
        let slug = payload.slug
        const { data } = await axiosServerKariermu({
          url: `/user/institution/get/${slug}`,
          method: 'GET',
          headers: {
            Authorization: rootState.storage.accessToken ? rootState.storage.accessToken : process.env.VUE_APP_APP_KEY
          }
        })
        if (data.status === 200) {
          return data
        } else {
          dispatch('storage/checkToken', data, { root: true })
        }
      } catch (error) {
        console.log(error)
      }
    },
    async loadMoreProgram ({ rootState, state, dispatch }) {
      try {
        const { data } = await axiosServer({
          url: state.nextUrl,
          method: 'GET',
          headers: {
            Authorization: rootState.storage.accessToken ? rootState.storage.accessToken : process.env.VUE_APP_APP_KEY
          }
        })
        if (data.status === 200) {
          return data
        } else {
          dispatch('storage/checkToken', data, { root: true })
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchYearLevel ({ rootState, dispatch }) {
      try {
        const { data } = await axiosServerKariermu({
          url: '/user/year/list',
          method: 'GET',
          headers: {
            Authorization: rootState.storage.accessToken ? rootState.storage.accessToken : process.env.VUE_APP_APP_KEY
          }
        })
        if (data.status === 200) {
          return data
        } else {
          dispatch('storage/checkToken', data, { root: true })
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchSubjectList ({ rootState }) {
      try {
        const { data } = await axiosServer({
          url: '/program/subject/list/',
          method: 'GET',
          headers: {
            Authorization: rootState.storage.accessToken ? rootState.storage.accessToken : process.env.VUE_APP_APP_KEY
          }
        })
        return data
      } catch (error) {
        console.log(error)
      }
    }
  }
}

export default institution
