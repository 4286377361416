<template>
  <div
    id="activity-navbar-wrapper-v2"
    class="activity-v2-navbar position-fixed ff-primary"
  >
    <b-navbar id="activity-navbar-v2" class="activity-v2-navbar-inner" toggleable="lg">
      <b-navbar-brand
        id="navbar-back"
        :to="backProgrammuLink"
        class="back-btn"
        @click="logEventAnalytic('HALAMAN_BELAJAR_BACK_BUTTON_CLICK')"
      >
        <img height="24" width="24" src="https://cdn.sekolah.mu/assets/icon-mini/halaman-belajar/icon-back-kelasku.svg" />
      </b-navbar-brand>
      <div class="nav-mobile">
        <router-link
          id="activity-name-desktop"
          :to="`/program/${activityDashboard.slug}`"
          class="inter-medium_small-bold activity-name text-decoration-none one-line-ellipsis"
          @click="logEventAnalytic('HALAMAN_BELAJAR_JUDUL_PROGRAM_CLICK')"
        >
          {{ activityDashboard.name }}
        </router-link>
        <div class="menu d-flex">
          <div id="mobile-current-progress" class="d-flex align-items-center">
            <vue-circle
              ref="vueCircleMobile"
              :progress="activityDashboard.percentage_progress"
              :size="24"
              :reverse="false"
              line-cap="round"
              :fill="fill"
              empty-fill="rgba(0, 0, 0, 0.2)"
              :animation-start-value="0.0"
              :start-angle="11"
              insert-mode="append"
              :thickness="4"
              :show-percent="false"
            >
            </vue-circle>
            <span class="d-block inter-medium_small-bold ml-2">
              {{ activityDashboard.percentage_progress.toFixed(2) }}%
            </span>
          </div>
          <others-bottomsheet-menu
            is-mobile
            :facil-room-id="facilRoomId"
            :is-tanyamu-closed="isTanyamuClosed"
            :is-tanyamu="isShowTanyamu"
            @showShareModal="showShareModal"
            @toggleChatBox="toggleChatBox"
            @logEventAnalytic="logEventAnalytic"
          />
        </div>
      </div>

      <b-collapse is-nav>
        <b-navbar-nav class="left-nav">
          <b-nav-item
            to="/"
            data-title="Kembali ke Halaman Utama"
            @click="logEventAnalytic('HALAMAN_BELAJAR_LOGO_CLICK')"
          >
            <img src="https://cdn.sekolah.mu/assets/home/sekolahmu_logo.svg" />
          </b-nav-item>
          <div class="line-separator"></div>
          <b-nav-item
            id="activity-name-desktop"
            :to="`/program/${activityDashboard.slug}`"
            @click="logEventAnalytic('HALAMAN_BELAJAR_JUDUL_PROGRAM_CLICK')"
            class="inter-medium-bold activity-name"
            :class="{'activity-name-width': !isSidebarVisible, 'activity-name-width-sidebar': isSidebarVisible}"
            :data-title="activityDashboard.name"
          >
            {{ activityDashboard.name }}
          </b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto right-items">
          <div id="current-progress" class="d-flex align-items-center">
            <vue-circle
              ref="vueCircle"
              :progress="activityDashboard.percentage_progress"
              :size="40"
              :reverse="false"
              line-cap="round"
              :fill="fill"
              empty-fill="rgba(0, 0, 0, 0.2)"
              :animation-start-value="0.0"
              :start-angle="11"
              insert-mode="append"
              :thickness="7"
              :show-percent="false"
            >
            </vue-circle>
            <span class="d-block inter-medium-bold ml-2">
              {{ activityDashboard.percentage_progress.toFixed(2) }}%
            </span>
          </div>
          <b-tooltip
            container="activity-navbar-wrapper-v2"
            id="navbar-progress-tooltip"
            target="current-progress"
            custom-class="navbar-progress-tooltip"
            triggers="hover"
            placement="bottomleft"
            :offset="-140"
          >
            <progress-detail
              :current-progress="activityDashboard.completed_resources"
              :total-progress="activityDashboard.total_resources"
            />
          </b-tooltip>
          <b-nav-item
            data-title="Tanyamu"
            v-if="isShowTanyamu"
            @click.prevent="toggleChatBox"
          >
            <img
              class="activity-icon"
              src="https://cdn.sekolah.mu/assets/v2/menu-icon/tanyamu.svg"
            />
          </b-nav-item>
          <s-button
            v-else
            @onClick="showShareModal"
            data-title="Bagikan"
            variant="link"
          >
            <img
              class="activity-icon"
              src="https://cdn.sekolah.mu/assets/icon-mini/halaman-belajar/icon-bagikan-aktivitas.svg"
            />
          </s-button>
          <others-dropdown-menu
            :facil-room-id="facilRoomId"
            :is-tanyamu-closed="isTanyamuClosed"
            :is-tanyamu="isShowTanyamu"
            @showShareModal="showShareModal"
            @toggleChatBox="toggleChatBox"
            @logEventAnalytic="logEventAnalytic"
          />
          <s-button
            variant="link"
            id="activity-navbar-list-activity"
            class="btn-toggle-activity-v2-sidebar position-relative"
            @onClick="toggleSidebar('daftar_aktivitas_click')"
            v-show="!isSidebarVisible"
          >
            <font-awesome-icon icon="chevron-left" />
            Daftar aktivitas
          </s-button>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <share-modal />

    <rating-modal v-bind="ratingModalProps" @posted="getActivityDashboard"/>

    <b-modal
      v-model="modalNewPassword"
      hide-footer
      hide-header
      centered
      size="new-password"
    >
      <modal-new-password
        @toggleNewPassword="toggleNewPassword"
        @toggleDaftar="toggleDaftar"
        @toggleMasuk="toggleMasuk"
        @toggleConfirmationPassword="toggleConfirmationPassword"
        class="open-sans open-sans-v2"
      />
    </b-modal>

    <b-modal
      v-model="modalConfirmationPassword"
      hide-footer
      hide-header
      centered
      size="new-password"
    >
      <modal-confirmation-password
        @toggleNewPassword="toggleNewPassword"
        @toggleDaftar="toggleDaftar"
        @toggleMasuk="toggleMasuk"
        @toggleConfirmationPassword="toggleConfirmationPassword"
        class="open-sans open-sans-v2"
      />
    </b-modal>

    <b-modal
      v-model="modalConfirmationRegister"
      hide-footer
      hide-header
      centered
      size="new-password"
    >
      <modal-confirmation-register
        @toggleNewPassword="toggleNewPassword"
        @toggleDaftar="toggleDaftar"
        @toggleMasuk="toggleMasuk"
        @toggleConfirmationPassword="toggleConfirmationPassword"
        @toggleConfirmationRegister="toggleConfirmationRegister"
        class="open-sans open-sans-v2"
      />
    </b-modal>

    <b-modal
      v-model="hasLimitedProgramAccess"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
      hide-header
      size="sm"
    >
      <modal-blocker-program-terbatas-warning-only />
    </b-modal>

    <base-modal
      v-if="!isMobile"
      :is-showing="showRatingForm"
      size="rating-form"
      :title="showRatingInformation ? 'Atur Privasi Ulasan Kelasmu' : 'Berikan Ulasan untuk Kelas Ini'"
    >
      <rating-form
        :isEnabledSendButton="isEnabledSendButton"
        :review="review"
        :showRatingInformation="showRatingInformation"
        :isLoading="isLoadingSubmitReview"
        @postReview="postReview"
        @toggleShowRatingInformation="showRatingInformation = !showRatingInformation"
      ></rating-form>
    </base-modal>

    <s-bottom-sheet
      v-if="isMobile"
      v-model="showRatingForm"
      :show-toggler="false"
    >
      <template #default>
        <rating-form
        :isEnabledSendButton="isEnabledSendButton"
        :review="review"
        :showRatingInformation="showRatingInformation"
        :isLoading="isLoadingSubmitReview"
        @postReview="postReview"
        @toggleShowRatingInformation="showRatingInformation = !showRatingInformation"
      ></rating-form>
      </template>
      <template #footer>
        <div class="bottom-sheet">
          <s-button v-if="!showRatingInformation" :isLoading="isLoadingSubmitReview" :disabled="!isEnabledSendButton" class="w-100 bottom-sheet-button" @onClick="postReview">Kirim Ulasan</s-button>
          <s-button v-if="showRatingInformation" class="w-100 bottom-sheet-button" @onClick="showRatingInformation = false">Mengerti</s-button>
        </div>
      </template>
    </s-bottom-sheet>

    <otp-verification key="otp-verification" />
  </div>
</template>

<script>
import deviceDetector from 'mobile-device-detect'
import VueCircle from 'vue2-circle-progress'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapState } from 'vuex'
import chat from '@/api/chat'
import ProgressDetail from './ProgressDetail.vue'
import OthersDropdownMenu from './OthersDropdownMenu.vue'
import SButton from '@/components/atoms/buttons/SButton.vue'
import ShareModal from './ShareModal.vue'
import RatingModal from '@/components/activityV2/ActivityHeader/RatingModal.vue'
import activityV2SidebarMixin from '@/mixins/activityV2SidebarMixin'
import activityV2GeneralMixin from '@/mixins/activityV2/general'
import ProgramActivityAPI from '@/api/program-activity'
import ModalNewPassword from '@/components/navbar/ModalNewPassword'
import ModalConfirmationPassword from '@/components/navbar/ModalConfirmationPassword'
import ModalConfirmationRegister from '@/components/navbar/ModalConfirmationRegister'
import ModalBlockerProgramTerbatasWarningOnly from '@/components/validator/ModalBlockerProgramTerbatasWarningOnly'
import OtpVerification from '@/components/otpUserVerification/OtpVerification'
import OthersBottomsheetMenu from '@/components/activityV2/ActivityHeader/OthersBottomsheetMenu.vue'

import regex from '@/constants/regex'

export default {
  name: 'ActivityHeader',
  components: {
    SButton,
    FontAwesomeIcon,
    ProgressDetail,
    OthersDropdownMenu,
    ShareModal,
    RatingModal,
    VueCircle,
    ModalNewPassword,
    ModalConfirmationPassword,
    ModalConfirmationRegister,
    ModalBlockerProgramTerbatasWarningOnly,
    OtpVerification,
    OthersBottomsheetMenu,
    BaseModal: () => import('@/components/molecules/modals/BaseModal.vue'),
    RatingForm: () => import('@//components/activityV2/ActivityHeader/RatingForm.vue')
  },
  mixins: [activityV2SidebarMixin, activityV2GeneralMixin],
  data () {
    return {
      ratingModalProps: {},
      fill: {
        color: '#2325B3'
      },
      isMobile: deviceDetector.isMobile,
      modalNewPassword: false,
      modalConfirmationPassword: false,
      modalConfirmationRegister: false,
      isFromProgramDetail: false,
      showRatingForm: false,
      showRatingInformation: false,
      review: {
        isPrivate: false,
        starTrue: 0,
        starFalse: 5,
        comment: ''
      },
      isLoadingSubmitReview: false
    }
  },
  computed: {
    ...mapState('activityV2', ['activityDashboard', 'isChatBoxOpen', 'facilRoomId', 'report']),
    isShowTanyamu () {
      const roomId = this.program.room_id > 0
      const checkRoomType = this.program.type_room === 'group' || this.program.type_room === 'channel'

      return roomId && checkRoomType && !this.isTanyamuClosed
    },
    backProgrammuLink () {
      let link = '/programmu'
      const folderId = this.activityDashboard.folder_id
      if (folderId) {
        link = `/programmu?folder=${folderId}`
      }
      return link
    },
    storage () {
      return this.$store.state.storage
    },
    dataProgramDescription: {
      get () {
        return this.$store.state.activity.dataProgramDescription
      },
      set (value) {
        this.$store.commit('activity/CHANGE_DATA_PROGRAM_DESCRIPTION', value)
      }
    },
    isOpenChildProgram () {
      return this.$store.state.program.isOpenChildProgram
    },
    isTanyamuClosed () {
      return !!this.dataProgramDescription.is_tanyamu_closed
    },
    userInfo () {
      return this.$store.state.user.userInfo
    },
    accessToken () {
      return this.$store.state.storage.accessToken
    },
    isQisqusDataAvailable () {
      return this.$store.state.tanyamu.qiscus
    },
    isLogin () {
      return this.$store.state.user.isLogin
    },
    hasLimitedProgramAccess () {
      return !this.activityValidate.is_joined && this.activityValidate.has_user_group && !this.activityValidate.enrolled
    },
    isEnabledSendButton () {
      const trimmedComment = this.review.comment.trim()
      return this.review.starTrue > 0 && trimmedComment.length >= 5 && regex.ALPHANUMERIC_QUESTION_EQUAL_DOTS.test(trimmedComment)
    }
  },
  methods: {
    showRatingModalIfEligible () {
      const today = new Date()
      const scoringDate = new Date(this.report.scoring_date)
      const { percentage_progress, is_rated, is_available_to_rate, pmo_id } = this.activityDashboard
      switch (true) {
        case today > scoringDate && is_rated === 0 && percentage_progress > 49:
        case is_available_to_rate && scoringDate && is_rated === 0 && percentage_progress > 49 && !pmo_id:
          this.ratingModalProps = { ratingType: 'time' }
          this.showRatingForm = true
          break

        case !this.isOpenChildProgram && percentage_progress >= 100 && is_rated === 0:
          this.showRatingForm = true
          break

        default:
          // not eligible to show rating modal
          break
      }
    },
    showShareModal () {
      this.$bvModal.show('share-modal')
      this.logEventAnalytic('halaman_belajar_share_click')
    },
    async getActivityDashboard () {
      const activityDashboard = await ProgramActivityAPI.getActivityDashboard({
        data: {
          user_id: this.storage.userId,
          program_user_id: this.activityValidate.program_user_id,
          id: this.activityValidate.program_id
        },
        Authorization: this.storage.accessToken
      })
      if (activityDashboard.data.status === 200) {
        this.$store.commit(
          'activityV2/SET_ACTIVITY_DASHBOARD',
          activityDashboard.data.data
        )
      }
    },
    async initProgramFacils () {
      try {
        const token = this.$store.state.storage.accessToken
        const url = `/program_activity/v2/product_by_activity/${this.$route.params.id}/description`

        const data = await chat.initProgramFacils(url, token)

        if (data.status === 200) {
          this.$store.commit('activityV2/SET_FACIL_ROOM_ID', parseInt(data.data.room_id))
          this.dataProgramDescription = data.data
        }
      } catch (err) {
        console.error(err)
      }
    },
    async toggleChatBox () {
      await this.initProgramFacils()

      if (!this.isQisqusDataAvailable) {
        const dataLogin = await this.$store.dispatch('user/fetchUserData')

        if (dataLogin.status === 200) {
          await this.$store.dispatch('tanyamu/loginQiscus')
        }
      }

      // this.switchToTab('activity')
      this.$store.commit('activityV2/SET_CHAT_BOX', !this.isChatBoxOpen)
      this.logEventAnalytic('halaman_belajar_tanyamu_click')
    },
    toggleMasuk (isClicked) {
      this.modalRegister = false
      this.modalNewPassword = false
      this.modalConfirmationPassword = false
      this.modalConfirmationRegister = false
      this.modalOtp = false
      this.isClicked = !!isClicked
    },
    toggleNewPassword () {
      this.modalRegister = false
      this.modalConfirmationPassword = false
      this.modalConfirmationRegister = false
      this.modalOtp = false

      this.modalNewPassword = !this.modalNewPassword
    },
    toggleDaftar (payload) {
      this.isSend = false
      this.modalNewPassword = false
      this.modalConfirmationPassword = false
      this.modalConfirmationRegister = false
      this.modalOtp = false

      this.modalRegister = !this.modalRegister

      if (payload?.origin === 'program_detail') {
        this.isFromProgramDetail = true
      }
    },
    toggleConfirmationPassword () {
      this.modalRegister = false
      this.modalNewPassword = false
      this.modalConfirmationRegister = false
      this.modalOtp = false

      this.modalConfirmationPassword = !this.modalConfirmationPassword
    },
    toggleConfirmationRegister () {
      this.modalRegister = false
      this.modalNewPassword = false
      this.modalConfirmationPassword = false
      this.modalOtp = false

      this.modalConfirmationRegister = !this.modalConfirmationRegister
    },
    async postReview () {
      try {
        this.isLoadingSubmitReview = true
        const { scoring_date } = this.report
        const { id, percentage_progress, is_rated, is_available_to_rate, pmo_id } = this.activityDashboard

        const data = await ProgramActivityAPI.sendReview({
          Authorization: this.accessToken,
          payload: {
            program_id: id,
            hide_info: this.review.isPrivate ? 1 : 0,
            rating: {
              value: this.review.starTrue,
              comment: this.review.comment
            },
            source: deviceDetector.isMobile ? 'web-mobile' : 'web',
            percentage_progress,
            scoring_date,
            pmo_id,
            is_available_to_rate,
            is_rated
          }
        })
        if (data.status === 200) {
          const option = {
            type: 'success',
            position: this.isMobile ? 'bottom-center' : 'top-center',
            timeout: 5000
          }

          this.$toast('Ulasan berhasil ditambahkan.', option)
          this.switchToTab('assessment')
          this.getActivityDashboard()
          this.showRatingForm = false
        } else {
          const option = {
            type: 'error',
            position: this.isMobile ? 'bottom-center' : 'top-center',
            timeout: 5000
          }

          this.$toast(data.message, option)
          throw data
        }
      } catch (err) {
        console.error(err)
      } finally {
        this.isLoadingSubmitReview = false
      }
    }
  },
  watch: {
    report (value) {
      'scoring_date' in value && this.showRatingModalIfEligible()
    },
    'activityResourceDetail.is_complete' (newValue, oldValue) {
      (newValue && newValue !== oldValue) && this.getActivityDashboard()
    },
    'activityDashboard.percentage_progress' (value, old) {
      if (value !== old) {
        this.$refs.vueCircle.updateProgress(value)
        this.$refs.vueCircleMobile.updateProgress(value)
        this.showRatingModalIfEligible()
      }
    }
  },
  created () {
    this.getActivityDashboard()
  },
  mounted () {
    // this.$bvModal.show('activity-rating-modal')
  }
}
</script>
<style scoped>
.activity-v2-navbar {
  box-shadow: 0 2px 8px var(--color-medium-shadow);
  -webkit-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 12;
}
.activity-v2-navbar-inner {
  background: var(--color-neutral-100);
  padding: 13px 13px 8px 13px;
}
.activity-name >>> a {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.activity-name-width >>> a {
  max-width: calc(100vw - 650px);
}
.activity-name-width-sidebar >>> a {
  max-width: calc(100vw - 880px);
}
.activity-name .nav-link,
.nav-mobile .activity-name {
  color: var(--color-ui-text-primary);
}
.back-btn {
  box-shadow: 0 7px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 5px;
}
.activity-icon {
  width: 24px;
  height: 24px;
  margin: 0 10px;
}
.others-dropdown-menu .btn-group > .btn:focus {
  box-shadow: none;
}
.activity-v2-navbar .navbar {
  justify-content: flex-start;
}
.nav-mobile {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex: 1;
  min-width: 0;
}
.progress-wrapper {
  padding-right: 40px;
}
.progress-text {
  position: absolute;
  left: 45px;
}
.activity-v2-navbar >>> .dropdown-item {
  padding: 0 30px 0 10px;
  color: var(--color-persian-blue-500);
}
.activity-v2-navbar >>> .dropdown-item .activity-icon {
  margin-right: 8px;
}
.activity-v2-navbar >>> .others-dropdown-menu .dropdown-menu {
  margin-top: 20px;
  border: transparent;
  box-shadow: 0 16px 20px 1px rgb(51 51 51 / 10%);
  border-radius: 10px;
  padding: 20px 10px;
}
.open-sans-v2 {
  margin: 13px 0
}

.bottom-sheet .bottom-sheet-button {
  max-width: none;
}
@media (min-width: 992px) {
  .nav-mobile {
    display: none;
  }
  .activity-name:hover .nav-link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--color-ui-text-secondary);
  }
  .line-separator {
    border-left: solid 1.5px var(--color-neutral-500);
    margin: 0 10px;
  }
  [data-title]:hover::before {
    content: attr(data-title);
    position: absolute;
    box-shadow: 0 2px 8px var(--color-medium-shadow);
    border-radius: 10px;
    padding: 4px;
    top: 60px;
    background: var(--color-neutral-100);
    color: var(--color-ui-text-secondary);
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
  }
  .others-dropdown-menu[data-title]:hover::before {
    top: 40px;
    right: 0;
  }
  .activity-v2-navbar >>> .navbar-progress-tooltip .tooltip-inner {
    background-color: var(--color-neutral-100);
    max-width: 300px;
    border-radius: 10px;
    margin: 12px;
  }
  .activity-v2-navbar >>> .navbar-progress-tooltip.tooltip .arrow::before {
    content: none;
  }
  .activity-navbar-wrapper >>> .navbar-expand-lg .left-nav {
    flex: 1;
    min-width: 0;
  }
  .activity-navbar-wrapper >>> .navbar-expand-lg .navbar-collapse {
    min-width: 0;
  }
  .activity-v2-navbar-inner .btn-toggle-activity-v2-sidebar {
    right: -13px;
  }
}
</style>
