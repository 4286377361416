import axiosServerKariermu from '@/configs/axiosServerKariermu'

const baseUrl = '/auth'

async function apiCall ({ authorization, url, payload, method, server }) {
  try {
    const result = await server({
      version: 'v2',
      method,
      url,
      headers: {
        Authorization: authorization
      },
      data: payload
    })
    if (result.status === 200) {
      return result.data
    } else {
      throw new Error(result)
    }
  } catch (error) {
    return { status: error.status, message: error.message }
  }
}

export default {
  async register ({ authorization, payload }) {
    try {
      const result = await axiosServerKariermu({
        version: 'v2',
        url: '/auth/register',
        method: 'POST',
        data: payload,
        headers: {
          authorization
        }
      })
      if (result.status === 200) {
        return result
      } else {
        throw new Error(result)
      }
    } catch (error) {
      return { status: error.status, message: error.message }
    }
  },
  async registerGoogle ({ authorization, payload }) {
    try {
      const result = await axiosServerKariermu({
        version: 'v2',
        url: '/auth/external',
        method: 'POST',
        data: payload,
        headers: {
          authorization
        }
      })
      if (result.status === 200) {
        return result
      } else {
        throw new Error(result)
      }
    } catch (error) {
      return { status: error.status, message: error.message }
    }
  },
  async createRelationChildren ({ authorization, payload }) {
    try {
      const result = await axiosServerKariermu({
        version: 'v2',
        url: '/auth/relation-children/create',
        method: 'POST',
        data: payload,
        headers: {
          authorization
        }
      })
      if (result.status === 200) {
        return result
      } else {
        throw new Error(result)
      }
    } catch (error) {
      return { status: error.status, message: error.message }
    }
  },
  async switchAccount ({ authorization, payload }) {
    try {
      const result = await axiosServerKariermu({
        version: 'v2',
        url: '/auth/switch-account',
        method: 'POST',
        data: payload,
        headers: {
          authorization
        }
      })
      if (result.status === 200) {
        return result
      } else {
        throw new Error(result)
      }
    } catch (error) {
      return { status: error.status, message: error.message }
    }
  },
  async verifyOTP ({ authorization, payload }) {
    const url = `${baseUrl}/verify-otp`
    const method = 'POST'
    const server = axiosServerKariermu
    const result = await apiCall({ authorization, url, method, payload, server })
    return result
  },
  async resendOTP ({ authorization, payload }) {
    const url = `${baseUrl}/send-otp`
    const method = 'POST'
    const server = axiosServerKariermu
    const result = await apiCall({ authorization, url, method, payload, server })
    return result
  },
  async referenceOTP ({ authorization }) {
    const url = `${baseUrl}/otp-reference`
    const method = 'GET'
    const server = axiosServerKariermu
    const result = await apiCall({ authorization, url, method, server })
    return result
  },
  async sendForgotPassword ({ payload, authorization }) {
    const url = `${baseUrl}/send_forgot_password`
    const method = 'POST'
    const server = axiosServerKariermu
    const result = await apiCall({ url, method, server, payload, authorization })
    return result
  },
  async getUserProfile (accessToken, isRefresh = false) {
    const response = await apiCall({
      url: `${baseUrl}/me-v2${isRefresh ? '?refresh=1' : ''}`,
      method: 'POST',
      server: axiosServerKariermu,
      authorization: accessToken
    })
    return response
  },
  async validatePassword ({ accessToken, payload }) {
    const response = await apiCall({
      url: `${baseUrl}/validate-password`,
      method: 'POST',
      server: axiosServerKariermu,
      authorization: accessToken,
      payload
    })
    return response
  }
}
