import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// design system css
import '@sekolahmu/sekolahmu-design-system/dist/component.css'
import '@sekolahmu/sekolahmu-design-system/dist/style.css'

// css
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vueperslides/dist/vueperslides.css'
import 'cropperjs/dist/cropper.css'
import '@/styles/Utilities.css'
import '@/styles/DSOverwriteStyle.css'

// plugin
import './plugin/vue-meta'
import './plugin/VueBootstrap'
import './plugin/fontawesome'
import './plugin/vue-cookies'
import './plugin/vue-waypoint'
import './plugin/vue-youtube-embed'

// global function
import imgResizer from '@/functions/imgResizer.js'
import XSSFilter from '@/functions/XSSFilter.js'
import axiosServer from '@/configs/axiosServer'
import axiosServerGolang from '@/configs/axiosServerGolang'
import axiosServerKariermu from '@/configs/axiosServerKariermu'

// api
import api from '@/api/index.js'

// layout
import './layouts/'

// sharing social media
import socialSharing from 'vue-social-sharing'

// firebase
import firebaseConfig from './modules/firebase.config'
import * as firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/messaging'
import 'firebase/firestore'

// apexcharts
import VueApexCharts from 'vue-apexcharts'

// touch event
import Vue2TouchEvents from 'vue2-touch-events'

// tour guide / coach mark
import VueShepherd from 'vue-shepherd'

// design system
import SDesignSystem from '@sekolahmu/sekolahmu-design-system/dist/component.common'

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.use(socialSharing)
Vue.component('social-sharing', socialSharing)

Vue.use(Vue2TouchEvents)
Vue.use(VueShepherd)

Vue.use(SDesignSystem)

Vue.config.productionTip = false

// declare global variable
Vue.prototype.$adminUrl = process.env.VUE_APP_ADMIN_URL

// declare global function
Vue.prototype.$imgResizer = imgResizer
Vue.prototype.$XSSFilter = XSSFilter
Vue.prototype.$axiosServer = axiosServer
Vue.prototype.$axiosServerGolang = axiosServerGolang
Vue.prototype.$axiosServerKariermu = axiosServerKariermu

Vue.prototype.$api = api

// firebase
const firebaseApp = firebase.initializeApp(firebaseConfig)
window.firebase = firebase
window.firebaseAnalytics = firebaseApp.analytics()
window.firestore = firebaseApp.firestore()

if (firebaseApp.firebase_.messaging.isSupported()) {
  window.firebaseMessaging = firebaseApp.messaging()
}

new Vue({
  router,
  store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered'))
}).$mount('#app')
