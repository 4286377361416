import userAPI from '@/api/user.js'

const parentPage = {
  namespaced: true,
  state: {
    activeAcademicYear: '',
    academicYears: [],
    dapodikChildren: [],
    dapodikInfo: {
      has_access: false
    },
    dataCompleteness: {},
    parentDataCompletenessStatus: {}
  },
  getters: {
    showBannerLock (state) {
      if (
        state.dapodikInfo.display_result === 2
      ) return true

      return false
    },
    showBannerCopy (state) {
      if (
        state.dapodikInfo.display_result === 4
      ) return true

      return false
    },
    showEditButton (state, getters) {
      if (
        state.dapodikInfo.display_result === 3 ||
        state.dapodikInfo.display_result === 4 ||
        getters.showTickerComplete
      ) return false

      return true
    },
    showTickerComplete (state) {
      if (
        state.dapodikInfo.data_status === 'complete' &&
        state.dapodikInfo.is_lock === 1
      ) return true

      return false
    }
  },
  mutations: {
    SET_ACTIVE_ACADEMIC_YEAR (state, payload) {
      state.activeAcademicYear = payload
    },
    SET_ACADEMIC_YEARS (state, payload) {
      state.academicYears = payload
    },
    SET_DAPODIK_CHILDREN (state, payload) {
      state.dapodikChildren = payload
    },
    SET_DAPODIK_INFO (state, payload) {
      state.dapodikInfo = payload
    },
    SET_PARENT_DATA_COMPLETENESS_STATUS (state, payload) {
      state.parentDataCompletenessStatus = payload
    },
    SET_DATA_COMPLETENESS (state, payload) {
      state.dataCompleteness = payload
    }
  },
  actions: {
    async validateDapodikChild ({ state, commit }, { accessToken, childId, userId }) {
      try {
        const academicYear = state.activeAcademicYear

        const data = await userAPI.validateDapodikChildV3({
          accessToken,
          childId,
          academicYear
        })

        if (data.status === 200) {
          commit('SET_DAPODIK_INFO', data.data)
        }

        return data
      } catch (err) {
      }
    },
    async getDapodikAccessList ({ commit }, { accessToken }) {
      const data = await userAPI.getListAcademicYear(accessToken)

      commit('SET_ACADEMIC_YEARS', data.data.academic_years.map(academicYear => {
        return {
          value: academicYear.title,
          label: academicYear.title,
          status: academicYear.status
        }
      }))

      commit('SET_DAPODIK_CHILDREN', data.data.children)
    },
    async getParentDataCompletenessStatus ({ commit }, obj) {
      const data = await userAPI.getParentDataCompletenessStatus(obj)

      if (data.status === 200) {
        commit('SET_PARENT_DATA_COMPLETENESS_STATUS', data.data)
      }

      return data
    },
    async checkUserDataCompleteness ({ commit }, { accessToken }) {
      try {
        const { data } = await userAPI.getUserDapodikCompletenessStatus(accessToken)
        if (data.status === 200) {
          commit('SET_DATA_COMPLETENESS', data.data)
        }

        return data.data
      } catch (error) {
        console.error(error)
      }
    }
  }
}

export default parentPage
