import axios from 'axios'
import { enhanceRequest } from './axiosHelpers'

const axiosServer = axios.create({
  baseURL: process.env.VUE_APP_URL_SERVER_KARIERMU
})

axiosServer.interceptors.request.use(
  function (req) {
    req = enhanceRequest({
      req,
      baseUrl: process.env.VUE_APP_URL_SERVER_KARIERMU,
      defaultSource: 'sekolah.mu',
      defaultVersion: ''
    })
    return req
  },
  function (error) {
    return Promise.reject(error)
  }
)

export default axiosServer
