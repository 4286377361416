<template>
  <div id="modal-confirmation-password">
    <div id="close-icon">
      <img
        src="../../assets/icons/icon_close.svg"
        @click="toggleConfirmationPassword()"
      />
    </div>
    <div id="modal-pic">
      <img src="https://cdn.sekolah.mu/assets/product-illustration/success-state/email-sudah-terkirim.png" />
    </div>
    <div
      id="modal-title"
      class="gotham-bold"
    >
      Email Berhasil Dikirim!
    </div>
    <div id="modal-description">
      Cek kotak masuk/spam kamu ya, email berisi link ganti kata sandi sudah dikirim ke <span>{{email}}</span>
    </div>
    <!-- <div id="modal-reminder">
      Jangan lupa segera ganti passwordmu di halaman profil ya
    </div> -->
    <s-button
      variant="primary"
      class="button-send"
      block
      pill
      @onClick="toggleMasuk()"
    >Mengerti</s-button>
    <!-- temporarily disabled -->
    <!-- <div id="modal-footer">
      <div>Belum dapat email?</div>
      <div v-if="time > 0">Coba <span>Kirim Ulang</span> ({{time}}s)</div>
      <div v-else-if="isLoading"><span class="send-again">
          <b-spinner small></b-spinner>
        </span></div>
      <div v-else>Coba <span
          class="send-again"
          @click="sendNewPassword()"
        >Kirim Ulang</span></div>
    </div> -->
  </div>
</template>

<script>
import axiosServerKariermu from '../../configs/axiosServerKariermu'
import SButton from '@/components/atoms/buttons/SButton.vue'
export default {
  components: {
    SButton
  },
  data () {
    return {
      time: 60,
      timerInterval: '',
      isLoading: false
    }
  },
  methods: {
    startTimer () {
      this.timerInterval = setInterval(() => {
        this.time -= 1
      }, 1000)
    },
    async sendNewPassword () {
      try {
        console.log(123)
        this.isLoading = true
        const { data } = await axiosServerKariermu({
          url: '/user/recover_password/',
          method: 'post',
          headers: {
            Authorization: process.env.VUE_APP_APP_KEY
          },
          data: {
            email: this.email
          }
        })
        console.log(data)
        this.isLoading = false
        this.time = 60
        this.startTimer()
      } catch (err) {
        this.isLoading = false
      }
    },
    toggleConfirmationPassword () {
      this.$emit('toggleConfirmationPassword')
    },
    toggleMasuk () {
      this.$emit('toggleMasuk')
    }
  },
  computed: {
    email () {
      return this.$store.state.login.email
    }
  },
  watch: {
    time (newValue) {
      if (newValue === 0) {
        clearInterval(this.timerInterval)
      }
    }
  },
  created () {
    this.startTimer()
  },
  beforeDestroy () {
    clearInterval(this.timerInterval)
  }
}
</script>

<style scoped>
#modal-confirmation-password {
  padding: 0px 13px;
}

#close-icon {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}

#close-icon img {
  cursor: pointer;
}

#modal-pic {
  text-align: center;
}

#modal-pic img {
  width: 50%;
}

#modal-title {
  text-align: center;
  color: #172d5c;
  margin-top: 25px;
  font-size: 20px;
}

#modal-description {
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
  margin-top: 15px;
}

#modal-description span {
  font-weight: 600;
}

#modal-reminder {
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
  margin-top: 15px;
}

.button-send {
  margin-top: 20px;
  margin-bottom: 15px;
}

#modal-footer {
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
}

#modal-footer span {
  font-weight: 600;
}

.send-again {
  color: var(--color-deep-lemon-500);
  font-weight: 600;
  cursor: pointer;
}

.send-again:hover {
  text-decoration: underline;
}
</style>
