import deviceDetector from 'mobile-device-detect'
import generateQueryParamSource from '@/functions/generateQueryParamSource'

export function enhanceRequest ({
  req,
  baseUrl,
  defaultSource,
  defaultVersion
}) {
  let traceSource = 'platform=sekolahmu'

  if (req.generateQueryParamSource) {
    const isMobile = deviceDetector.isMobile
    const sourceString = req.source || defaultSource
    traceSource += `&${generateQueryParamSource(sourceString, isMobile)}`
  }

  const version = req.version || defaultVersion
  if (version) {
    req.baseURL = `${baseUrl}/${version}`
  }

  req.url = req.url.includes('?')
    ? `${req.url}&${traceSource}`
    : `${req.url}?${traceSource}`

  return req
}
