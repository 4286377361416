import axiosServerKariermu from '../../configs/axiosServerKariermu'

const storage = {
  namespaced: true,
  state: {
    accountMenu: {},
    isLoadingAccountMenu: false
  },
  mutations: {
    SET_ACCOUNT_MENU (state, payload) {
      state.accountMenu = payload
    },
    SET_IS_LOADING (state, payload) {
      state.isLoadingAccountMenu = payload
    }
  },
  actions: {
    async fetchAccountMenu ({ commit }, { accessToken, parentId }) {
      try {
        commit('SET_IS_LOADING', true)
        const { data } = await axiosServerKariermu({
          version: 'v2',
          url: '/user/account-menu',
          method: 'GET',
          headers: {
            Authorization: accessToken
          }
        })
        if (data.status === 200) {
          commit('SET_ACCOUNT_MENU', data.data)
        }
      } catch (error) {
        console.log(error)
      } finally {
        commit('SET_IS_LOADING', false)
      }
    },
    async fetchAccountMenuV2 ({ commit }, { accessToken, parentId }) {
      try {
        commit('SET_IS_LOADING', true)
        const { data } = await axiosServerKariermu({
          version: 'v2',
          url: `/user/v2/account-menu/${parentId}`,
          method: 'GET',
          headers: {
            Authorization: accessToken
          }
        })
        if (data.status === 200) {
          commit('SET_ACCOUNT_MENU', data.data)
        }
      } catch (error) {
        console.log(error)
      } finally {
        commit('SET_IS_LOADING', false)
      }
    }
  }
}

export default storage
